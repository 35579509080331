// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Apollo from "../../Apollo.bs.js";
import * as Spinner from "../../Spinner.bs.js";
import * as Utils_react from "../../utils/Utils_react.bs.js";
import * as React_bootstrap from "../../vendor/React_bootstrap.bs.js";
import ModalBody from "react-bootstrap/lib/ModalBody";
import ModalFooter from "react-bootstrap/lib/ModalFooter";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

function cancelFooter(onClose) {
  return React.createElement(ModalFooter, {
              children: React.createElement("button", {
                    className: "btn btn-danger",
                    onClick: (function (param) {
                        return Utils_react.preventCallback(onClose, param);
                      })
                  }, Utils_react.ste("Fermer"))
            });
}

function modalLoading(onClose) {
  return React.createElement(React.Fragment, undefined, React.createElement(ModalBody, {
                  className: "flex items-end",
                  children: React.createElement(Spinner.make, {})
                }), cancelFooter(onClose));
}

function modalError(error, onClose) {
  return React.createElement(React.Fragment, undefined, React.createElement(ModalBody, {
                  className: "flex items-end",
                  children: React.createElement("div", {
                        className: "alert alert-danger"
                      }, Utils_react.ste(error))
                }), cancelFooter(onClose));
}

function modalEntity(showUrl, editUrl, pdfUrl, locked, onClose) {
  return React.createElement(React.Fragment, undefined, React.createElement(ModalBody, {
                  children: React.createElement("iframe", {
                        className: "w-full h-80vh",
                        src: showUrl
                      })
                }), React.createElement(ModalFooter, {
                  children: null
                }, locked ? null : React.createElement("a", {
                        className: "btn btn-default",
                        href: editUrl
                      }, React.createElement("i", {
                            className: "fa fa-pencil"
                          }), Utils_react.ste("Modifier")), React.createElement("a", {
                      className: "btn btn-default",
                      href: showUrl,
                      target: "_blank"
                    }, React.createElement("i", {
                          className: "fa fa-eye"
                        }), Utils_react.ste("Voir")), React.createElement("a", {
                      className: "btn btn-default",
                      href: pdfUrl
                    }, React.createElement("i", {
                          className: "fa fa-print"
                        }), Utils_react.ste("Télécharger")), React.createElement("div", {
                      className: "btn btn-danger",
                      onClick: (function (param) {
                          return Utils_react.preventCallback(onClose, param);
                        })
                    }, Utils_react.ste("Fermer"))));
}

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment ModalShowInvoiceFragment on Invoice   {
    __typename
    id
    showUrl
    editUrl
    pdfUrl
    locked
  }
`);

function parse(value) {
  return {
          __typename: value.__typename,
          id: value.id,
          showUrl: value.showUrl,
          editUrl: value.editUrl,
          pdfUrl: value.pdfUrl,
          locked: value.locked
        };
}

function serialize(value) {
  var value$1 = value.locked;
  var value$2 = value.pdfUrl;
  var value$3 = value.editUrl;
  var value$4 = value.showUrl;
  var value$5 = value.id;
  var value$6 = value.__typename;
  return {
          __typename: value$6,
          id: value$5,
          showUrl: value$4,
          editUrl: value$3,
          pdfUrl: value$2,
          locked: value$1
        };
}

function verifyArgsAndParse(_ModalShowInvoiceFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var ModalShowInvoiceFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = (require("@apollo/client").gql`
  fragment ModalShowQuoteFragment on Quote   {
    __typename
    id
    showUrl
    editUrl
    pdfUrl
    locked
  }
`);

function parse$1(value) {
  return {
          __typename: value.__typename,
          id: value.id,
          showUrl: value.showUrl,
          editUrl: value.editUrl,
          pdfUrl: value.pdfUrl,
          locked: value.locked
        };
}

function serialize$1(value) {
  var value$1 = value.locked;
  var value$2 = value.pdfUrl;
  var value$3 = value.editUrl;
  var value$4 = value.showUrl;
  var value$5 = value.id;
  var value$6 = value.__typename;
  return {
          __typename: value$6,
          id: value$5,
          showUrl: value$4,
          editUrl: value$3,
          pdfUrl: value$2,
          locked: value$1
        };
}

function verifyArgsAndParse$1(_ModalShowQuoteFragment, value) {
  return parse$1(value);
}

function verifyName$1(param) {
  
}

var ModalShowQuoteFragment = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  verifyArgsAndParse: verifyArgsAndParse$1,
  verifyName: verifyName$1
};

var Raw$2 = {};

var query$2 = (require("@apollo/client").gql`
  fragment ModalShowContractFragment on Contract   {
    __typename
    id
    showUrl
    editUrl
    pdfUrl
    locked
  }
`);

function parse$2(value) {
  return {
          __typename: value.__typename,
          id: value.id,
          showUrl: value.showUrl,
          editUrl: value.editUrl,
          pdfUrl: value.pdfUrl,
          locked: value.locked
        };
}

function serialize$2(value) {
  var value$1 = value.locked;
  var value$2 = value.pdfUrl;
  var value$3 = value.editUrl;
  var value$4 = value.showUrl;
  var value$5 = value.id;
  var value$6 = value.__typename;
  return {
          __typename: value$6,
          id: value$5,
          showUrl: value$4,
          editUrl: value$3,
          pdfUrl: value$2,
          locked: value$1
        };
}

function verifyArgsAndParse$2(_ModalShowContractFragment, value) {
  return parse$2(value);
}

function verifyName$2(param) {
  
}

var ModalShowContractFragment = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  verifyArgsAndParse: verifyArgsAndParse$2,
  verifyName: verifyName$2
};

var Raw$3 = {};

var query$3 = ((frag_0) => require("@apollo/client").gql`
  query invoiceQuery($id: Int!)  {
    entity: strictInvoice(id: $id)  {
      ...ModalShowInvoiceFragment
    }
  }
  ${frag_0}
`)(query);

function parse$3(value) {
  return {
          entity: parse(value.entity)
        };
}

function serialize$3(value) {
  var value$1 = value.entity;
  var entity = serialize(value$1);
  return {
          entity: entity
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

function makeVariables(id, param) {
  return {
          id: id
        };
}

var InvoiceQuery_inner = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$3,
      Raw: Raw$3,
      parse: parse$3,
      serialize: serialize$3,
      serializeVariables: serializeVariables
    });

var use = include.use;

var InvoiceQuery_refetchQueryDescription = include.refetchQueryDescription;

var InvoiceQuery_useLazy = include.useLazy;

var InvoiceQuery_useLazyWithVariables = include.useLazyWithVariables;

var InvoiceQuery = {
  InvoiceQuery_inner: InvoiceQuery_inner,
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: InvoiceQuery_refetchQueryDescription,
  use: use,
  useLazy: InvoiceQuery_useLazy,
  useLazyWithVariables: InvoiceQuery_useLazyWithVariables
};

function ModalShowInvoice$Invoice(Props) {
  var show = Props.show;
  var onClose = Props.onClose;
  var id = Props.id;
  var apollo = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          id: id
        }
      ]);
  var match = apollo.data;
  var error = apollo.error;
  var tmp;
  if (apollo.loading) {
    tmp = modalLoading(onClose);
  } else if (error !== undefined) {
    tmp = modalError(Apollo.getErrorMessage(error), onClose);
  } else if (match !== undefined) {
    var match$1 = match.entity;
    tmp = modalEntity(match$1.showUrl, match$1.editUrl, match$1.pdfUrl, match$1.locked, onClose);
  } else {
    tmp = modalError("Erreur", onClose);
  }
  return React.createElement(React_bootstrap.Modal.make, {
              dialogClassName: "w-900px",
              onHide: onClose,
              show: show,
              children: tmp
            });
}

var Invoice = {
  InvoiceQuery: InvoiceQuery,
  make: ModalShowInvoice$Invoice
};

var Raw$4 = {};

var query$4 = ((frag_0) => require("@apollo/client").gql`
  query quoteQuery($id: Int!)  {
    entity: strictQuote(id: $id)  {
      ...ModalShowQuoteFragment
    }
  }
  ${frag_0}
`)(query$1);

function parse$4(value) {
  return {
          entity: parse$1(value.entity)
        };
}

function serialize$4(value) {
  var value$1 = value.entity;
  var entity = serialize$1(value$1);
  return {
          entity: entity
        };
}

function serializeVariables$1(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$1(id, param) {
  return {
          id: id
        };
}

var QuoteQuery_inner = {
  Raw: Raw$4,
  query: query$4,
  parse: parse$4,
  serialize: serialize$4,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$4,
      Raw: Raw$4,
      parse: parse$4,
      serialize: serialize$4,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var QuoteQuery_refetchQueryDescription = include$1.refetchQueryDescription;

var QuoteQuery_useLazy = include$1.useLazy;

var QuoteQuery_useLazyWithVariables = include$1.useLazyWithVariables;

var QuoteQuery = {
  QuoteQuery_inner: QuoteQuery_inner,
  Raw: Raw$4,
  query: query$4,
  parse: parse$4,
  serialize: serialize$4,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  refetchQueryDescription: QuoteQuery_refetchQueryDescription,
  use: use$1,
  useLazy: QuoteQuery_useLazy,
  useLazyWithVariables: QuoteQuery_useLazyWithVariables
};

function ModalShowInvoice$Quote(Props) {
  var show = Props.show;
  var onClose = Props.onClose;
  var id = Props.id;
  var apollo = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          id: id
        }
      ]);
  var match = apollo.data;
  var error = apollo.error;
  var tmp;
  if (apollo.loading) {
    tmp = modalLoading(onClose);
  } else if (error !== undefined) {
    tmp = modalError(Apollo.getErrorMessage(error), onClose);
  } else if (match !== undefined) {
    var match$1 = match.entity;
    tmp = modalEntity(match$1.showUrl, match$1.editUrl, match$1.pdfUrl, match$1.locked, onClose);
  } else {
    tmp = modalError("Erreur", onClose);
  }
  return React.createElement(React_bootstrap.Modal.make, {
              dialogClassName: "w-900px",
              onHide: onClose,
              show: show,
              children: tmp
            });
}

var Quote = {
  QuoteQuery: QuoteQuery,
  make: ModalShowInvoice$Quote
};

var Raw$5 = {};

var query$5 = ((frag_0) => require("@apollo/client").gql`
  query contractQuery($id: Int!)  {
    entity: strictQuote(id: $id)  {
      ...ModalShowContractFragment
    }
  }
  ${frag_0}
`)(query$2);

function parse$5(value) {
  return {
          entity: parse$2(value.entity)
        };
}

function serialize$5(value) {
  var value$1 = value.entity;
  var entity = serialize$2(value$1);
  return {
          entity: entity
        };
}

function serializeVariables$2(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$2(id, param) {
  return {
          id: id
        };
}

var ContractQuery_inner = {
  Raw: Raw$5,
  query: query$5,
  parse: parse$5,
  serialize: serialize$5,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2
};

var include$2 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$5,
      Raw: Raw$5,
      parse: parse$5,
      serialize: serialize$5,
      serializeVariables: serializeVariables$2
    });

var use$2 = include$2.use;

var ContractQuery_refetchQueryDescription = include$2.refetchQueryDescription;

var ContractQuery_useLazy = include$2.useLazy;

var ContractQuery_useLazyWithVariables = include$2.useLazyWithVariables;

var ContractQuery = {
  ContractQuery_inner: ContractQuery_inner,
  Raw: Raw$5,
  query: query$5,
  parse: parse$5,
  serialize: serialize$5,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  refetchQueryDescription: ContractQuery_refetchQueryDescription,
  use: use$2,
  useLazy: ContractQuery_useLazy,
  useLazyWithVariables: ContractQuery_useLazyWithVariables
};

function ModalShowInvoice$Contract(Props) {
  var show = Props.show;
  var onClose = Props.onClose;
  var id = Props.id;
  var apollo = Curry.app(use$2, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          id: id
        }
      ]);
  var match = apollo.data;
  var error = apollo.error;
  var tmp;
  if (apollo.loading) {
    tmp = modalLoading(onClose);
  } else if (error !== undefined) {
    tmp = modalError(Apollo.getErrorMessage(error), onClose);
  } else if (match !== undefined) {
    var match$1 = match.entity;
    tmp = modalEntity(match$1.showUrl, match$1.editUrl, match$1.pdfUrl, match$1.locked, onClose);
  } else {
    tmp = modalError("Erreur", onClose);
  }
  return React.createElement(React_bootstrap.Modal.make, {
              dialogClassName: "w-900px",
              onHide: onClose,
              show: show,
              children: tmp
            });
}

var Contract = {
  ContractQuery: ContractQuery,
  make: ModalShowInvoice$Contract
};

var Modal;

export {
  Modal ,
  ModalShowInvoiceFragment ,
  ModalShowQuoteFragment ,
  ModalShowContractFragment ,
  Invoice ,
  Quote ,
  Contract ,
  
}
/* query Not a pure module */
