// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "../vendor/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Utils_List from "../utils/Utils_List.bs.js";
import * as Utils_unicode from "../utils/Utils_unicode.bs.js";
import * as Caml_primitive from "bs-platform/lib/es6/caml_primitive.js";

var root = "navigation";

var pagination = "flex p-0 rounded";

function pageItem(activeOpt, disabledOpt, param) {
  var active = activeOpt !== undefined ? activeOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  if (disabled) {
    return Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus("block relative", "py-2 px-3"), "-ml-px"), "text-gray-600"), "border border-solid border-gray-300"), Cn.on("bg-white", !active)), Cn.on("bg-gray-200", active)), "cursor-not-allowed");
  } else {
    return Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus("block relative", "py-2 px-3"), "-ml-px"), "text-link"), "border border-solid border-gray-300"), "hover:bg-gray-300"), Cn.on("bg-white", !active)), Cn.on("bg-gray-300", active));
  }
}

var Cls = {
  root: root,
  pagination: pagination,
  pageItem: pageItem
};

function Pagination$PhantomItem(Props) {
  return Props.children;
}

var PhantomItem = {
  make: Pagination$PhantomItem
};

function Pagination(Props) {
  var renderPage = Props.renderPage;
  var currentPage = Props.currentPage;
  var nbPages = Props.nbPages;
  var windowOpt = Props.window;
  var $$window = windowOpt !== undefined ? windowOpt : 6;
  var range = Utils_List.range(Caml_primitive.caml_int_max(currentPage - ($$window / 2 | 0) | 0, 1), Caml_primitive.caml_int_min((currentPage + 1 | 0) + ($$window / 2 | 0) | 0, nbPages + 1 | 0), undefined, undefined);
  var firstRange = Belt_List.head(range);
  var lastRange = Belt_List.get(range, Belt_List.length(range) - 1 | 0);
  if (firstRange !== undefined && lastRange !== undefined) {
    return React.createElement("nav", {
                className: root
              }, React.createElement("div", {
                    className: pagination
                  }, React.createElement(Pagination$PhantomItem, {
                        children: Curry._3(renderPage, Caml_primitive.caml_int_max(currentPage - 1 | 0, 1), pageItem(undefined, currentPage === 1, undefined), Utils_unicode.laquoElement),
                        key: "page-prev"
                      }), firstRange !== 1 ? React.createElement(Pagination$PhantomItem, {
                          children: Curry._3(renderPage, 1, pageItem(undefined, undefined, undefined), String(1)),
                          key: "page-first"
                        }) : null, firstRange > 2 ? React.createElement(Pagination$PhantomItem, {
                          children: Curry._3(renderPage, 2, pageItem(undefined, undefined, undefined), Utils_unicode.mldrElement),
                          key: "page-2"
                        }) : null, Belt_List.toArray(Belt_List.map(range, (function (page) {
                              return React.createElement(Pagination$PhantomItem, {
                                          children: Curry._3(renderPage, page, pageItem(page === currentPage, undefined, undefined), String(page)),
                                          key: "page-" + page
                                        });
                            }))), lastRange < (nbPages - 1 | 0) ? React.createElement(Pagination$PhantomItem, {
                          children: Curry._3(renderPage, nbPages - 1 | 0, pageItem(undefined, undefined, undefined), Utils_unicode.mldrElement),
                          key: "page-" + nbPages + "-1"
                        }) : null, lastRange !== nbPages ? React.createElement(Pagination$PhantomItem, {
                          children: Curry._3(renderPage, nbPages, pageItem(undefined, undefined, undefined), String(nbPages)),
                          key: "page-last"
                        }) : null, React.createElement(Pagination$PhantomItem, {
                        children: Curry._3(renderPage, Caml_primitive.caml_int_min(currentPage + 1 | 0, nbPages), pageItem(undefined, currentPage >= nbPages, undefined), Utils_unicode.raquoElement),
                        key: "page-next"
                      })));
  }
  return React.createElement("nav", {
              className: root
            }, React.createElement("ul", {
                  className: pagination
                }));
}

var make = Pagination;

export {
  Cls ,
  PhantomItem ,
  make ,
  
}
/* react Not a pure module */
