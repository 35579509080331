// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import Get from "lodash/get";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import Join from "lodash/join";
import Some from "lodash/some";
import Trim from "lodash/trim";
import Uniq from "lodash/uniq";
import Filter from "lodash/filter";
import SortBy from "lodash/sortBy";

function some(predicate, array) {
  return Some(array, predicate);
}

function sortBy(mapper, array) {
  return SortBy(array, mapper);
}

function join(sperator, array) {
  return Join(array, sperator);
}

function filter(predicate, array) {
  return Filter(array, predicate);
}

function trim(chars, str) {
  return Trim(str, chars);
}

function jsonGet(json, path) {
  return Caml_option.undefined_to_opt(Get(json, path));
}

function jsonGetWithDef(json, path, def) {
  return Belt_Option.getWithDefault(Caml_option.undefined_to_opt(Get(json, path)), def);
}

function uniq(l) {
  return Belt_List.fromArray(Uniq(Belt_List.toArray(l)));
}

var List = {
  uniq: uniq
};

export {
  some ,
  sortBy ,
  join ,
  filter ,
  trim ,
  jsonGet ,
  jsonGetWithDef ,
  List ,
  
}
/* lodash/get Not a pure module */
