// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as GraphQLExtending from "../../Graphql/GraphQLExtending.bs.js";
import * as ModalShowInvoice from "../ModalShowInvoice/ModalShowInvoice.bs.js";

var Raw = {};

var query = ((frag_0) => require("@apollo/client").gql`
  fragment ContractInvoiceFragment on ContractInvoice   {
    __typename
    id
    effectiveDate
    sendByMailDate
    emailPrototype
    emailSubjectPrototype
    contract  {
      __typename
      id
      number
      date
      totalAfterTax
      client  {
        __typename
        id
        invoiceName
        emailCompta
      }
    }
    invoice  {
      __typename
      id
      number
      showUrl
      pdfUrl
      editUrl
      publicUrl
      addPaymentUrl
      ...ModalShowInvoiceFragment
    }
  }
  ${frag_0}
`)(ModalShowInvoice.ModalShowInvoiceFragment.query);

function parse(value) {
  var value$1 = value.sendByMailDate;
  var value$2 = value.emailPrototype;
  var value$3 = value.emailSubjectPrototype;
  var value$4 = value.contract;
  var value$5 = value$4.client;
  var value$6 = value$5.invoiceName;
  var value$7 = value$5.emailCompta;
  var value$8 = value.invoice;
  return {
          __typename: value.__typename,
          id: value.id,
          effectiveDate: GraphQLExtending.$$Date.parse(value.effectiveDate),
          sendByMailDate: !(value$1 == null) ? Caml_option.some(GraphQLExtending.$$Date.parse(value$1)) : undefined,
          emailPrototype: !(value$2 == null) ? value$2 : undefined,
          emailSubjectPrototype: !(value$3 == null) ? value$3 : undefined,
          contract: {
            __typename: value$4.__typename,
            id: value$4.id,
            number: value$4.number,
            date: GraphQLExtending.$$Date.parse(value$4.date),
            totalAfterTax: value$4.totalAfterTax,
            client: {
              __typename: value$5.__typename,
              id: value$5.id,
              invoiceName: !(value$6 == null) ? value$6 : undefined,
              emailCompta: !(value$7 == null) ? value$7 : undefined
            }
          },
          invoice: !(value$8 == null) ? ({
                __typename: value$8["__typename"],
                id: value$8["id"],
                number: value$8["number"],
                showUrl: value$8["showUrl"],
                pdfUrl: value$8["pdfUrl"],
                editUrl: value$8["editUrl"],
                publicUrl: value$8["publicUrl"],
                addPaymentUrl: value$8["addPaymentUrl"],
                modalShowInvoiceFragment: ModalShowInvoice.ModalShowInvoiceFragment.verifyArgsAndParse("ModalShowInvoiceFragment", value$8)
              }) : undefined
        };
}

function serialize(value) {
  var value$1 = value.invoice;
  var invoice;
  if (value$1 !== undefined) {
    var value$2 = value$1.addPaymentUrl;
    var value$3 = value$1.publicUrl;
    var value$4 = value$1.editUrl;
    var value$5 = value$1.pdfUrl;
    var value$6 = value$1.showUrl;
    var value$7 = value$1.number;
    var value$8 = value$1.id;
    var value$9 = value$1.__typename;
    invoice = [ModalShowInvoice.ModalShowInvoiceFragment.serialize(value$1.modalShowInvoiceFragment)].reduce(GraphQL_PPX.deepMerge, {
          __typename: value$9,
          id: value$8,
          number: value$7,
          showUrl: value$6,
          pdfUrl: value$5,
          editUrl: value$4,
          publicUrl: value$3,
          addPaymentUrl: value$2
        });
  } else {
    invoice = null;
  }
  var value$10 = value.contract;
  var value$11 = value$10.client;
  var value$12 = value$11.emailCompta;
  var emailCompta = value$12 !== undefined ? value$12 : null;
  var value$13 = value$11.invoiceName;
  var invoiceName = value$13 !== undefined ? value$13 : null;
  var value$14 = value$11.id;
  var value$15 = value$11.__typename;
  var client = {
    __typename: value$15,
    id: value$14,
    invoiceName: invoiceName,
    emailCompta: emailCompta
  };
  var value$16 = value$10.totalAfterTax;
  var value$17 = value$10.date;
  var value$18 = GraphQLExtending.$$Date.serialize(value$17);
  var value$19 = value$10.number;
  var value$20 = value$10.id;
  var value$21 = value$10.__typename;
  var contract = {
    __typename: value$21,
    id: value$20,
    number: value$19,
    date: value$18,
    totalAfterTax: value$16,
    client: client
  };
  var value$22 = value.emailSubjectPrototype;
  var emailSubjectPrototype = value$22 !== undefined ? value$22 : null;
  var value$23 = value.emailPrototype;
  var emailPrototype = value$23 !== undefined ? value$23 : null;
  var value$24 = value.sendByMailDate;
  var sendByMailDate = value$24 !== undefined ? GraphQLExtending.$$Date.serialize(Caml_option.valFromOption(value$24)) : null;
  var value$25 = value.effectiveDate;
  var value$26 = GraphQLExtending.$$Date.serialize(value$25);
  var value$27 = value.id;
  var value$28 = value.__typename;
  return {
          __typename: value$28,
          id: value$27,
          effectiveDate: value$26,
          sendByMailDate: sendByMailDate,
          emailPrototype: emailPrototype,
          emailSubjectPrototype: emailSubjectPrototype,
          contract: contract,
          invoice: invoice
        };
}

function verifyArgsAndParse(_ContractInvoiceFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var ContractInvoiceFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var ModalShowInvoiceFragment;

export {
  ModalShowInvoiceFragment ,
  ContractInvoiceFragment ,
  
}
/* query Not a pure module */
