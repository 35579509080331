// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Utils_react from "../utils/Utils_react.bs.js";
import * as Form_Renderer from "./Form_Renderer.bs.js";
import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";
import * as Hook$ReasonForm from "@maarekj/reason-form/src/Hook.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/src/Wrap.bs.js";
import * as Helper$ReasonForm from "@maarekj/reason-form/src/Helper.bs.js";

function Form_BoolCheckbox(Props) {
  var wrap = Props.wrap;
  var id = Props.id;
  var label = Props.label;
  var classNameOpt = Props.className;
  var field = Props.field;
  var className = classNameOpt !== undefined ? classNameOpt : "checkbox";
  var value = Hook$ReasonForm.useValue(wrap, field);
  var key = field.key;
  return React.useMemo((function () {
                console.log("render checkbox");
                var focus = function (param) {
                  return Form$ReasonForm.focus(key, param);
                };
                var blur = function (param) {
                  return Form$ReasonForm.blur(key, param);
                };
                var id$1;
                if (id !== undefined) {
                  id$1 = id;
                } else {
                  var prefixId = Wrap$ReasonForm.id(wrap);
                  id$1 = Form_Renderer.normalizeId(prefixId + ("-" + key));
                }
                return React.createElement("div", {
                            className: className
                          }, React.createElement("label", undefined, React.createElement("input", {
                                    id: id$1,
                                    checked: value === true,
                                    type: "checkbox",
                                    onFocus: (function (_event) {
                                        return Wrap$ReasonForm.dispatch(wrap, focus);
                                      }),
                                    onBlur: (function (_event) {
                                        return Wrap$ReasonForm.dispatch(wrap, blur);
                                      }),
                                    onChange: (function ($$event) {
                                        var partial_arg = Utils_react.isChecked($$event);
                                        return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                                                      return Helper$ReasonForm.changeFieldValue(field, partial_arg, param);
                                                    }));
                                      })
                                  }), React.createElement("span", undefined, label)));
              }), [
              value,
              key,
              className,
              id
            ]);
}

var make = Form_BoolCheckbox;

export {
  make ,
  
}
/* react Not a pure module */
