// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as $$Image from "../component/Image.bs.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils_react from "../utils/Utils_react.bs.js";
import * as GraphQLExtending from "../Graphql/GraphQLExtending.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = ((frag_0) => require("@apollo/client").gql`
  query MediaQuery($id: Int!, $filter: ImageFilterEnum!)  {
    media: strictMedia(id: $id)  {
      __typename
      id
      type
      name
      context
      createdAt
      referenceUrl
      image  {
        ...ImageFragment
      }
    }
  }
  ${frag_0}
`)($$Image.ImageFragment.query);

function parse(value) {
  var value$1 = value.media;
  var value$2 = value$1.type;
  var tmp;
  switch (value$2) {
    case "image" :
        tmp = "image";
        break;
    case "pdf" :
        tmp = "pdf";
        break;
    case "video" :
        tmp = "video";
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$2
      };
  }
  var value$3 = value$1.image;
  return {
          media: {
            __typename: value$1.__typename,
            id: value$1.id,
            type: tmp,
            name: value$1.name,
            context: value$1.context,
            createdAt: GraphQLExtending.$$Date.parse(value$1.createdAt),
            referenceUrl: value$1.referenceUrl,
            image: !(value$3 == null) ? $$Image.ImageFragment.verifyArgsAndParse("NonNull_ImageFilterEnum", "ImageFragment", value$3) : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.media;
  var value$2 = value$1.image;
  var image = value$2 !== undefined ? $$Image.ImageFragment.serialize(value$2) : null;
  var value$3 = value$1.referenceUrl;
  var value$4 = value$1.createdAt;
  var value$5 = GraphQLExtending.$$Date.serialize(value$4);
  var value$6 = value$1.context;
  var value$7 = value$1.name;
  var value$8 = value$1.type;
  var type_ = typeof value$8 === "string" ? (
      value$8 === "video" ? "video" : (
          value$8 === "image" ? "image" : "pdf"
        )
    ) : value$8.VAL;
  var value$9 = value$1.id;
  var value$10 = value$1.__typename;
  var media = {
    __typename: value$10,
    id: value$9,
    type: type_,
    name: value$7,
    context: value$6,
    createdAt: value$5,
    referenceUrl: value$3,
    image: image
  };
  return {
          media: media
        };
}

function serializeVariables(inp) {
  var a = inp.filter;
  return {
          id: inp.id,
          filter: a === "inset100" ? "inset100" : (
              a === "inset500" ? "inset500" : (
                  a === "outbound1000" ? "outbound1000" : (
                      a === "reference" ? "reference" : (
                          a === "heighten100" ? "heighten100" : (
                              a === "heighten500" ? "heighten500" : (
                                  a === "widen100" ? "widen100" : (
                                      a === "widen500" ? "widen500" : (
                                          a === "inset1000" ? "inset1000" : (
                                              a === "widen1000" ? "widen1000" : (
                                                  a === "outbound100" ? "outbound100" : (
                                                      a === "outbound500" ? "outbound500" : (
                                                          a === "square100" ? "square100" : (
                                                              a === "square500" ? "square500" : (
                                                                  a === "heighten1000" ? "heighten1000" : "square1000"
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        };
}

function makeVariables(id, filter, param) {
  return {
          id: id,
          filter: filter
        };
}

var MediaQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var MediaQuery_refetchQueryDescription = include.refetchQueryDescription;

var MediaQuery_useLazy = include.useLazy;

var MediaQuery_useLazyWithVariables = include.useLazyWithVariables;

var MediaQuery = {
  MediaQuery_inner: MediaQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: MediaQuery_refetchQueryDescription,
  use: use,
  useLazy: MediaQuery_useLazy,
  useLazyWithVariables: MediaQuery_useLazyWithVariables
};

function MediaUpload_PreviewMedia(Props) {
  var withClear = Props.withClear;
  var onClear = Props.onClear;
  var inputId = Props.inputId;
  var mediaId = Props.mediaId;
  var imageFilterOpt = Props.imageFilter;
  var imageFilter = imageFilterOpt !== undefined ? imageFilterOpt : "widen500";
  var apollo = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          id: mediaId,
          filter: imageFilter
        }
      ]);
  var match = apollo.data;
  var mediaElement;
  if (apollo.loading) {
    mediaElement = React.createElement("div", undefined, Utils_react.ste("Chargement..."));
  } else if (match !== undefined) {
    var match$1 = match.media;
    var match$2 = match$1.type;
    if (match$2 === "pdf") {
      var tmp = {
        className: "block",
        href: match$1.referenceUrl,
        target: "_blank"
      };
      if (inputId !== undefined) {
        tmp.id = Caml_option.valFromOption(inputId);
      }
      mediaElement = React.createElement("div", {
            className: ""
          }, React.createElement("a", tmp, React.createElement("i", {
                    className: "fa fa-file-pdf-o text-5xl"
                  })));
    } else if (match$2 === "image") {
      var tmp$1 = {
        image: {
          TAG: 1,
          _0: mediaId,
          _1: imageFilter,
          [Symbol.for("name")]: "Id"
        },
        className: "max-w-full w-48 h-auto"
      };
      if (inputId !== undefined) {
        tmp$1.id = Caml_option.valFromOption(inputId);
      }
      mediaElement = React.createElement("div", {
            className: ""
          }, React.createElement($$Image.make, tmp$1));
    } else {
      var tmp$2 = {
        className: "block",
        href: match$1.referenceUrl,
        target: "_blank"
      };
      if (inputId !== undefined) {
        tmp$2.id = Caml_option.valFromOption(inputId);
      }
      mediaElement = React.createElement("div", {
            className: ""
          }, React.createElement("a", tmp$2, React.createElement("i", {
                    className: "fa fa-file text-5xl"
                  })));
    }
  } else {
    mediaElement = React.createElement("div", undefined, Utils_react.ste("Erreur"));
  }
  if (withClear) {
    return React.createElement("div", {
                className: "flex items-center"
              }, mediaElement, React.createElement("button", {
                    className: "btn btn-danger ml-3",
                    type: "button",
                    onClick: (function (param) {
                        return Utils_react.preventCallback(onClear, param);
                      })
                  }, React.createElement("i", {
                        className: "fa fa-trash fill-current"
                      })));
  } else {
    return mediaElement;
  }
}

var ImageFragment;

var make = MediaUpload_PreviewMedia;

export {
  ImageFragment ,
  MediaQuery ,
  make ,
  
}
/* query Not a pure module */
