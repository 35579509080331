// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Utils from "../../utils/Utils.bs.js";
import * as React from "react";
import * as Apollo from "../../Apollo.bs.js";
import * as Js_exn from "bs-platform/lib/es6/js_exn.js";
import * as Lodash from "../../vendor/Lodash.bs.js";
import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Numeral from "../../vendor/Numeral.bs.js";
import * as Spinner from "../../Spinner.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Pagination from "../Pagination.bs.js";
import * as Utils_date from "../../utils/Utils_date.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Belt_SetInt from "bs-platform/lib/es6/belt_SetInt.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils_react from "../../utils/Utils_react.bs.js";
import Join from "lodash/join";
import * as Hook$ReasonForm from "@maarekj/reason-form/src/Hook.bs.js";
import * as GraphQLExtending from "../../Graphql/GraphQLExtending.bs.js";
import * as ModalShowInvoice from "../ModalShowInvoice/ModalShowInvoice.bs.js";
import * as ModalClientButton from "../ModalClient/ModalClientButton.bs.js";
import UpperFirst from "lodash/upperFirst";
import * as ContractInvoiceTable_Fragment from "./ContractInvoiceTable_Fragment.bs.js";
import * as ContractInvoiceTable_ModalEmail from "./ContractInvoiceTable_ModalEmail.bs.js";
import * as ContractInvoiceTable_Filters_Form from "./ContractInvoiceTable_Filters_Form.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as ContractInvoiceTable_Filters_Fields from "./ContractInvoiceTable_Filters_Fields.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = ((frag_0) => require("@apollo/client").gql`
  query PagerQuery($pro: ProId!, $page: Int!, $months: [Int!]!, $year: Int = null, $alreadySended: YesNoEnum!)  {
    pager: contractInvoices(pro: $pro, page: $page, months: $months, year: $year, alreadySended: $alreadySended, maxPerPage: 30)  {
      __typename
      nbPages
      nbResults
      results  {
        ...ContractInvoiceFragment
      }
    }
  }
  ${frag_0}
`)(ContractInvoiceTable_Fragment.ContractInvoiceFragment.query);

function parse(value) {
  var value$1 = value.pager;
  var value$2 = value$1.results;
  return {
          pager: {
            __typename: value$1.__typename,
            nbPages: value$1.nbPages,
            nbResults: value$1.nbResults,
            results: value$2.map(function (value) {
                  return ContractInvoiceTable_Fragment.ContractInvoiceFragment.verifyArgsAndParse("ContractInvoiceFragment", value);
                })
          }
        };
}

function serialize(value) {
  var value$1 = value.pager;
  var value$2 = value$1.results;
  var results = value$2.map(function (value) {
        return ContractInvoiceTable_Fragment.ContractInvoiceFragment.serialize(value);
      });
  var value$3 = value$1.nbResults;
  var value$4 = value$1.nbPages;
  var value$5 = value$1.__typename;
  var pager = {
    __typename: value$5,
    nbPages: value$4,
    nbResults: value$3,
    results: results
  };
  return {
          pager: pager
        };
}

function serializeVariables(inp) {
  var a = inp.months;
  var a$1 = inp.year;
  var a$2 = inp.alreadySended;
  return {
          pro: inp.pro,
          page: inp.page,
          months: a.map(function (b) {
                return b;
              }),
          year: a$1 !== undefined ? a$1 : undefined,
          alreadySended: a$2 === "YES" ? "YES" : (
              a$2 === "DEFAULT" ? "DEFAULT" : "NO"
            )
        };
}

function makeVariables(pro, page, months, year, alreadySended, param) {
  return {
          pro: pro,
          page: page,
          months: months,
          year: year,
          alreadySended: alreadySended
        };
}

var PagerQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var PagerQuery_refetchQueryDescription = include.refetchQueryDescription;

var PagerQuery_useLazy = include.useLazy;

var PagerQuery_useLazyWithVariables = include.useLazyWithVariables;

var PagerQuery = {
  PagerQuery_inner: PagerQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: PagerQuery_refetchQueryDescription,
  use: use,
  useLazy: PagerQuery_useLazy,
  useLazyWithVariables: PagerQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = ((frag_0) => require("@apollo/client").gql`
  mutation CreateInvoiceFromContractInvoiceMutation($data: CreateInvoiceFromContractInvoiceInput!)  {
    results: createInvoiceFromContractInvoice(data: $data)  {
      error
      result  {
        ...ContractInvoiceFragment
      }
    }
  }
  ${frag_0}
`)(ContractInvoiceTable_Fragment.ContractInvoiceFragment.query);

function parse$1(value) {
  var value$1 = value.results;
  return {
          results: value$1.map(function (value) {
                var value$1 = Js_json.decodeObject(value);
                if (value$1 === undefined) {
                  return Js_exn.raiseError("Unexpected GraphQL query response");
                }
                var value$2 = Caml_option.valFromOption(value$1);
                var temp = value$2["error"];
                var match = Js_json.decodeNull(temp);
                if (match === undefined) {
                  return {
                          NAME: "Error",
                          VAL: temp
                        };
                }
                var temp$1 = value$2["result"];
                var match$1 = Js_json.decodeNull(temp$1);
                if (match$1 !== undefined) {
                  return Js_exn.raiseError("Unexpected GraphQL query response");
                } else {
                  return {
                          NAME: "Result",
                          VAL: ContractInvoiceTable_Fragment.ContractInvoiceFragment.verifyArgsAndParse("ContractInvoiceFragment", temp$1)
                        };
                }
              })
        };
}

function serialize$1(value) {
  var value$1 = value.results;
  var results = value$1.map(function (value) {
        return null;
      });
  return {
          results: results
        };
}

function serializeInputObjectCreateInvoiceFromContractInvoiceInput(inp) {
  var a = inp.contractInvoices;
  return {
          contractInvoices: a.map(function (b) {
                return b;
              })
        };
}

function serializeVariables$1(inp) {
  return {
          data: serializeInputObjectCreateInvoiceFromContractInvoiceInput(inp.data)
        };
}

function makeVariables$1(data, param) {
  return {
          data: data
        };
}

function makeInputObjectCreateInvoiceFromContractInvoiceInput(contractInvoices, param) {
  return {
          contractInvoices: contractInvoices
        };
}

var CreateInvoiceFromContractInvoiceMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectCreateInvoiceFromContractInvoiceInput: serializeInputObjectCreateInvoiceFromContractInvoiceInput,
  makeVariables: makeVariables$1,
  makeInputObjectCreateInvoiceFromContractInvoiceInput: makeInputObjectCreateInvoiceFromContractInvoiceInput
};

var include$1 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var CreateInvoiceFromContractInvoiceMutation_useWithVariables = include$1.useWithVariables;

var CreateInvoiceFromContractInvoiceMutation = {
  CreateInvoiceFromContractInvoiceMutation_inner: CreateInvoiceFromContractInvoiceMutation_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectCreateInvoiceFromContractInvoiceInput: serializeInputObjectCreateInvoiceFromContractInvoiceInput,
  makeVariables: makeVariables$1,
  makeInputObjectCreateInvoiceFromContractInvoiceInput: makeInputObjectCreateInvoiceFromContractInvoiceInput,
  use: use$1,
  useWithVariables: CreateInvoiceFromContractInvoiceMutation_useWithVariables
};

var Raw$2 = {};

var query$2 = ((frag_0) => require("@apollo/client").gql`
  mutation sendContractInvoiceByEmailMutation($data: SendContractInvoiceByEmailInput!)  {
    results: sendContractInvoiceByEmail(data: $data)  {
      error
      result  {
        ...ContractInvoiceFragment
      }
    }
  }
  ${frag_0}
`)(ContractInvoiceTable_Fragment.ContractInvoiceFragment.query);

function parse$2(value) {
  var value$1 = value.results;
  return {
          results: value$1.map(function (value) {
                var value$1 = Js_json.decodeObject(value);
                if (value$1 === undefined) {
                  return Js_exn.raiseError("Unexpected GraphQL query response");
                }
                var value$2 = Caml_option.valFromOption(value$1);
                var temp = value$2["error"];
                var match = Js_json.decodeNull(temp);
                if (match === undefined) {
                  return {
                          NAME: "Error",
                          VAL: temp
                        };
                }
                var temp$1 = value$2["result"];
                var match$1 = Js_json.decodeNull(temp$1);
                if (match$1 !== undefined) {
                  return Js_exn.raiseError("Unexpected GraphQL query response");
                } else {
                  return {
                          NAME: "Result",
                          VAL: ContractInvoiceTable_Fragment.ContractInvoiceFragment.verifyArgsAndParse("ContractInvoiceFragment", temp$1)
                        };
                }
              })
        };
}

function serialize$2(value) {
  var value$1 = value.results;
  var results = value$1.map(function (value) {
        return null;
      });
  return {
          results: results
        };
}

function serializeInputObjectSendContractInvoiceByEmailInput(inp) {
  var a = inp.contractInvoices;
  return {
          contractInvoices: a.map(function (b) {
                return b;
              })
        };
}

function serializeVariables$2(inp) {
  return {
          data: serializeInputObjectSendContractInvoiceByEmailInput(inp.data)
        };
}

function makeVariables$2(data, param) {
  return {
          data: data
        };
}

function makeInputObjectSendContractInvoiceByEmailInput(contractInvoices, param) {
  return {
          contractInvoices: contractInvoices
        };
}

var SendContractInvoiceByEmailMutation_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  serializeInputObjectSendContractInvoiceByEmailInput: serializeInputObjectSendContractInvoiceByEmailInput,
  makeVariables: makeVariables$2,
  makeInputObjectSendContractInvoiceByEmailInput: makeInputObjectSendContractInvoiceByEmailInput
};

var include$2 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2
    });

var use$2 = include$2.use;

var SendContractInvoiceByEmailMutation_useWithVariables = include$2.useWithVariables;

var SendContractInvoiceByEmailMutation = {
  SendContractInvoiceByEmailMutation_inner: SendContractInvoiceByEmailMutation_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  serializeInputObjectSendContractInvoiceByEmailInput: serializeInputObjectSendContractInvoiceByEmailInput,
  makeVariables: makeVariables$2,
  makeInputObjectSendContractInvoiceByEmailInput: makeInputObjectSendContractInvoiceByEmailInput,
  use: use$2,
  useWithVariables: SendContractInvoiceByEmailMutation_useWithVariables
};

function getCreateError(mutationResult) {
  var match = mutationResult.data;
  var error = mutationResult.error;
  if (error !== undefined) {
    return Apollo.getErrorMessage(error);
  }
  if (match === undefined) {
    return ;
  }
  var errors = Belt_Array.keepMap(match.results, (function (r) {
          if (r.NAME === "Result") {
            return ;
          } else {
            return r.VAL;
          }
        }));
  if (errors.length === 0) {
    return ;
  } else {
    return Join(errors, "\n");
  }
}

function getSendError(mutationResult) {
  var match = mutationResult.data;
  var error = mutationResult.error;
  if (error !== undefined) {
    return Apollo.getErrorMessage(error);
  }
  if (match === undefined) {
    return ;
  }
  var errors = Belt_Array.keepMap(match.results, (function (r) {
          if (r.NAME === "Result") {
            return ;
          } else {
            return r.VAL;
          }
        }));
  if (errors.length === 0) {
    return ;
  } else {
    return Join(errors, "\n");
  }
}

function getNbCreated(mutationResult) {
  if (!mutationResult.called || mutationResult.loading) {
    return ;
  } else {
    return Belt_Array.keepMap(Belt_Option.mapWithDefault(mutationResult.data, [], (function (d) {
                      return d.results;
                    })), (function (r) {
                  if (r.NAME === "Result") {
                    return Caml_option.some(undefined);
                  }
                  
                })).length;
  }
}

function getNbSended(mutationResult) {
  if (!mutationResult.called || mutationResult.loading) {
    return ;
  } else {
    return Belt_Array.keepMap(Belt_Option.mapWithDefault(mutationResult.data, [], (function (d) {
                      return d.results;
                    })), (function (r) {
                  if (r.NAME === "Result") {
                    return Caml_option.some(undefined);
                  }
                  
                })).length;
  }
}

function reducer(_state, _action) {
  while(true) {
    var action = _action;
    var state = _state;
    if (typeof action === "number") {
      return ;
    }
    if (action.TAG === /* Select */0) {
      var match = action._0;
      if (!match) {
        return state;
      }
      _action = {
        TAG: 0,
        _0: match.tl,
        [Symbol.for("name")]: "Select"
      };
      _state = Belt_SetInt.add(state, match.hd);
      continue ;
    }
    var match$1 = action._0;
    if (!match$1) {
      return state;
    }
    _action = {
      TAG: 1,
      _0: match$1.tl,
      [Symbol.for("name")]: "Unselect"
    };
    _state = Belt_SetInt.remove(state, match$1.hd);
    continue ;
  };
}

function ContractInvoiceTable$Row(Props) {
  var row = Props.row;
  var dispatch = Props.dispatch;
  var isSelected = Props.isSelected;
  var emailModal = Utils_react.useToggleShow(false);
  var showInvoiceModal = Utils_react.useToggleShow(false);
  var match = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var createMutationResult = match[1];
  var createMutation = match[0];
  var match$1 = Curry.app(use$2, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var sendMutationResult = match$1[1];
  var sendMutation = match$1[0];
  var loading = createMutationResult.loading || sendMutationResult.loading;
  var sendError = getSendError(sendMutationResult);
  var createError = getCreateError(createMutationResult);
  if (loading) {
    return React.createElement("tr", undefined, React.createElement("td", {
                    colSpan: 8
                  }, React.createElement("div", {
                        className: "flex justify-center"
                      }, React.createElement(Spinner.make, {
                            className: "spinner-sm"
                          }))));
  }
  var emailPrototype = row.emailPrototype;
  var tmp;
  var exit = 0;
  if (emailPrototype !== undefined) {
    var emailSubjectPrototype = row.emailSubjectPrototype;
    if (emailSubjectPrototype !== undefined && Lodash.trim(undefined, emailPrototype) !== "" && Lodash.trim(undefined, emailSubjectPrototype) !== "") {
      tmp = React.createElement(React.Fragment, undefined, React.createElement("button", {
                className: "btn btn-default mr-2",
                onClick: emailModal.showWithPrevent
              }, Utils_react.ste("Modifier l\'email")), row.contract.client.emailCompta !== undefined ? React.createElement("button", {
                  className: "btn btn-primary",
                  onClick: Curry._1(Utils_react.preventCallback, (function (param) {
                          Curry._8(sendMutation, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                data: {
                                  contractInvoices: [GraphQLExtending.ContractInvoiceId.serialize(row.id)]
                                }
                              });
                          
                        }))
                }, React.createElement("i", {
                      className: "fa fa-paper-plane mr-2"
                    }), Utils_react.ste("Envoyer")) : Utils_react.ste("Le client n\'a pas d\'email"));
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement("button", {
          className: "btn btn-default",
          onClick: emailModal.showWithPrevent
        }, Utils_react.ste("Définir l\'email"));
  }
  var match$2 = row.invoice;
  return React.createElement(React.Fragment, undefined, Utils_react.mapOrNull(sendError, (function (error) {
                    return React.createElement("tr", undefined, React.createElement("td", {
                                    colSpan: 8
                                  }, React.createElement("div", {
                                        className: "alert alert-danger m-0"
                                      }, Utils_react.nl2br(error))));
                  })), Utils_react.mapOrNull(createError, (function (error) {
                    return React.createElement("tr", undefined, React.createElement("td", {
                                    colSpan: 8
                                  }, React.createElement("div", {
                                        className: "alert alert-danger m-0"
                                      }, Utils_react.nl2br(error))));
                  })), React.createElement("tr", undefined, React.createElement("td", undefined, Utils_react.ste(Numeral.formatInt(row.contract.number))), React.createElement("td", undefined, React.createElement(ModalClientButton.make, {
                          className: "btn btn-default",
                          label: Utils_react.ste(Utils.def(row.contract.client.invoiceName, Numeral.formatInt(row.contract.client.id))),
                          clientId: row.contract.client.id
                        })), React.createElement("td", undefined, Numeral.formatAutoFloatEuroEl(row.contract.totalAfterTax)), React.createElement("td", undefined, Utils_react.ste(UpperFirst(Utils_date.format(row.effectiveDate, "fr", "MMMM yyyy")))), React.createElement("td", undefined, Utils_react.mapOrNull(row.sendByMailDate, (function (d) {
                            return Utils_react.ste(UpperFirst(Utils_date.formatLong(d, "fr", "LLL")));
                          }))), React.createElement("td", undefined, tmp, React.createElement(ContractInvoiceTable_ModalEmail.make, {
                          show: emailModal.value,
                          onClose: emailModal.hide,
                          contractInvoice: row
                        })), React.createElement("td", undefined, match$2 !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("button", {
                                className: "btn btn-default",
                                onClick: showInvoiceModal.showWithPrevent
                              }, Utils_react.ste("Facture N°" + match$2.number.toString())), React.createElement(ModalShowInvoice.Invoice.make, {
                                show: showInvoiceModal.value,
                                onClose: showInvoiceModal.hide,
                                id: match$2.id
                              })) : React.createElement("button", {
                            className: "btn btn-default",
                            onClick: Curry._1(Utils_react.preventCallback, (function (param) {
                                    Curry._8(createMutation, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                          data: {
                                            contractInvoices: [GraphQLExtending.ContractInvoiceId.serialize(row.id)]
                                          }
                                        });
                                    
                                  }))
                          }, Utils_react.ste("Créer la facture"))), React.createElement("td", {
                      onClick: (function (_event) {
                          return Curry._1(dispatch, isSelected ? ({
                                          TAG: 1,
                                          _0: {
                                            hd: row.id,
                                            tl: /* [] */0
                                          },
                                          [Symbol.for("name")]: "Unselect"
                                        }) : ({
                                          TAG: 0,
                                          _0: {
                                            hd: row.id,
                                            tl: /* [] */0
                                          },
                                          [Symbol.for("name")]: "Select"
                                        }));
                        })
                    }, React.createElement("input", {
                          checked: isSelected,
                          type: "checkbox",
                          onChange: (function ($$event) {
                              var isChecked = Utils_react.isChecked($$event);
                              return Curry._1(dispatch, isChecked ? ({
                                              TAG: 0,
                                              _0: {
                                                hd: row.id,
                                                tl: /* [] */0
                                              },
                                              [Symbol.for("name")]: "Select"
                                            }) : ({
                                              TAG: 1,
                                              _0: {
                                                hd: row.id,
                                                tl: /* [] */0
                                              },
                                              [Symbol.for("name")]: "Unselect"
                                            }));
                            })
                        }))));
}

var Row = {
  make: ContractInvoiceTable$Row
};

function ContractInvoiceTable(Props) {
  var proId = Props.proId;
  var match = React.useReducer(reducer, undefined);
  var dispatch = match[1];
  var selected = match[0];
  var match$1 = React.useState(function () {
        return 1;
      });
  var setCurrentPage = match$1[1];
  var currentPage = match$1[0];
  var initialValues = ContractInvoiceTable_Filters_Fields.Value.make(false, undefined, undefined, undefined);
  var match$2 = ContractInvoiceTable_Filters_Fields.useForm(initialValues, (function (param) {
          return Curry._1(setCurrentPage, (function (param) {
                        return 1;
                      }));
        }));
  var wrap = match$2[0];
  var filters = Hook$ReasonForm.useFormValues(wrap, Utils.id);
  var pager = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          pro: GraphQLExtending.ProId.serialize(proId),
          page: currentPage,
          months: Belt_List.toArray(filters.months),
          year: filters.year,
          alreadySended: Belt_Option.mapWithDefault(filters.alreadySended, "DEFAULT", (function (v) {
                  if (v) {
                    return "YES";
                  } else {
                    return "NO";
                  }
                }))
        }
      ]);
  var match$3 = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var createMutationResult = match$3[1];
  var createMutation = match$3[0];
  var match$4 = Curry.app(use$2, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var sendMutationResult = match$4[1];
  var sendMutation = match$4[0];
  var create = function (param) {
    Curry._8(createMutation, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
          data: {
            contractInvoices: Belt_Array.map(Belt_SetInt.toArray(selected), GraphQLExtending.ContractInvoiceId.serialize)
          }
        });
    
  };
  var send = function (param) {
    Curry._8(sendMutation, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
          data: {
            contractInvoices: Belt_Array.map(Belt_SetInt.toArray(selected), GraphQLExtending.ContractInvoiceId.serialize)
          }
        });
    
  };
  var loading = createMutationResult.loading || sendMutationResult.loading;
  var nbSended = getNbSended(sendMutationResult);
  var sendError = getSendError(sendMutationResult);
  var nbCreated = getNbCreated(createMutationResult);
  var createError = getCreateError(createMutationResult);
  var match$5 = pager.data;
  var error = pager.error;
  var tmp;
  if (pager.loading) {
    tmp = React.createElement("div", {
          className: "box-body"
        }, React.createElement("div", {
              className: "w-full flex items-center"
            }, React.createElement(Spinner.make, {
                  className: "mx-auto"
                })));
  } else if (error !== undefined) {
    tmp = React.createElement("div", {
          className: "box-body"
        }, React.createElement("div", {
              className: "alert alert-danger"
            }, Utils_react.ste(Apollo.getErrorMessage(error))));
  } else if (match$5 !== undefined) {
    var pager$1 = match$5.pager;
    var diff = Belt_SetInt.diff(Belt_SetInt.fromArray(Belt_Array.map(pager$1.results, (function (param) {
                    return param.id;
                  }))), selected);
    var allSelected = Belt_SetInt.isEmpty(diff);
    var allSelect = function (param) {
      if (allSelected) {
        return Curry._1(dispatch, /* Clear */0);
      } else {
        return Curry._1(dispatch, {
                    TAG: 0,
                    _0: Belt_List.fromArray(Belt_Array.map(pager$1.results, (function (param) {
                                return param.id;
                              }))),
                    [Symbol.for("name")]: "Select"
                  });
      }
    };
    var size = Belt_SetInt.size(selected);
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: "box-body table-responsive no-padding"
            }, React.createElement("table", {
                  className: "table table-bordered table-striped table-hover"
                }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, Utils_react.ste("N° de contrat")), React.createElement("th", undefined, Utils_react.ste("Client")), React.createElement("th", undefined, Utils_react.ste("Total TTC")), React.createElement("th", undefined, Utils_react.ste("Mois/Année")), React.createElement("th", undefined, Utils_react.ste("Envoyé par email le")), React.createElement("th", undefined, Utils_react.ste("Email")), React.createElement("th", undefined, Utils_react.ste("Facture")), React.createElement("th", {
                              onClick: (function (param) {
                                  return allSelect(undefined);
                                })
                            }, React.createElement("input", {
                                  checked: allSelected,
                                  type: "checkbox",
                                  onChange: (function (_event) {
                                      return allSelect(undefined);
                                    })
                                })))), React.createElement("tbody", undefined, Utils_react.ate(Belt_Array.map(pager$1.results, (function (row) {
                                return React.createElement(ContractInvoiceTable$Row, {
                                            row: row,
                                            dispatch: dispatch,
                                            isSelected: Belt_SetInt.has(selected, row.id),
                                            key: "row-" + row.id.toString()
                                          });
                              }))), React.createElement("tr", undefined, React.createElement("td", {
                              className: "text-right",
                              colSpan: 7
                            }, React.createElement("div", {
                                  className: ""
                                }, React.createElement("label", {
                                      className: "control-label",
                                      htmlFor: "ContractInvoiceTable-action-select"
                                    }, Utils_react.ste("Actions: ")))), React.createElement("td", undefined, loading ? React.createElement(Spinner.make, {
                                    className: "spinner-xs"
                                  }) : React.createElement("div", {
                                    className: "flex"
                                  }, React.createElement("button", {
                                        className: "btn btn-primary mr-2",
                                        disabled: size === 0,
                                        onClick: (function (param) {
                                            return Utils_react.preventCallback(create, param);
                                          })
                                      }, Utils_react.ste("Créer les factures (" + (Numeral.formatInt(size) + ")"))), React.createElement("button", {
                                        className: "btn btn-primary",
                                        disabled: size === 0,
                                        onClick: (function (param) {
                                            return Utils_react.preventCallback(send, param);
                                          })
                                      }, Utils_react.ste("Envoyer par email (" + (Numeral.formatInt(size) + ")")))))), Utils_react.mapOrNull(nbCreated, (function (nbCreated) {
                            return React.createElement("tr", undefined, React.createElement("td", {
                                            colSpan: 8
                                          }, nbCreated !== 0 ? (
                                              nbCreated !== 1 ? React.createElement("div", {
                                                      className: "alert alert-success m-0"
                                                    }, Utils_react.ste(Numeral.formatInt(nbCreated) + " factures ont été créées")) : React.createElement("div", {
                                                      className: "alert alert-success m-0"
                                                    }, Utils_react.ste("1 facture à été créée"))
                                            ) : React.createElement("div", {
                                                  className: "alert alert-info m-0"
                                                }, Utils_react.ste("Aucune facture n\'a été créée"))));
                          })), Utils_react.mapOrNull(createError, (function (error) {
                            return React.createElement("tr", undefined, React.createElement("td", {
                                            colSpan: 8
                                          }, React.createElement("div", {
                                                className: "alert alert-warning m-0"
                                              }, Utils_react.nl2br(error))));
                          })), Utils_react.mapOrNull(nbSended, (function (nbSended) {
                            return React.createElement("tr", undefined, React.createElement("td", {
                                            colSpan: 8
                                          }, nbSended !== 0 ? (
                                              nbSended !== 1 ? React.createElement("div", {
                                                      className: "alert alert-success m-0"
                                                    }, Utils_react.ste(Numeral.formatInt(nbSended) + " factures ont été envoyée")) : React.createElement("div", {
                                                      className: "alert alert-success m-0"
                                                    }, Utils_react.ste("1 facture à été envoyée"))
                                            ) : React.createElement("div", {
                                                  className: "alert alert-info m-0"
                                                }, Utils_react.ste("Aucune facture n\'a été envoyée"))));
                          })), Utils_react.mapOrNull(sendError, (function (error) {
                            return React.createElement("tr", undefined, React.createElement("td", {
                                            colSpan: 8
                                          }, React.createElement("div", {
                                                className: "alert alert-warning m-0"
                                              }, Utils_react.nl2br(error))));
                          }))))), React.createElement("div", {
              className: "box-footer"
            }, React.createElement(Pagination.make, {
                  renderPage: (function (page, className, children) {
                      return React.createElement("button", {
                                  className: className,
                                  onClick: Curry._1(Utils_react.preventCallback, (function (param) {
                                          return Curry._1(setCurrentPage, (function (param) {
                                                        return page;
                                                      }));
                                        }))
                                }, children);
                    }),
                  currentPage: currentPage,
                  nbPages: pager$1.nbPages
                })));
  } else {
    tmp = React.createElement("div", {
          className: "box-body"
        }, React.createElement("div", {
              className: "alert alert-danger"
            }, Utils_react.ste("Erreur inconnue")));
  }
  return React.createElement(React.Fragment, undefined, React.createElement(ContractInvoiceTable_Filters_Form.make, {
                  className: "mb-gutter",
                  wrap: wrap,
                  fields: match$2[1]
                }), React.createElement("div", {
                  className: "box box-primary my-gutter"
                }, tmp));
}

var ContractInvoiceFragment;

var Filters;

var make = ContractInvoiceTable;

export {
  ContractInvoiceFragment ,
  PagerQuery ,
  CreateInvoiceFromContractInvoiceMutation ,
  SendContractInvoiceByEmailMutation ,
  reducer ,
  Row ,
  Filters ,
  make ,
  
}
/* query Not a pure module */
