// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/src/Wrap.bs.js";
import * as Helper$ReasonForm from "@maarekj/reason-form/src/Helper.bs.js";

function SubformSyncWidget$Inner(Props) {
  var initialValues = Props.initialValues;
  var onChangeValue = Props.onChangeValue;
  var onBlur = Props.onBlur;
  var onFocus = Props.onFocus;
  var renderForm = Props.renderForm;
  return Curry._5(renderForm, initialValues, onChangeValue, onBlur, onFocus, undefined);
}

var Inner = {
  make: SubformSyncWidget$Inner
};

function SubformSyncWidget(Props) {
  var wrap = Props.wrap;
  var field = Props.field;
  var transformers = Props.transformers;
  var renderForm = Props.renderForm;
  var reverseTransform = transformers[1];
  var onChangeValue = React.useMemo((function () {
          return function (param, param$1, param$2) {
            var newValues = Curry._1(reverseTransform, Form$ReasonForm.getValues(param$2));
            Wrap$ReasonForm.dispatch(wrap, (function (param) {
                    return Helper$ReasonForm.changeFieldValue(field, newValues, param);
                  }));
            return param$2;
          };
        }), [
        wrap,
        field.key
      ]);
  var onFocus = React.useMemo((function () {
          return function (param, param$1) {
            var partial_arg = field.key;
            Wrap$ReasonForm.dispatch(wrap, (function (param) {
                    return Form$ReasonForm.focus(partial_arg, param);
                  }));
            return param$1;
          };
        }), [
        wrap,
        field.key
      ]);
  var onBlur = React.useMemo((function () {
          return function (param, param$1) {
            var partial_arg = field.key;
            Wrap$ReasonForm.dispatch(wrap, (function (param) {
                    return Form$ReasonForm.focus(partial_arg, param);
                  }));
            return param$1;
          };
        }), [
        wrap,
        field.key
      ]);
  var initialValues = Curry._1(transformers[0], Curry._1(field.getValue, Form$ReasonForm.getValues(wrap.content)));
  return React.createElement(SubformSyncWidget$Inner, {
              initialValues: initialValues,
              onChangeValue: onChangeValue,
              onBlur: onBlur,
              onFocus: onFocus,
              renderForm: renderForm,
              key: field.key
            });
}

var make = SubformSyncWidget;

export {
  Inner ,
  make ,
  
}
/* react Not a pure module */
