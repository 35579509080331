import React from "react";
import Modal from "react-bootstrap/lib/Modal";
import preventDefault from "../lib/preventDefault";

export default class Loading extends React.Component {
    render() {
        const {show, onClose} = this.props;

        return (
            <Modal show={show} onHide={onClose}>
                <Modal.Header>
                    <Modal.Title>Chargement...</Modal.Title>
                </Modal.Header>
                <Modal.Body>Chargement...</Modal.Body>
                <Modal.Footer>
                    <div className="btn btn-danger" onClick={preventDefault(onClose)}>
                        Fermer
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
