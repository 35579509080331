// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ButtonModal from "../Modal/ButtonModal.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ModalClient from "./ModalClient.bs.js";

function ModalClientButton(Props) {
  var className = Props.className;
  var icon = Props.icon;
  var label = Props.label;
  var clientId = Props.clientId;
  var tmp = {
    className: className,
    label: label,
    renderModal: (function (modal) {
        return React.createElement(ModalClient.make, {
                    show: modal.value,
                    clientId: clientId,
                    onClose: modal.hide
                  });
      })
  };
  if (icon !== undefined) {
    tmp.icon = Caml_option.valFromOption(icon);
  }
  return React.createElement(ButtonModal.make, tmp);
}

var make = ModalClientButton;

var $$default = ModalClientButton;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
