// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Lodash from "../vendor/Lodash.bs.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import Split from "lodash/split";

function findIndex(f, l) {
  var _i = 0;
  var _l = l;
  while(true) {
    var l$1 = _l;
    var i = _i;
    if (!l$1) {
      return ;
    }
    if (Curry._1(f, l$1.hd)) {
      return i;
    }
    _l = l$1.tl;
    _i = i + 1 | 0;
    continue ;
  };
}

function range(_startOpt, end_, _stepOpt, _listOpt, _current, _param) {
  while(true) {
    var startOpt = _startOpt;
    var stepOpt = _stepOpt;
    var listOpt = _listOpt;
    var current = _current;
    var start = startOpt !== undefined ? startOpt : 0;
    var step = stepOpt !== undefined ? stepOpt : 1;
    var list = listOpt !== undefined ? listOpt : /* [] */0;
    if (current >= end_) {
      return list;
    }
    _param = undefined;
    _current = current + step | 0;
    _listOpt = {
      hd: current,
      tl: list
    };
    _stepOpt = step;
    _startOpt = start;
    continue ;
  };
}

function rangeRight(start, _end_Opt, _stepOpt, _listOpt, _current, _param) {
  while(true) {
    var end_Opt = _end_Opt;
    var stepOpt = _stepOpt;
    var listOpt = _listOpt;
    var current = _current;
    var end_ = end_Opt !== undefined ? end_Opt : 0;
    var step = stepOpt !== undefined ? stepOpt : 1;
    var list = listOpt !== undefined ? listOpt : /* [] */0;
    if (current <= end_) {
      return list;
    }
    _param = undefined;
    _current = current - step | 0;
    _listOpt = {
      hd: current,
      tl: list
    };
    _stepOpt = step;
    _end_Opt = end_;
    continue ;
  };
}

function range$1(startOpt, end_, stepOpt, param) {
  var start = startOpt !== undefined ? startOpt : 0;
  var step = stepOpt !== undefined ? stepOpt : 1;
  if (start < end_) {
    return List.rev(range(start, end_, Pervasives.abs(step), undefined, start, undefined));
  } else {
    return List.rev(rangeRight(start, end_, Pervasives.abs(step), undefined, start, undefined));
  }
}

function split(str, separator) {
  return $$Array.to_list(Split(str, separator));
}

function join(separator, list) {
  return Lodash.join(separator, $$Array.of_list(list));
}

function join_(list, separator) {
  return Lodash.join(separator, $$Array.of_list(list));
}

function nthOpt(l, n) {
  try {
    return Caml_option.some(List.nth(l, n));
  }
  catch (exn){
    return ;
  }
}

function removeDuplicates(equalOpt, l) {
  var equal = equalOpt !== undefined ? equalOpt : Caml_obj.caml_equal;
  var removeElt = function (x, l) {
    return List.filter(function (y) {
                  return !Curry._2(equal, x, y);
                })(l);
  };
  var _l = l;
  var _acc = /* [] */0;
  while(true) {
    var acc = _acc;
    var l$1 = _l;
    if (!l$1) {
      return List.rev(acc);
    }
    var x = l$1.hd;
    _acc = {
      hd: x,
      tl: acc
    };
    _l = removeElt(x, l$1.tl);
    continue ;
  };
}

function keepSome(list) {
  return Belt_List.reduceReverseU(list, /* [] */0, (function (acc, a) {
                if (a !== undefined) {
                  return {
                          hd: Caml_option.valFromOption(a),
                          tl: acc
                        };
                } else {
                  return acc;
                }
              }));
}

function mapWithLoopU(list, callback) {
  var count = Belt_List.length(list);
  return Belt_List.mapWithIndexU(list, (function (i, x) {
                return callback({
                            index: i,
                            last: (count - 1 | 0) === i,
                            first: i === 0
                          }, x);
              }));
}

function mapWithLoop(list, callback) {
  return mapWithLoopU(list, Curry.__2(callback));
}

function sortBy(list, getKey) {
  return Belt_List.sort(list, (function (a, b) {
                return $$String.compare(Curry._1(getKey, a), Curry._1(getKey, b));
              }));
}

export {
  findIndex ,
  rangeRight ,
  range$1 as range,
  split ,
  join ,
  join_ ,
  nthOpt ,
  removeDuplicates ,
  keepSome ,
  mapWithLoopU ,
  mapWithLoop ,
  sortBy ,
  
}
/* Lodash Not a pure module */
