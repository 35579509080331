// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Numeral from "../vendor/Numeral.bs.js";
import * as Utils_react from "../utils/Utils_react.bs.js";
import * as Utils_unicode from "../utils/Utils_unicode.bs.js";
import * as ModalPaymentsButton from "./ModalPayments/ModalPaymentsButton.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  query soldOutQuery($invoiceId: Int!)  {
    invoice(id: $invoiceId)  {
      __typename
      id
      credit
      soldOut
      totalAfterTax
      totalPaid
      countPayments
    }
  }
`);

function parse(value) {
  var value$1 = value.invoice;
  return {
          invoice: !(value$1 == null) ? ({
                __typename: value$1.__typename,
                id: value$1.id,
                credit: value$1.credit,
                soldOut: value$1.soldOut,
                totalAfterTax: value$1.totalAfterTax,
                totalPaid: value$1.totalPaid,
                countPayments: value$1.countPayments
              }) : undefined
        };
}

function serialize(value) {
  var value$1 = value.invoice;
  var invoice;
  if (value$1 !== undefined) {
    var value$2 = value$1.countPayments;
    var value$3 = value$1.totalPaid;
    var value$4 = value$1.totalAfterTax;
    var value$5 = value$1.soldOut;
    var value$6 = value$1.credit;
    var value$7 = value$1.id;
    var value$8 = value$1.__typename;
    invoice = {
      __typename: value$8,
      id: value$7,
      credit: value$6,
      soldOut: value$5,
      totalAfterTax: value$4,
      totalPaid: value$3,
      countPayments: value$2
    };
  } else {
    invoice = null;
  }
  return {
          invoice: invoice
        };
}

function serializeVariables(inp) {
  return {
          invoiceId: inp.invoiceId
        };
}

function makeVariables(invoiceId, param) {
  return {
          invoiceId: invoiceId
        };
}

var SoldOutQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var SoldOutQuery_refetchQueryDescription = include.refetchQueryDescription;

var SoldOutQuery_useLazy = include.useLazy;

var SoldOutQuery_useLazyWithVariables = include.useLazyWithVariables;

var SoldOutQuery = {
  SoldOutQuery_inner: SoldOutQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: SoldOutQuery_refetchQueryDescription,
  use: use,
  useLazy: SoldOutQuery_useLazy,
  useLazyWithVariables: SoldOutQuery_useLazyWithVariables
};

var soldOut = Curry._1(Css.style, {
      hd: Css.textAlign(Css.center),
      tl: {
        hd: Css.fontSize(Css.pct(120)),
        tl: /* [] */0
      }
    });

var Cls = {
  soldOut: soldOut
};

function SoldOutWidget(Props) {
  var invoiceId = Props.invoiceId;
  var canAddPayment = Props.canAddPayment;
  var apolloData = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          invoiceId: invoiceId
        }
      ]);
  var match = apolloData.data;
  if (apolloData.loading) {
    return Utils_react.ste("");
  }
  if (apolloData.error !== undefined) {
    return Utils_react.ste("");
  }
  if (match === undefined) {
    return Utils_react.ste("");
  }
  var invoice = match.invoice;
  if (invoice === undefined) {
    return Utils_react.ste("Facture introuvable");
  }
  if (invoice.soldOut) {
    return React.createElement("div", undefined, React.createElement("div", {
                    className: soldOut
                  }, React.createElement("i", {
                        className: "fa fa-check-square-o text-bs-success"
                      }), Utils_react.ste("" + Utils_unicode.nbsp + Utils_unicode.nbsp + "Soldée"), React.createElement(ModalPaymentsButton.make, {
                        className: "btn btn-link",
                        label: Utils_react.ste("Voir les paiements"),
                        invoiceId: invoiceId,
                        canAddPayment: canAddPayment
                      })));
  }
  var total = invoice.totalAfterTax;
  var totalPaid = invoice.totalPaid;
  if (total > 0) {
    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: "progress progress-sold-out"
                  }, React.createElement("div", {
                        "aria-valuemax": total,
                        "aria-valuemin": totalPaid,
                        "aria-valuenow": totalPaid,
                        className: Curry._1(Css.merge, {
                              hd: "progress-bar",
                              tl: {
                                hd: Curry._1(Css.style, {
                                      hd: Css.width(Css.pct(totalPaid * 100 / total)),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }),
                        role: "progressbar"
                      }), React.createElement("div", {
                        className: "progress-bar-label"
                      }, Utils_react.ste("" + Utils_unicode.nbsp + Utils_unicode.nbsp + "Payé:" + Utils_unicode.nbsp), Utils_react.ste(Numeral.formatAutoFloat(totalPaid)), Utils_react.ste("" + Utils_unicode.nbsp + "€" + Utils_unicode.nbsp + "/" + Utils_unicode.nbsp), Utils_react.ste(Numeral.formatAutoFloat(total)), Utils_react.ste("" + Utils_unicode.nbsp + "€"))), invoice.countPayments > 0 ? React.createElement(ModalPaymentsButton.make, {
                      className: "btn btn-link",
                      label: Utils_react.ste("Voir les paiements"),
                      invoiceId: invoiceId,
                      canAddPayment: canAddPayment
                    }) : null);
  } else {
    return null;
  }
}

var ste = Utils_react.ste;

var nbspE = Utils_unicode.nbspElement;

var nbsp = Utils_unicode.nbsp;

var make = SoldOutWidget;

var $$default = SoldOutWidget;

export {
  SoldOutQuery ,
  ste ,
  nbspE ,
  nbsp ,
  Cls ,
  make ,
  $$default ,
  $$default as default,
  
}
/* query Not a pure module */
