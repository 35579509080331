// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Map from "bs-platform/lib/es6/map.js";
import * as List from "bs-platform/lib/es6/list.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Js_exn from "bs-platform/lib/es6/js_exn.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Caml_js_exceptions from "bs-platform/lib/es6/caml_js_exceptions.js";

function memo(f) {
  var m = {
    contents: /* [] */0
  };
  return function (x) {
    try {
      return List.assoc(x, m.contents);
    }
    catch (raw_exn){
      var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
      if (exn.RE_EXN_ID === "Not_found") {
        var y = Curry._1(f, x);
        m.contents = {
          hd: [
            x,
            y
          ],
          tl: m.contents
        };
        return y;
      }
      throw exn;
    }
  };
}

function objCopySet(obj, key, value) {
  var data = {};
  data[key] = value;
  var copy = Object.assign({}, obj);
  return Object.assign(copy, data);
}

function unique_id(prefix) {
  var i = {
    contents: 0
  };
  return function (param) {
    i.contents = i.contents + 1 | 0;
    return prefix + ("-" + String(i.contents));
  };
}

function mapFromList(mapEmpty, mapAdd, list) {
  var _list = list;
  var _acc = mapEmpty;
  while(true) {
    var acc = _acc;
    var list$1 = _list;
    if (!list$1) {
      return acc;
    }
    var match = list$1.hd;
    _acc = Curry._3(mapAdd, match[0], match[1], acc);
    _list = list$1.tl;
    continue ;
  };
}

var StringMap = $$Map.Make({
      compare: $$String.compare
    });

function stringMapFromList(list) {
  return mapFromList(StringMap.empty, StringMap.add, list);
}

function listWithoutNone(list) {
  return List.map((function (e) {
                if (e !== undefined) {
                  return Caml_option.valFromOption(e);
                } else {
                  return Js_exn.raiseError("impossible error");
                }
              }), List.filter(function (e) {
                    return e !== undefined;
                  })(list));
}

function arrayWithoutNone(array) {
  return array.filter(function (e) {
                return e !== undefined;
              }).map(function (e) {
              if (e !== undefined) {
                return Caml_option.valFromOption(e);
              } else {
                return Js_exn.raiseError("impossible error");
              }
            });
}

function stringOfFloat(f) {
  if ((f | 0) === f) {
    return String(f | 0);
  } else {
    return f.toString();
  }
}

export {
  memo ,
  objCopySet ,
  unique_id ,
  mapFromList ,
  StringMap ,
  stringMapFromList ,
  listWithoutNone ,
  arrayWithoutNone ,
  stringOfFloat ,
  
}
/* StringMap Not a pure module */
