// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/src/Wrap.bs.js";
import * as Field$ReasonForm from "@maarekj/reason-form/src/Field.bs.js";
import * as InvoiceStyle_WinroFields from "./Winro/InvoiceStyle_WinroFields.bs.js";
import * as InvoiceStyle_ColdraFields from "./Coldra/InvoiceStyle_ColdraFields.bs.js";

function emptyForStyle(v) {
  switch (v) {
    case "coldra" :
        return {
                TAG: 0,
                _0: InvoiceStyle_ColdraFields.Value.empty,
                [Symbol.for("name")]: "Coldra"
              };
    case "winro" :
        return {
                TAG: 1,
                _0: InvoiceStyle_WinroFields.Value.empty,
                [Symbol.for("name")]: "Winro"
              };
    default:
      return {
              TAG: 0,
              _0: InvoiceStyle_ColdraFields.Value.empty,
              [Symbol.for("name")]: "Coldra"
            };
  }
}

var empty = emptyForStyle("coldra");

function getName(v) {
  if (v.TAG === /* Coldra */0) {
    return "coldra";
  } else {
    return "winro";
  }
}

function fromJson(json) {
  var style = Json_decode.field("style", Json_decode.string, json);
  switch (style) {
    case "coldra" :
        return {
                TAG: 0,
                _0: InvoiceStyle_ColdraFields.Value.fromJson(json),
                [Symbol.for("name")]: "Coldra"
              };
    case "winro" :
        return {
                TAG: 1,
                _0: InvoiceStyle_WinroFields.Value.fromJson(json),
                [Symbol.for("name")]: "Winro"
              };
    default:
      return {
              TAG: 0,
              _0: InvoiceStyle_ColdraFields.Value.empty,
              [Symbol.for("name")]: "Coldra"
            };
  }
}

function toJson(v) {
  if (v.TAG === /* Coldra */0) {
    return InvoiceStyle_ColdraFields.Value.toJson(v._0);
  } else {
    return InvoiceStyle_WinroFields.Value.toJson(v._0);
  }
}

var Value = {
  emptyForStyle: emptyForStyle,
  empty: empty,
  getName: getName,
  fromJson: fromJson,
  toJson: toJson
};

function createFields(baseField) {
  return Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("value", (function (v) {
                    return v;
                  }), (function (value, _v) {
                    return value;
                  })));
}

function useForm(initialValues) {
  return React.useMemo((function () {
                var form = Form$ReasonForm.initializeForm(initialValues, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
                var wrap = Wrap$ReasonForm.make(undefined, form);
                var rootField = Field$ReasonForm.idField("root");
                return [
                        wrap,
                        createFields(rootField)
                      ];
              }), []);
}

var Coldra;

var Winro;

export {
  Coldra ,
  Winro ,
  Value ,
  createFields ,
  useForm ,
  
}
/* empty Not a pure module */
