// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Vendor_dom from "../vendor/Vendor_dom.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils_error from "../utils/Utils_error.bs.js";
import * as Utils_react from "../utils/Utils_react.bs.js";
import Join from "lodash/join";
import * as Utils_promise from "../utils/Utils_promise.bs.js";
import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";
import * as Hook$ReasonForm from "@maarekj/reason-form/src/Hook.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/src/Wrap.bs.js";
import * as MediaUploadAction from "./MediaUploadAction.bs.js";
import * as MediaUpload_PreviewMedia from "./MediaUpload_PreviewMedia.bs.js";
import * as BootstrapRender$ReasonForm from "@maarekj/reason-form/src/BootstrapRender.bs.js";

function defaultRenderControl(param) {
  var mediaTypes = param.mediaTypes;
  var accept = Belt_List.has(mediaTypes, "image", (function (a, b) {
          return a === b;
        })) ? ({
        hd: ".jpg",
        tl: {
          hd: ".jpeg",
          tl: {
            hd: ".png",
            tl: /* [] */0
          }
        }
      }) : /* [] */0;
  var accept$1 = Belt_List.has(mediaTypes, "pdf", (function (a, b) {
          return a === b;
        })) ? ({
        hd: ".pdf",
        tl: accept
      }) : accept;
  var tmp = {
    className: "control-file",
    accept: Join(Belt_List.toArray(accept$1), ", "),
    type: "file",
    onChange: param.onChange
  };
  if (param.inputId !== undefined) {
    tmp.id = Caml_option.valFromOption(param.inputId);
  }
  if (param.onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(param.onFocus);
  }
  if (param.onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(param.onBlur);
  }
  return React.createElement("input", tmp);
}

function MediaUpload$DefaultRenderPending(Props) {
  return React.createElement("div", {
              className: "form-control"
            }, Utils_react.ste("Chargement"));
}

var DefaultRenderPending = {
  make: MediaUpload$DefaultRenderPending
};

function defaultRenderPending(param) {
  return React.createElement(MediaUpload$DefaultRenderPending, {});
}

function defaultRenderMedia(withClear, param) {
  return React.createElement(MediaUpload_PreviewMedia.make, {
              withClear: withClear,
              onClear: param.onClear,
              inputId: param.inputId,
              mediaId: param.mediaId
            });
}

function defaultRenderError(error, clearError) {
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "form-control"
                }, Utils_react.ste(error)), React.createElement("button", {
                  className: "btn btn-danger ml-3",
                  type: "button",
                  onClick: (function (param) {
                      return Utils_react.preventCallback(clearError, param);
                    })
                }, React.createElement("i", {
                      className: "fa fa-trash fill-current"
                    })));
}

function reducer(_state, mediaId) {
  if (typeof mediaId === "number") {
    if (mediaId === /* Clear */0) {
      return /* Empty */0;
    } else {
      return /* Loading */1;
    }
  }
  switch (mediaId.TAG | 0) {
    case /* Change */0 :
    case /* UploadSuccess */1 :
        return {
                TAG: 0,
                _0: mediaId._0,
                [Symbol.for("name")]: "Loaded"
              };
    case /* UploadFailed */2 :
        return {
                TAG: 1,
                _0: mediaId._0,
                [Symbol.for("name")]: "Error"
              };
    
  }
}

function MediaUpload$Controlled(Props) {
  var mediaTypesOpt = Props.mediaTypes;
  var inputId = Props.inputId;
  var mediaId = Props.mediaId;
  var onChangeProps = Props.onChange;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var contextOpt = Props.context;
  var renderControlOpt = Props.renderControl;
  var renderPendingOpt = Props.renderPending;
  var renderMediaOpt = Props.renderMedia;
  var renderErrorOpt = Props.renderError;
  var mediaTypes = mediaTypesOpt !== undefined ? mediaTypesOpt : ({
        hd: "image",
        tl: /* [] */0
      });
  var context = contextOpt !== undefined ? contextOpt : "tmp";
  var renderControl = renderControlOpt !== undefined ? renderControlOpt : defaultRenderControl;
  var renderPending = renderPendingOpt !== undefined ? renderPendingOpt : defaultRenderPending;
  var renderMedia = renderMediaOpt !== undefined ? renderMediaOpt : (function (param) {
        return defaultRenderMedia(true, param);
      });
  var renderError = renderErrorOpt !== undefined ? renderErrorOpt : defaultRenderError;
  var previousMediaId = Utils_react.usePrevious(mediaId);
  var match = React.useReducer(reducer, mediaId !== undefined ? ({
            TAG: 0,
            _0: mediaId,
            [Symbol.for("name")]: "Loaded"
          }) : /* Empty */0);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (typeof state === "number") {
            if (state === /* Empty */0) {
              Curry._1(onChangeProps, undefined);
            }
            
          } else if (state.TAG === /* Loaded */0) {
            Curry._1(onChangeProps, state._0);
          } else {
            Curry._1(onChangeProps, undefined);
          }
          
        }), [state]);
  React.useEffect((function () {
          if (previousMediaId !== undefined && Caml_option.valFromOption(previousMediaId) !== undefined) {
            if (mediaId !== undefined) {
              Curry._1(dispatch, {
                    TAG: 0,
                    _0: mediaId,
                    [Symbol.for("name")]: "Change"
                  });
            } else {
              Curry._1(dispatch, /* Clear */0);
            }
          }
          
        }), [
        previousMediaId,
        mediaId,
        dispatch
      ]);
  var uploadStart = React.useCallback((function (file) {
          var mimeType = file.type;
          var mediaType;
          switch (mimeType) {
            case "application/pdf" :
                mediaType = "pdf";
                break;
            case "image/jpeg" :
            case "image/png" :
                mediaType = "image";
                break;
            default:
              mediaType = undefined;
          }
          Curry._1(dispatch, /* UploadStart */1);
          return Utils_promise.mapError(Utils_promise.mapOk(MediaUploadAction.upload(mediaType, context, {
                              TAG: 0,
                              _0: file,
                              [Symbol.for("name")]: "File"
                            }, undefined), (function (mediaId) {
                            return {
                                    TAG: 0,
                                    _0: Curry._1(dispatch, {
                                          TAG: 1,
                                          _0: mediaId,
                                          [Symbol.for("name")]: "UploadSuccess"
                                        }),
                                    [Symbol.for("name")]: "Ok"
                                  };
                          })), (function (param) {
                        return {
                                TAG: 0,
                                _0: Curry._1(dispatch, {
                                      TAG: 2,
                                      _0: Utils_error.exnToString(param.VAL),
                                      [Symbol.for("name")]: "UploadFailed"
                                    }),
                                [Symbol.for("name")]: "Ok"
                              };
                      }));
        }), [
        dispatch,
        context
      ]);
  var onChange = React.useCallback((function ($$event) {
          var file = Vendor_dom.$$File.getFirstFile($$event.target);
          if (file !== undefined) {
            Curry._1(uploadStart, Caml_option.valFromOption(file));
            return ;
          }
          
        }), [uploadStart]);
  if (typeof state === "number") {
    if (state === /* Empty */0) {
      return Curry._1(renderControl, {
                  mediaTypes: mediaTypes,
                  onChange: onChange,
                  onFocus: onFocus,
                  onBlur: onBlur,
                  inputId: inputId
                });
    } else {
      return Curry._1(renderPending, undefined);
    }
  } else if (state.TAG === /* Loaded */0) {
    return Curry._1(renderMedia, {
                onClear: (function (param) {
                    return Curry._1(onChangeProps, undefined);
                  }),
                inputId: inputId,
                mediaId: state._0
              });
  } else {
    return Curry._2(renderError, state._0, (function (param) {
                  return Curry._1(dispatch, /* Clear */0);
                }));
  }
}

var Controlled = {
  make: MediaUpload$Controlled
};

function MediaUpload$Uncontrolled(Props) {
  var inputId = Props.inputId;
  var mediaTypes = Props.mediaTypes;
  var defaultMediaIdOpt = Props.defaultMediaId;
  var onChangeOpt = Props.onChange;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var context = Props.context;
  var renderControl = Props.renderControl;
  var renderPending = Props.renderPending;
  var renderMediaOpt = Props.renderMedia;
  var renderError = Props.renderError;
  var defaultMediaId = defaultMediaIdOpt !== undefined ? Caml_option.valFromOption(defaultMediaIdOpt) : undefined;
  var onChange = onChangeOpt !== undefined ? onChangeOpt : (function (param) {
        
      });
  var renderMedia = renderMediaOpt !== undefined ? renderMediaOpt : (function (param) {
        return defaultRenderMedia(true, param);
      });
  var match = React.useState(function () {
        return defaultMediaId;
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          Curry._1(onChange, state);
          
        }), [
        state,
        onChange
      ]);
  var onChange$1 = React.useCallback((function (mediaId) {
          return Curry._1(setState, (function (param) {
                        return mediaId;
                      }));
        }), [setState]);
  var tmp = {
    mediaTypes: mediaTypes,
    mediaId: state,
    onChange: onChange$1,
    renderMedia: renderMedia
  };
  if (inputId !== undefined) {
    tmp.inputId = Caml_option.valFromOption(inputId);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  if (context !== undefined) {
    tmp.context = Caml_option.valFromOption(context);
  }
  if (renderControl !== undefined) {
    tmp.renderControl = Caml_option.valFromOption(renderControl);
  }
  if (renderPending !== undefined) {
    tmp.renderPending = Caml_option.valFromOption(renderPending);
  }
  if (renderError !== undefined) {
    tmp.renderError = Caml_option.valFromOption(renderError);
  }
  return React.createElement(MediaUpload$Controlled, tmp);
}

var Uncontrolled = {
  make: MediaUpload$Uncontrolled
};

function MediaUpload$FormInput(Props) {
  var wrap = Props.wrap;
  var id = Props.id;
  var field = Props.field;
  var context = Props.context;
  var renderControl = Props.renderControl;
  var renderPending = Props.renderPending;
  var renderMedia = Props.renderMedia;
  var renderError = Props.renderError;
  var value = Hook$ReasonForm.useValue(wrap, field);
  var key = field.key;
  var inputId;
  if (id !== undefined) {
    inputId = id;
  } else {
    var prefixId = Wrap$ReasonForm.id(wrap);
    inputId = BootstrapRender$ReasonForm.normalizeId(prefixId + ("-" + key));
  }
  var onChange = React.useCallback((function (value) {
          return Wrap$ReasonForm.dispatch(wrap, (function (form) {
                        var value$1 = Curry._2(field.setValue, value, Form$ReasonForm.getValues(form));
                        return Form$ReasonForm.changeValues({
                                    hd: field.key,
                                    tl: /* [] */0
                                  }, value$1, form);
                      }));
        }), [
        wrap,
        field
      ]);
  var onFocus = React.useCallback((function (_event) {
          return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                        return Form$ReasonForm.focus(key, param);
                      }));
        }), [
        wrap,
        key
      ]);
  var onBlur = React.useCallback((function (_event) {
          return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                        return Form$ReasonForm.blur(key, param);
                      }));
        }), [
        wrap,
        key
      ]);
  return React.useMemo((function () {
                var tmp = {
                  inputId: inputId,
                  mediaId: value,
                  onChange: onChange,
                  onFocus: onFocus,
                  onBlur: onBlur
                };
                if (context !== undefined) {
                  tmp.context = Caml_option.valFromOption(context);
                }
                if (renderControl !== undefined) {
                  tmp.renderControl = Caml_option.valFromOption(renderControl);
                }
                if (renderPending !== undefined) {
                  tmp.renderPending = Caml_option.valFromOption(renderPending);
                }
                if (renderMedia !== undefined) {
                  tmp.renderMedia = Caml_option.valFromOption(renderMedia);
                }
                if (renderError !== undefined) {
                  tmp.renderError = Caml_option.valFromOption(renderError);
                }
                return React.createElement(MediaUpload$Controlled, tmp);
              }), [
              value,
              inputId,
              context,
              onChange,
              onFocus,
              onBlur,
              renderControl,
              renderPending,
              renderMedia,
              renderError
            ]);
}

var FormInput = {
  Form: undefined,
  Hook: undefined,
  Helper: undefined,
  Wrap: undefined,
  make: MediaUpload$FormInput
};

function makeProps(prim, prim$1, prim$2, prim$3, prim$4, prim$5, prim$6, prim$7, prim$8, prim$9, prim$10, prim$11, prim$12) {
  var tmp = {
    mediaId: prim$2,
    onChange: prim$3
  };
  if (prim !== undefined) {
    tmp.mediaTypes = Caml_option.valFromOption(prim);
  }
  if (prim$1 !== undefined) {
    tmp.inputId = Caml_option.valFromOption(prim$1);
  }
  if (prim$4 !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(prim$4);
  }
  if (prim$5 !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(prim$5);
  }
  if (prim$6 !== undefined) {
    tmp.context = Caml_option.valFromOption(prim$6);
  }
  if (prim$7 !== undefined) {
    tmp.renderControl = Caml_option.valFromOption(prim$7);
  }
  if (prim$8 !== undefined) {
    tmp.renderPending = Caml_option.valFromOption(prim$8);
  }
  if (prim$9 !== undefined) {
    tmp.renderMedia = Caml_option.valFromOption(prim$9);
  }
  if (prim$10 !== undefined) {
    tmp.renderError = Caml_option.valFromOption(prim$10);
  }
  if (prim$11 !== undefined) {
    tmp.key = Caml_option.valFromOption(prim$11);
  }
  return tmp;
}

var Actions;

var ste = Utils_react.ste;

var preventCallback = Utils_react.preventCallback;

var mapOk = Utils_promise.mapOk;

var flatMapOk = Utils_promise.flatMapOk;

var mapError = Utils_promise.mapError;

var convertResult = Utils_promise.convertResult;

var make = MediaUpload$Controlled;

export {
  Actions ,
  ste ,
  preventCallback ,
  mapOk ,
  flatMapOk ,
  mapError ,
  convertResult ,
  defaultRenderControl ,
  DefaultRenderPending ,
  defaultRenderPending ,
  defaultRenderMedia ,
  defaultRenderError ,
  reducer ,
  Controlled ,
  Uncontrolled ,
  FormInput ,
  make ,
  makeProps ,
  
}
/* react Not a pure module */
