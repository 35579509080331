// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";

var emailRe = /\S+@\S+\.\S+/;

function validate(value, field, messageOpt, param, form) {
  var message = messageOpt !== undefined ? messageOpt : "Email invalide.";
  if (value === "" || emailRe.test(value)) {
    return form;
  } else {
    return Form$ReasonForm.addError(field.key, message, form);
  }
}

export {
  emailRe ,
  validate ,
  
}
/* No side effect */
