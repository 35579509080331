// Generated by ReScript, PLEASE EDIT WITH CARE

import Jquery from "jquery";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

var version = ((jquery) => jquery.fn.jquery);

function get(t, index) {
  return Caml_option.nullable_to_opt(t.get(index));
}

function isChecked(elt) {
  return elt.is(":checked");
}

function onReady(cb) {
  Jquery("document").ready(cb);
  
}

var $$Event = {};

function setVal(element, value) {
  element.select2("val", value);
  
}

function setData(element, data) {
  element.select2("data", data);
  
}

var Select2 = {
  setVal: setVal,
  setData: setData
};

export {
  version ,
  get ,
  isChecked ,
  onReady ,
  $$Event ,
  Select2 ,
  
}
/* jquery Not a pure module */
