// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Utils_json from "./Utils_json.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Utils_option from "./Utils_option.bs.js";

var exnToString = (function(error) { return error !== null && error.toString !== null ? error.toString() : "unknown error."; });

function messageFromJson(json) {
  return Belt_Option.flatMap(Js_json.decodeObject(json), (function (param) {
                return Utils_json.getString("message", param);
              }));
}

function messageFromJsonStrict(json) {
  return Utils_option.def(messageFromJson(json), "Unknown error.");
}

export {
  exnToString ,
  messageFromJson ,
  messageFromJsonStrict ,
  
}
/* No side effect */
