// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "../vendor/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Id from "bs-platform/lib/es6/belt_Id.js";
import * as Belt_Set from "bs-platform/lib/es6/belt_Set.js";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Form_Renderer from "./Form_Renderer.bs.js";
import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";
import * as Hook$ReasonForm from "@maarekj/reason-form/src/Hook.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/src/Wrap.bs.js";
import * as Helper$ReasonForm from "@maarekj/reason-form/src/Helper.bs.js";

function Form_Choice$Pure(Props) {
  var wrap = Props.wrap;
  var field = Props.field;
  var className = Props.className;
  var labelClass = Props.labelClass;
  var inputClass = Props.inputClass;
  var id = Props.id;
  var choices = Props.choices;
  var nbSubmits = Props.nbSubmits;
  var hasError = Props.hasError;
  var isAlreadyBlur = Props.isAlreadyBlur;
  var handleCheck = Props.handleCheck;
  var isChecked = Props.isChecked;
  var type_ = Props.type_;
  var onFocus = React.useCallback((function (_event) {
          var partial_arg = field.key;
          return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                        return Form$ReasonForm.focus(partial_arg, param);
                      }));
        }), [field.key]);
  var onBlur = React.useCallback((function (_event) {
          var partial_arg = field.key;
          return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                        return Form$ReasonForm.blur(partial_arg, param);
                      }));
        }), [field.key]);
  var toString = React.useCallback((function (value) {
          var choice = Belt_List.getByU(choices, (function (choice) {
                  return Caml_obj.caml_equal(choice.value, value);
                }));
          if (choice !== undefined) {
            return choice.string;
          } else {
            return "";
          }
        }), [choices]);
  var fromString = React.useCallback((function (string) {
          var choice = Belt_List.getByU(choices, (function (choice) {
                  return choice.string === string;
                }));
          if (choice !== undefined) {
            return Caml_option.some(choice.value);
          }
          
        }), [choices]);
  var onChange = React.useCallback((function (string) {
          return function (_event) {
            var value = Curry._1(fromString, string);
            if (value !== undefined) {
              return Wrap$ReasonForm.dispatch(wrap, Curry._1(handleCheck, Caml_option.valFromOption(value)));
            }
            
          };
        }), [
        wrap,
        fromString,
        handleCheck
      ]);
  var pureChoices = React.useMemo((function () {
          return Belt_List.mapU(choices, (function (c) {
                        return {
                                pureString: c.string,
                                pureLabel: c.label
                              };
                      }));
        }), [choices]);
  var id$1;
  if (id !== undefined) {
    id$1 = id;
  } else {
    var prefixId = Wrap$ReasonForm.id(wrap);
    id$1 = Form_Renderer.normalizeId(prefixId + ("-" + field.key));
  }
  return React.useMemo((function () {
                return React.createElement("div", {
                            className: Cn.$plus(className, Cn.on("is-invalid", (nbSubmits > 0 || isAlreadyBlur) && hasError))
                          }, Belt_List.toArray(Belt_List.mapU(pureChoices, (function (choice) {
                                      return React.createElement("label", {
                                                  key: "label-" + choice.pureString,
                                                  className: Cn.$plus("form-checkbox-label", labelClass),
                                                  htmlFor: Form_Renderer.normalizeId(id$1 + ("--" + choice.pureString))
                                                }, React.createElement("input", {
                                                      className: Cn.$plus("form-checkbox", inputClass),
                                                      id: Form_Renderer.normalizeId(id$1 + ("--" + choice.pureString)),
                                                      checked: Curry._2(isChecked, choice.pureString, toString),
                                                      type: type_,
                                                      onFocus: onFocus,
                                                      onBlur: onBlur,
                                                      onChange: Curry._1(onChange, choice.pureString)
                                                    }), choice.pureLabel);
                                    }))));
              }), [
              type_,
              className,
              labelClass,
              inputClass,
              nbSubmits,
              isAlreadyBlur,
              hasError,
              pureChoices,
              id$1,
              isChecked,
              onChange,
              toString
            ]);
}

var Pure = {
  make: Form_Choice$Pure
};

var cmp = Caml_obj.caml_compare;

var CompareId = Belt_Id.MakeComparable({
      cmp: cmp
    });

function Form_Choice$Checkbox(Props) {
  var wrap = Props.wrap;
  var field = Props.field;
  var classNameOpt = Props.className;
  var labelClassOpt = Props.labelClass;
  var inputClassOpt = Props.inputClass;
  var id = Props.id;
  var choices = Props.choices;
  var className = classNameOpt !== undefined ? classNameOpt : Cn.none;
  var labelClass = labelClassOpt !== undefined ? labelClassOpt : Cn.none;
  var inputClass = inputClassOpt !== undefined ? inputClassOpt : Cn.none;
  var values = Hook$ReasonForm.useValue(wrap, field);
  var match = Hook$ReasonForm.useFormMeta(wrap);
  var match$1 = Hook$ReasonForm.useMeta(wrap, field);
  var handleCheck = React.useCallback((function (value) {
          return function (form) {
            return Helper$ReasonForm.update(field, (function (setValue) {
                          var set = Belt_Set.fromArray(Belt_List.toArray(setValue), CompareId);
                          return Belt_Set.toList(Belt_Set.has(set, value) ? Belt_Set.remove(set, value) : Belt_Set.add(set, value));
                        }), form);
          };
        }), [field]);
  var isChecked = React.useCallback((function (str) {
          return function (toString) {
            return Belt_Option.isSome(Belt_List.getByU(Belt_List.map(values, toString), (function (v) {
                              return v === str;
                            })));
          };
        }), [values]);
  var tmp = {
    wrap: wrap,
    field: field,
    className: className,
    labelClass: labelClass,
    inputClass: inputClass,
    choices: choices,
    nbSubmits: match.nbSubmits,
    hasError: match$1.hasError,
    isAlreadyBlur: match$1.isAlreadyBlur,
    handleCheck: handleCheck,
    isChecked: isChecked,
    type_: "checkbox"
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement(Form_Choice$Pure, tmp);
}

var Checkbox = {
  CompareId: CompareId,
  make: Form_Choice$Checkbox
};

function Form_Choice$Radio(Props) {
  var wrap = Props.wrap;
  var field = Props.field;
  var classNameOpt = Props.className;
  var labelClassOpt = Props.labelClass;
  var inputClassOpt = Props.inputClass;
  var id = Props.id;
  var choices = Props.choices;
  var className = classNameOpt !== undefined ? classNameOpt : Cn.none;
  var labelClass = labelClassOpt !== undefined ? labelClassOpt : Cn.none;
  var inputClass = inputClassOpt !== undefined ? inputClassOpt : Cn.none;
  var values = Hook$ReasonForm.useValue(wrap, field);
  var match = Hook$ReasonForm.useFormMeta(wrap);
  var match$1 = Hook$ReasonForm.useMeta(wrap, field);
  var handleCheck = React.useCallback((function (value) {
          return function (form) {
            return Helper$ReasonForm.changeFieldValue(field, value, form);
          };
        }), [field]);
  var isChecked = React.useCallback((function (str) {
          return function (toString) {
            return str === Curry._1(toString, values);
          };
        }), [values]);
  var tmp = {
    wrap: wrap,
    field: field,
    className: className,
    labelClass: labelClass,
    inputClass: inputClass,
    choices: choices,
    nbSubmits: match.nbSubmits,
    hasError: match$1.hasError,
    isAlreadyBlur: match$1.isAlreadyBlur,
    handleCheck: handleCheck,
    isChecked: isChecked,
    type_: "radio"
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement(Form_Choice$Pure, tmp);
}

var Radio = {
  make: Form_Choice$Radio
};

export {
  Pure ,
  Checkbox ,
  Radio ,
  
}
/* CompareId Not a pure module */
