// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import Jquery from "jquery";
import * as Css_Core from "bs-css/src/Css_Core.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils_react from "../utils/Utils_react.bs.js";
import Signature_pad from "signature_pad";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";

function makeConfig(backgroundColorOpt, onBegin, onEnd, param) {
  var backgroundColor = backgroundColorOpt !== undefined ? backgroundColorOpt : "rgb(255,255,255)";
  return {
          backgroundColor: backgroundColor,
          onBegin: Js_null_undefined.fromOption(onBegin),
          onEnd: Js_null_undefined.fromOption(onEnd)
        };
}

var canvas = Curry._1(Css.style, {
      hd: Css.border(Css.px(1), Css.solid, Css.hex("#AAA")),
      tl: {
        hd: Css.boxShadow(Css_Core.Shadow.box(Css.zero, Css.px(1), Css.px(4), undefined, undefined, Css.rgba(0, 0, 0, {
                      NAME: "num",
                      VAL: 0.2
                    }))),
        tl: {
          hd: Css.marginBottom(Css.px(10)),
          tl: /* [] */0
        }
      }
    });

var clearButton = Curry._1(Css.merge, {
      hd: "btn",
      tl: {
        hd: "btn-block",
        tl: {
          hd: "btn-default",
          tl: {
            hd: Curry._1(Css.style, {
                  hd: Css.width(Css.px(270)),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var Cls = {
  canvas: canvas,
  clearButton: clearButton
};

function SignaturePad(Props) {
  var inputId = Props.inputId;
  var defaultValue = Props.defaultValue;
  var match = React.useState(function () {
        return Belt_Option.isNone((defaultValue == null) ? undefined : Caml_option.some(defaultValue));
      });
  var setDisplayPad = match[1];
  var match$1 = React.useState(function () {
        if (defaultValue == null) {
          return ;
        } else {
          return Caml_option.some(defaultValue);
        }
      });
  var setValue = match$1[1];
  var value = match$1[0];
  var signaturePadRef = React.useRef(undefined);
  var onEnd = React.useCallback((function (param) {
          var signaturePad = signaturePadRef.current;
          if (signaturePad === undefined) {
            return ;
          }
          var data = Caml_option.valFromOption(signaturePad).toDataURL();
          return Curry._1(setValue, (function (param) {
                        return data;
                      }));
        }), []);
  var onClear = React.useCallback((function ($$event) {
          $$event.preventDefault();
          var signaturePad = signaturePadRef.current;
          if (signaturePad !== undefined) {
            Caml_option.valFromOption(signaturePad).clear();
          }
          Curry._1(setValue, (function (param) {
                  
                }));
          return Curry._1(setDisplayPad, (function (param) {
                        return true;
                      }));
        }), []);
  React.useEffect((function () {
          var exit = 0;
          var inputId$1;
          if (!(inputId == null)) {
            if (value !== undefined && value !== "") {
              Jquery("#" + inputId).val(value);
            } else {
              inputId$1 = inputId;
              exit = 1;
            }
          }
          if (exit === 1) {
            Jquery("#" + inputId$1).val("");
          }
          
        }), [
        inputId,
        value
      ]);
  var tmp;
  if (match[0]) {
    tmp = React.createElement(React.Fragment, undefined, React.createElement("canvas", {
              ref: (function (canvas) {
                  var match = signaturePadRef.current;
                  if (canvas == null) {
                    return ;
                  }
                  if (match !== undefined) {
                    return ;
                  }
                  var signaturePad = new Signature_pad(canvas, makeConfig(undefined, undefined, onEnd, undefined));
                  signaturePadRef.current = Caml_option.some(signaturePad);
                  
                }),
              className: canvas,
              height: "200",
              width: "270"
            }), React.createElement("button", {
              className: clearButton,
              onClick: onClear
            }, Utils_react.ste("Effacer")));
  } else {
    var tmp$1 = {};
    if (value !== undefined) {
      tmp$1.src = Caml_option.valFromOption(value);
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement("img", tmp$1), React.createElement("button", {
              className: clearButton,
              onClick: onClear
            }, Utils_react.ste("Effacer")));
  }
  return React.createElement("div", undefined, tmp);
}

var ste = Utils_react.ste;

var preventCallback = Utils_react.preventCallback;

var make = SignaturePad;

var $$default = SignaturePad;

export {
  ste ,
  preventCallback ,
  makeConfig ,
  Cls ,
  make ,
  $$default ,
  $$default as default,
  
}
/* canvas Not a pure module */
