// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/src/Wrap.bs.js";
import * as Field$ReasonForm from "@maarekj/reason-form/src/Field.bs.js";

function fromJson(json) {
  return {
          topLeft: Json_decode.field("topLeft", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          topMiddle: Json_decode.field("topMiddle", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          topRight: Json_decode.field("topRight", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          bottom: Json_decode.field("bottom", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          footer: Json_decode.field("footer", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          mainColor: Json_decode.field("mainColor", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          border1Color: Json_decode.field("border1Color", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          border2Color: Json_decode.field("border2Color", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json)
        };
}

function toJson(v) {
  return Json_encode.object_({
              hd: [
                "style",
                "coldra"
              ],
              tl: {
                hd: [
                  "topLeft",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), v.topLeft)
                ],
                tl: {
                  hd: [
                    "topMiddle",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), v.topMiddle)
                  ],
                  tl: {
                    hd: [
                      "topRight",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), v.topRight)
                    ],
                    tl: {
                      hd: [
                        "bottom",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), v.bottom)
                      ],
                      tl: {
                        hd: [
                          "footer",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), v.footer)
                        ],
                        tl: {
                          hd: [
                            "mainColor",
                            Json_encode.nullable((function (prim) {
                                    return prim;
                                  }), v.mainColor)
                          ],
                          tl: {
                            hd: [
                              "border1Color",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), v.border1Color)
                            ],
                            tl: {
                              hd: [
                                "border2Color",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), v.border2Color)
                              ],
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Value_empty = {
  topLeft: undefined,
  topMiddle: undefined,
  topRight: undefined,
  bottom: undefined,
  footer: undefined,
  mainColor: "#AF5131",
  border1Color: "#2A2813",
  border2Color: "#E4D3BE"
};

var Value = {
  empty: Value_empty,
  fromJson: fromJson,
  toJson: toJson
};

function createFields(self, baseField) {
  return {
          self: self,
          topLeft: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("topLeft", (function (v) {
                      return v.topLeft;
                    }), (function (topLeft, v) {
                      return {
                              topLeft: topLeft,
                              topMiddle: v.topMiddle,
                              topRight: v.topRight,
                              bottom: v.bottom,
                              footer: v.footer,
                              mainColor: v.mainColor,
                              border1Color: v.border1Color,
                              border2Color: v.border2Color
                            };
                    }))),
          topMiddle: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("topMiddle", (function (v) {
                      return v.topMiddle;
                    }), (function (topMiddle, v) {
                      return {
                              topLeft: v.topLeft,
                              topMiddle: topMiddle,
                              topRight: v.topRight,
                              bottom: v.bottom,
                              footer: v.footer,
                              mainColor: v.mainColor,
                              border1Color: v.border1Color,
                              border2Color: v.border2Color
                            };
                    }))),
          topRight: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("topRight", (function (v) {
                      return v.topRight;
                    }), (function (topRight, v) {
                      return {
                              topLeft: v.topLeft,
                              topMiddle: v.topMiddle,
                              topRight: topRight,
                              bottom: v.bottom,
                              footer: v.footer,
                              mainColor: v.mainColor,
                              border1Color: v.border1Color,
                              border2Color: v.border2Color
                            };
                    }))),
          bottom: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("bottom", (function (v) {
                      return v.bottom;
                    }), (function (bottom, v) {
                      return {
                              topLeft: v.topLeft,
                              topMiddle: v.topMiddle,
                              topRight: v.topRight,
                              bottom: bottom,
                              footer: v.footer,
                              mainColor: v.mainColor,
                              border1Color: v.border1Color,
                              border2Color: v.border2Color
                            };
                    }))),
          footer: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("footer", (function (v) {
                      return v.footer;
                    }), (function (footer, v) {
                      return {
                              topLeft: v.topLeft,
                              topMiddle: v.topMiddle,
                              topRight: v.topRight,
                              bottom: v.bottom,
                              footer: footer,
                              mainColor: v.mainColor,
                              border1Color: v.border1Color,
                              border2Color: v.border2Color
                            };
                    }))),
          mainColor: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("mainColor", (function (v) {
                      return v.mainColor;
                    }), (function (mainColor, v) {
                      return {
                              topLeft: v.topLeft,
                              topMiddle: v.topMiddle,
                              topRight: v.topRight,
                              bottom: v.bottom,
                              footer: v.footer,
                              mainColor: mainColor,
                              border1Color: v.border1Color,
                              border2Color: v.border2Color
                            };
                    }))),
          border1Color: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("border1Color", (function (v) {
                      return v.border1Color;
                    }), (function (border1Color, v) {
                      return {
                              topLeft: v.topLeft,
                              topMiddle: v.topMiddle,
                              topRight: v.topRight,
                              bottom: v.bottom,
                              footer: v.footer,
                              mainColor: v.mainColor,
                              border1Color: border1Color,
                              border2Color: v.border2Color
                            };
                    }))),
          border2Color: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("border2Color", (function (v) {
                      return v.border2Color;
                    }), (function (border2Color, v) {
                      return {
                              topLeft: v.topLeft,
                              topMiddle: v.topMiddle,
                              topRight: v.topRight,
                              bottom: v.bottom,
                              footer: v.footer,
                              mainColor: v.mainColor,
                              border1Color: v.border1Color,
                              border2Color: border2Color
                            };
                    })))
        };
}

function useForm(initialValues, onChangeValue, onBlur, onFocus, param) {
  return React.useMemo((function () {
                var form = Form$ReasonForm.initializeForm(initialValues, undefined, undefined, onBlur, onFocus, onChangeValue, undefined, undefined);
                var wrap = Wrap$ReasonForm.make(undefined, form);
                var rootField = Field$ReasonForm.idField("root");
                return [
                        wrap,
                        createFields(rootField, rootField)
                      ];
              }), []);
}

export {
  Value ,
  createFields ,
  useForm ,
  
}
/* react Not a pure module */
