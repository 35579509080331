// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Utils from "../../utils/Utils.bs.js";
import * as React from "react";
import * as Apollo from "../../Apollo.bs.js";
import * as Spinner from "../../Spinner.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils_react from "../../utils/Utils_react.bs.js";
import * as Form_Renderer from "../../Form/Form_Renderer.bs.js";
import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";
import * as React_bootstrap from "../../vendor/React_bootstrap.bs.js";
import * as GraphQLExtending from "../../Graphql/GraphQLExtending.bs.js";
import * as ModalSendEmail_Form from "./ModalSendEmail_Form.bs.js";
import * as ModalSendEmail_Fields from "./ModalSendEmail_Fields.bs.js";
import ModalBody from "react-bootstrap/lib/ModalBody";
import ModalTitle from "react-bootstrap/lib/ModalTitle";
import ModalFooter from "react-bootstrap/lib/ModalFooter";
import ModalHeader from "react-bootstrap/lib/ModalHeader";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  query ModalSendEmailQuery($clientId: Int!, $invoiceId: Int!, $quoteId: Int!, $contractId: Int!)  {
    client(id: $clientId)  {
      __typename
      id
      emailCompta
    }
    invoice(id: $invoiceId)  {
      __typename
      id
      number
      publicUrl
      date
    }
    contract(id: $contractId)  {
      __typename
      id
      number
      publicUrl
      date
      effectiveDate
    }
    quote(id: $quoteId)  {
      __typename
      id
      number
      publicUrl
      date
    }
  }
`);

function parse(value) {
  var value$1 = value.client;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.emailCompta;
    tmp = {
      __typename: value$1.__typename,
      id: value$1.id,
      emailCompta: !(value$2 == null) ? value$2 : undefined
    };
  }
  var value$3 = value.invoice;
  var value$4 = value.contract;
  var value$5 = value.quote;
  return {
          client: tmp,
          invoice: !(value$3 == null) ? ({
                __typename: value$3.__typename,
                id: value$3.id,
                number: value$3.number,
                publicUrl: value$3.publicUrl,
                date: GraphQLExtending.$$Date.parse(value$3.date)
              }) : undefined,
          contract: !(value$4 == null) ? ({
                __typename: value$4.__typename,
                id: value$4.id,
                number: value$4.number,
                publicUrl: value$4.publicUrl,
                date: GraphQLExtending.$$Date.parse(value$4.date),
                effectiveDate: GraphQLExtending.$$Date.parse(value$4.effectiveDate)
              }) : undefined,
          quote: !(value$5 == null) ? ({
                __typename: value$5.__typename,
                id: value$5.id,
                number: value$5.number,
                publicUrl: value$5.publicUrl,
                date: GraphQLExtending.$$Date.parse(value$5.date)
              }) : undefined
        };
}

function serialize(value) {
  var value$1 = value.quote;
  var quote;
  if (value$1 !== undefined) {
    var value$2 = value$1.date;
    var value$3 = GraphQLExtending.$$Date.serialize(value$2);
    var value$4 = value$1.publicUrl;
    var value$5 = value$1.number;
    var value$6 = value$1.id;
    var value$7 = value$1.__typename;
    quote = {
      __typename: value$7,
      id: value$6,
      number: value$5,
      publicUrl: value$4,
      date: value$3
    };
  } else {
    quote = null;
  }
  var value$8 = value.contract;
  var contract;
  if (value$8 !== undefined) {
    var value$9 = value$8.effectiveDate;
    var value$10 = GraphQLExtending.$$Date.serialize(value$9);
    var value$11 = value$8.date;
    var value$12 = GraphQLExtending.$$Date.serialize(value$11);
    var value$13 = value$8.publicUrl;
    var value$14 = value$8.number;
    var value$15 = value$8.id;
    var value$16 = value$8.__typename;
    contract = {
      __typename: value$16,
      id: value$15,
      number: value$14,
      publicUrl: value$13,
      date: value$12,
      effectiveDate: value$10
    };
  } else {
    contract = null;
  }
  var value$17 = value.invoice;
  var invoice;
  if (value$17 !== undefined) {
    var value$18 = value$17.date;
    var value$19 = GraphQLExtending.$$Date.serialize(value$18);
    var value$20 = value$17.publicUrl;
    var value$21 = value$17.number;
    var value$22 = value$17.id;
    var value$23 = value$17.__typename;
    invoice = {
      __typename: value$23,
      id: value$22,
      number: value$21,
      publicUrl: value$20,
      date: value$19
    };
  } else {
    invoice = null;
  }
  var value$24 = value.client;
  var client;
  if (value$24 !== undefined) {
    var value$25 = value$24.emailCompta;
    var emailCompta = value$25 !== undefined ? value$25 : null;
    var value$26 = value$24.id;
    var value$27 = value$24.__typename;
    client = {
      __typename: value$27,
      id: value$26,
      emailCompta: emailCompta
    };
  } else {
    client = null;
  }
  return {
          client: client,
          invoice: invoice,
          contract: contract,
          quote: quote
        };
}

function serializeVariables(inp) {
  return {
          clientId: inp.clientId,
          invoiceId: inp.invoiceId,
          quoteId: inp.quoteId,
          contractId: inp.contractId
        };
}

function makeVariables(clientId, invoiceId, quoteId, contractId, param) {
  return {
          clientId: clientId,
          invoiceId: invoiceId,
          quoteId: quoteId,
          contractId: contractId
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = (require("@apollo/client").gql`
  mutation SendEmailMutation($data: SendEmailInput!)  {
    sendEmail(data: $data)
  }
`);

function parse$1(value) {
  return {
          sendEmail: value.sendEmail
        };
}

function serialize$1(value) {
  var value$1 = value.sendEmail;
  return {
          sendEmail: value$1
        };
}

function serializeInputObjectSendEmailInput(inp) {
  var a = inp.invoice;
  var a$1 = inp.quote;
  var a$2 = inp.contract;
  return {
          to: inp.to,
          subject: inp.subject,
          body: inp.body,
          invoice: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          quote: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          contract: a$2 !== undefined ? Caml_option.valFromOption(a$2) : undefined
        };
}

function serializeVariables$1(inp) {
  return {
          data: serializeInputObjectSendEmailInput(inp.data)
        };
}

function makeVariables$1(data, param) {
  return {
          data: data
        };
}

function makeInputObjectSendEmailInput(to_, subject, body, invoice, quote, contract, param) {
  return {
          to: to_,
          subject: subject,
          body: body,
          invoice: invoice,
          quote: quote,
          contract: contract
        };
}

var SendEmailMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectSendEmailInput: serializeInputObjectSendEmailInput,
  makeVariables: makeVariables$1,
  makeInputObjectSendEmailInput: makeInputObjectSendEmailInput
};

var include$1 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var SendEmailMutation_useWithVariables = include$1.useWithVariables;

var SendEmailMutation = {
  SendEmailMutation_inner: SendEmailMutation_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectSendEmailInput: serializeInputObjectSendEmailInput,
  makeVariables: makeVariables$1,
  makeInputObjectSendEmailInput: makeInputObjectSendEmailInput,
  use: use$1,
  useWithVariables: SendEmailMutation_useWithVariables
};

function ModalSendEmail$Inner(Props) {
  var client = Props.client;
  var invoice = Props.invoice;
  var contract = Props.contract;
  var quote = Props.quote;
  var onClose = Props.onClose;
  var title = Props.title;
  var email = Belt_Option.flatMap(client, (function (param) {
          return param.emailCompta;
        }));
  var match = ModalSendEmail_Fields.useForm(ModalSendEmail_Fields.Value.make(email, undefined, undefined, undefined));
  var wrap = match[0];
  var match$1 = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutation = match$1[0];
  var onSubmit = Form_Renderer.Form.useOnSubmitWithPrevent(wrap, (function (form) {
          var values = Form$ReasonForm.getValues(form);
          return Apollo.promiseWrapError(Curry._8(mutation, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                          data: {
                            to: values.email,
                            subject: values.subject,
                            body: values.body,
                            invoice: Belt_Option.map(invoice, (function (param) {
                                    return GraphQLExtending.InvoiceId.serialize(param.id);
                                  })),
                            quote: Belt_Option.map(quote, (function (param) {
                                    return GraphQLExtending.QuoteId.serialize(param.id);
                                  })),
                            contract: Belt_Option.map(contract, (function (param) {
                                    return GraphQLExtending.ContractId.serialize(param.id);
                                  }))
                          }
                        }));
        }), undefined, undefined);
  var placeholders_0 = Belt_Option.mapWithDefault(invoice, /* [] */0, (function (param) {
          return {
                  hd: {
                    label: "Numéro de facture",
                    placeholder: "{{ invoice.number }}",
                    preview: param.number.toString()
                  },
                  tl: {
                    hd: {
                      label: "Url de la facture",
                      placeholder: "{{ invoiceUrl }}",
                      preview: param.publicUrl
                    },
                    tl: /* [] */0
                  }
                };
        }));
  var placeholders_1 = {
    hd: Belt_Option.mapWithDefault(quote, /* [] */0, (function (param) {
            return {
                    hd: {
                      label: "Numéro de devis",
                      placeholder: "{{ quote.number }}",
                      preview: param.number.toString()
                    },
                    tl: {
                      hd: {
                        label: "Url du devis",
                        placeholder: "{{ quoteUrl }}",
                        preview: param.publicUrl
                      },
                      tl: /* [] */0
                    }
                  };
          })),
    tl: {
      hd: Belt_Option.mapWithDefault(contract, /* [] */0, (function (param) {
              return {
                      hd: {
                        label: "Numéro de contrat",
                        placeholder: "{{ contract.number }}",
                        preview: param.number.toString()
                      },
                      tl: {
                        hd: {
                          label: "Url du contrat",
                          placeholder: "{{ contractUrl }}",
                          preview: param.publicUrl
                        },
                        tl: /* [] */0
                      }
                    };
            })),
      tl: /* [] */0
    }
  };
  var placeholders = {
    hd: placeholders_0,
    tl: placeholders_1
  };
  var placeholders$1 = Belt_List.flatten(placeholders);
  var match$2 = match$1[1].data;
  return React.createElement(React.Fragment, undefined, React.createElement(ModalHeader, {
                  children: React.createElement(ModalTitle, {
                        children: Utils_react.ste(title)
                      })
                }), React.createElement(ModalBody, {
                  children: null
                }, match$2 !== undefined && match$2.sendEmail ? React.createElement("div", {
                        className: "alert alert-success"
                      }, Utils_react.ste("L\'email vient d\'être envoyé.")) : null, React.createElement(ModalSendEmail_Form.make, {
                      wrap: wrap,
                      placeholders: placeholders$1,
                      fields: match[1]
                    }), React.createElement(Form_Renderer.FormErrors.make, {
                      wrap: wrap
                    })), React.createElement(ModalFooter, {
                  children: null
                }, React.createElement("div", {
                      className: "btn btn-default",
                      onClick: (function (param) {
                          return Utils_react.preventCallback(onClose, param);
                        })
                    }, Utils_react.ste("Fermer")), React.createElement(Form_Renderer.SubmitActionButton.make, {
                      wrap: wrap,
                      text: "Envoyer",
                      submittingText: "Envoi en cours...",
                      onClick: onSubmit
                    })));
}

var Inner = {
  make: ModalSendEmail$Inner
};

function ModalSendEmail(Props) {
  var clientId = Props.clientId;
  var invoiceId = Props.invoiceId;
  var contractId = Props.contractId;
  var quoteId = Props.quoteId;
  var show = Props.show;
  var onClose = Props.onClose;
  var title = Props.title;
  var results = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          clientId: Utils.def(clientId, -1),
          invoiceId: Utils.def(invoiceId, -1),
          quoteId: Utils.def(quoteId, -1),
          contractId: Utils.def(contractId, -1)
        }
      ]);
  var match = results.data;
  var error = results.error;
  var tmp;
  if (results.loading) {
    tmp = React.createElement(ModalBody, {
          className: "flex items-center",
          children: React.createElement(Spinner.make, {})
        });
  } else if (error !== undefined) {
    tmp = React.createElement(ModalBody, {
          children: Utils_react.ste(Apollo.getErrorMessage(error))
        });
  } else if (match !== undefined) {
    var tmp$1 = {
      onClose: onClose,
      title: title
    };
    if (match.client !== undefined) {
      tmp$1.client = Caml_option.valFromOption(match.client);
    }
    if (match.invoice !== undefined) {
      tmp$1.invoice = Caml_option.valFromOption(match.invoice);
    }
    if (match.contract !== undefined) {
      tmp$1.contract = Caml_option.valFromOption(match.contract);
    }
    if (match.quote !== undefined) {
      tmp$1.quote = Caml_option.valFromOption(match.quote);
    }
    tmp = React.createElement(ModalSendEmail$Inner, tmp$1);
  } else {
    tmp = React.createElement(ModalBody, {
          children: Utils_react.ste("Erreur inconnue")
        });
  }
  return React.createElement(React_bootstrap.Modal.make, {
              onHide: onClose,
              show: show,
              children: tmp
            });
}

var Modal;

var Fields;

var make = ModalSendEmail;

export {
  Modal ,
  Fields ,
  Query ,
  SendEmailMutation ,
  Inner ,
  make ,
  
}
/* query Not a pure module */
