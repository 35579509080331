// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Validator from "../../validator/validator.bs.js";
import * as BBCodeEdit from "./BBCodeEdit.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Form_Choice from "../../Form/Form_Choice.bs.js";
import * as MediaUpload from "../../MediaUpload/MediaUpload.bs.js";
import * as Utils_react from "../../utils/Utils_react.bs.js";
import * as Form_Renderer from "../../Form/Form_Renderer.bs.js";
import * as Utils_promise from "../../utils/Utils_promise.bs.js";
import * as Utils_unicode from "../../utils/Utils_unicode.bs.js";
import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";
import * as React_bootstrap from "../../vendor/React_bootstrap.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/src/Wrap.bs.js";
import * as Field$ReasonForm from "@maarekj/reason-form/src/Field.bs.js";
import * as Form_BoolCheckbox from "../../Form/Form_BoolCheckbox.bs.js";
import * as ReactUtils from "netosoftcrm-js/lib/reactUtils";

function renderElement(prim, prim$1) {
  ReactUtils.renderElement(prim, prim$1);
  
}

var Value = {};

var imageId = Field$ReasonForm.createField("imageId", (function (v) {
        return v.imageId;
      }), (function (v, values) {
        return {
                imageId: v,
                src: values.src,
                legend: values.legend,
                copyright: values.copyright,
                width: values.width,
                float: values.float,
                clickable: values.clickable
              };
      }));

var src = Field$ReasonForm.createField("src", (function (v) {
        return v.src;
      }), (function (v, values) {
        return {
                imageId: values.imageId,
                src: v,
                legend: values.legend,
                copyright: values.copyright,
                width: values.width,
                float: values.float,
                clickable: values.clickable
              };
      }));

var legend = Field$ReasonForm.createField("legend", (function (v) {
        return v.legend;
      }), (function (v, values) {
        return {
                imageId: values.imageId,
                src: values.src,
                legend: v,
                copyright: values.copyright,
                width: values.width,
                float: values.float,
                clickable: values.clickable
              };
      }));

var copyright = Field$ReasonForm.createField("copyright", (function (v) {
        return v.copyright;
      }), (function (v, values) {
        return {
                imageId: values.imageId,
                src: values.src,
                legend: values.legend,
                copyright: v,
                width: values.width,
                float: values.float,
                clickable: values.clickable
              };
      }));

var width = Field$ReasonForm.createField("width", (function (v) {
        return v.width;
      }), (function (v, values) {
        return {
                imageId: values.imageId,
                src: values.src,
                legend: values.legend,
                copyright: values.copyright,
                width: v,
                float: values.float,
                clickable: values.clickable
              };
      }));

var clickable = Field$ReasonForm.createField("clickable", (function (v) {
        return v.clickable;
      }), (function (v, values) {
        return {
                imageId: values.imageId,
                src: values.src,
                legend: values.legend,
                copyright: values.copyright,
                width: values.width,
                float: values.float,
                clickable: v
              };
      }));

var $$float = Field$ReasonForm.createField("float", (function (v) {
        return v.float;
      }), (function (v, values) {
        return {
                imageId: values.imageId,
                src: values.src,
                legend: values.legend,
                copyright: values.copyright,
                width: values.width,
                float: v,
                clickable: values.clickable
              };
      }));

function onValidate(form) {
  var values = Form$ReasonForm.getValues(form);
  return Validator.optionIsRequired(values.imageId, imageId, undefined, undefined, form);
}

var initialForm = {
  imageId: undefined,
  src: undefined,
  legend: undefined,
  copyright: undefined,
  width: undefined,
  float: undefined,
  clickable: true
};

var initializedForm = Form$ReasonForm.initializeForm(initialForm, undefined, undefined, undefined, undefined, undefined, onValidate, undefined);

var Fields = {
  Value: Value,
  imageId: imageId,
  src: src,
  legend: legend,
  copyright: copyright,
  width: width,
  clickable: clickable,
  $$float: $$float,
  onValidate: onValidate,
  initialForm: initialForm,
  initializedForm: initializedForm
};

function BBCode_ImagePlugin$Button(Props) {
  var bbcode = Props.bbcode;
  var show = Utils_react.useToggleShow(false);
  var wrap = React.useMemo((function () {
          return Wrap$ReasonForm.make(undefined, initializedForm);
        }), []);
  var onSubmit = React.useCallback((function (form) {
          var match = Form$ReasonForm.getValues(form);
          var $$float = match.float;
          var width = match.width;
          var copyright = match.copyright;
          var legend = match.legend;
          var imageId = match.imageId;
          var attrs = imageId !== undefined ? ({
                hd: [
                  "imageId",
                  imageId.toString()
                ],
                tl: /* [] */0
              }) : /* [] */0;
          var attrs$1 = legend !== undefined ? ({
                hd: [
                  "legend",
                  legend
                ],
                tl: attrs
              }) : attrs;
          var attrs$2 = copyright !== undefined ? ({
                hd: [
                  "copyright",
                  copyright
                ],
                tl: attrs$1
              }) : attrs$1;
          var attrs$3 = width !== undefined ? ({
                hd: [
                  "width",
                  width
                ],
                tl: attrs$2
              }) : attrs$2;
          var attrs$4 = $$float !== undefined ? (
              $$float === "right" ? ({
                    hd: [
                      "float",
                      "right"
                    ],
                    tl: attrs$3
                  }) : ({
                    hd: [
                      "float",
                      "left"
                    ],
                    tl: attrs$3
                  })
            ) : attrs$3;
          var attrs$5 = match.clickable ? attrs$4 : ({
                hd: [
                  "clickable",
                  "true"
                ],
                tl: attrs$4
              });
          BBCodeEdit.insertTagAutoclosed(bbcode, "photo", attrs$5);
          Wrap$ReasonForm.dispatch(wrap, (function (param) {
                  return initializedForm;
                }));
          Curry._1(show.hide, undefined);
          return Utils_promise.resolveOk(undefined);
        }), [
        wrap,
        show
      ]);
  return React.createElement("div", {
              className: "btn-group btn-group-bbcode"
            }, React.createElement("button", {
                  className: "btn btn-default btn-bbcode",
                  onClick: show.showWithPrevent
                }, React.createElement("i", {
                      className: "fa fa-photo"
                    }), React.createElement("span", undefined, Utils_react.ste(Utils_unicode.nbsp + "Image"))), React.createElement(React_bootstrap.Modal.make, {
                  onHide: show.hide,
                  show: show.value,
                  children: React.createElement(Form_Renderer.Form.make, {
                        wrap: wrap,
                        className: "modal-content",
                        onSubmit: onSubmit,
                        children: null
                      }, React.createElement("div", {
                            className: "modal-header"
                          }, React.createElement("button", {
                                className: "close",
                                onClick: show.hideWithPrevent
                              }, Utils_unicode.timesElement), React.createElement("h4", {
                                className: "modal-title"
                              }, Utils_react.ste("Ajouter une image"))), React.createElement("div", {
                            className: "modal-body"
                          }, React.createElement(Form_Renderer.Row.make, {
                                label: "Image",
                                input: React.createElement(MediaUpload.FormInput.make, {
                                      wrap: wrap,
                                      field: imageId
                                    }),
                                wrap: wrap,
                                field: imageId
                              }), React.createElement(Form_Renderer.Row.make, {
                                label: "Légende",
                                input: React.createElement(Form_Renderer.Input.make, {
                                      wrap: wrap,
                                      toText: (function (v) {
                                          return Belt_Option.getWithDefault(v, "");
                                        }),
                                      fromText: (function (v) {
                                          if (v === "") {
                                            return ;
                                          } else {
                                            return v;
                                          }
                                        }),
                                      field: legend
                                    }),
                                wrap: wrap,
                                field: legend
                              }), React.createElement(Form_Renderer.Row.make, {
                                label: "Copyright",
                                input: React.createElement(Form_Renderer.Input.make, {
                                      wrap: wrap,
                                      toText: (function (v) {
                                          return Belt_Option.getWithDefault(v, "");
                                        }),
                                      fromText: (function (v) {
                                          if (v === "") {
                                            return ;
                                          } else {
                                            return v;
                                          }
                                        }),
                                      field: copyright
                                    }),
                                wrap: wrap,
                                field: copyright
                              }), React.createElement(Form_Renderer.Row.make, {
                                label: "Largeur",
                                input: React.createElement(Form_Renderer.Input.make, {
                                      wrap: wrap,
                                      toText: (function (v) {
                                          return Belt_Option.getWithDefault(v, "");
                                        }),
                                      fromText: (function (v) {
                                          if (v === "") {
                                            return ;
                                          } else {
                                            return v;
                                          }
                                        }),
                                      field: width
                                    }),
                                wrap: wrap,
                                field: width
                              }), React.createElement(Form_Renderer.Row.make, {
                                label: "Image flottante",
                                input: React.createElement(Form_Choice.Radio.make, {
                                      wrap: wrap,
                                      field: $$float,
                                      choices: {
                                        hd: {
                                          value: undefined,
                                          string: "none",
                                          label: Utils_react.ste("Non flottante")
                                        },
                                        tl: {
                                          hd: {
                                            value: "left",
                                            string: "left",
                                            label: Utils_react.ste("Flottante à gauche")
                                          },
                                          tl: {
                                            hd: {
                                              value: "right",
                                              string: "right",
                                              label: Utils_react.ste("Flottante à droite")
                                            },
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }),
                                wrap: wrap,
                                field: $$float
                              }), React.createElement(Form_Renderer.Row.make, {
                                input: React.createElement(Form_BoolCheckbox.make, {
                                      wrap: wrap,
                                      label: "Cliquable ?",
                                      field: clickable
                                    }),
                                wrap: wrap,
                                field: clickable
                              })), React.createElement("div", {
                            className: "modal-footer"
                          }, React.createElement("button", {
                                className: "btn btn-default",
                                onClick: show.hideWithPrevent
                              }, Utils_react.ste("Fermer")), React.createElement(Form_Renderer.SubmitButton.make, {
                                wrap: wrap,
                                text: "Ajouter l\'image",
                                submittingText: "Ajout de l\'image"
                              })))
                }));
}

var Button = {
  make: BBCode_ImagePlugin$Button
};

function make(param) {
  return {
          getName: (function () {
              return "image";
            }),
          initToolbar: (function (bbcode) {
              var group = document.createElement("div");
              BBCodeEdit.toolbar(bbcode).appendChild(group);
              var prim = React.createElement(BBCode_ImagePlugin$Button, {
                    bbcode: bbcode
                  });
              ReactUtils.renderElement(group, prim);
              
            })
        };
}

var Dom;

var $$Element;

var Modal;

var ste = Utils_react.ste;

var preventCallback = Utils_react.preventCallback;

var nbsp = Utils_unicode.nbsp;

var timesElement = Utils_unicode.timesElement;

var isNone = Belt_Option.isNone;

export {
  Dom ,
  $$Element ,
  Modal ,
  ste ,
  renderElement ,
  preventCallback ,
  nbsp ,
  timesElement ,
  isNone ,
  Fields ,
  Button ,
  make ,
  
}
/* imageId Not a pure module */
