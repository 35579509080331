// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "@glennsl/bs-json/src/Json.bs.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Utils from "./utils/Utils.bs.js";
import * as React from "react";
import Jquery from "jquery";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as MediaUpload from "./MediaUpload/MediaUpload.bs.js";
import * as Utils_common from "./utils/Utils_common.bs.js";
import * as Utils_option from "./utils/Utils_option.bs.js";
import * as MarkdownInput from "./component/MarkdownInput/MarkdownInput.bs.js";
import * as MediaUploadAction from "./MediaUpload/MediaUploadAction.bs.js";
import * as InvoiceStyle_Widget from "./component/InvoiceStyle/InvoiceStyle_Widget.bs.js";
import * as ContractInvoiceTable from "./component/ContractInvoiceTable/ContractInvoiceTable.bs.js";
import * as ModalSendEmailButton from "./component/ModalSendEmail/ModalSendEmailButton.bs.js";
import * as Webapi__Dom__Element from "bs-webapi/src/Webapi/Dom/Webapi__Dom__Element.bs.js";
import * as ReactUtils from "netosoftcrm-js/lib/reactUtils";

function dataReact(element, name, propsDecode, render) {
  return $$Array.iter((function (element) {
                var props = Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.nullable_to_opt(element.getAttribute("data-props")), Json.parse), (function (json) {
                        return Utils_option.fromResult(Curry._1(propsDecode, json));
                      }));
                if (props === undefined) {
                  return ;
                }
                var prim = Curry._2(render, element, Caml_option.valFromOption(props));
                ReactUtils.renderElement(element, prim);
                
              }), Utils_common.arrayWithoutNone($$Array.map(Webapi__Dom__Element.ofNode, Array.prototype.slice.call(element.querySelectorAll("[data-react=" + (name + "]"))))));
}

function props_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var inputId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "inputId"), null));
  if (inputId.TAG === /* Ok */0) {
    var mediaTypes = Decco.listFromJson(MediaUploadAction.mediaType_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "mediaTypes"), null));
    if (mediaTypes.TAG === /* Ok */0) {
      var defaultValue = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "defaultValue"), null));
      if (defaultValue.TAG === /* Ok */0) {
        return {
                TAG: 0,
                _0: {
                  inputId: inputId._0,
                  mediaTypes: mediaTypes._0,
                  defaultValue: defaultValue._0
                },
                [Symbol.for("name")]: "Ok"
              };
      }
      var e = defaultValue._0;
      return {
              TAG: 1,
              _0: {
                path: ".defaultValue" + e.path,
                message: e.message,
                value: e.value
              },
              [Symbol.for("name")]: "Error"
            };
    }
    var e$1 = mediaTypes._0;
    return {
            TAG: 1,
            _0: {
              path: ".mediaTypes" + e$1.path,
              message: e$1.message,
              value: e$1.value
            },
            [Symbol.for("name")]: "Error"
          };
  }
  var e$2 = inputId._0;
  return {
          TAG: 1,
          _0: {
            path: ".inputId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          },
          [Symbol.for("name")]: "Error"
        };
}

function main(element) {
  return dataReact(element, "MediaUpload", props_decode, (function (_element, param) {
                var inputId = param.inputId;
                return React.createElement(MediaUpload.Uncontrolled.make, {
                            mediaTypes: param.mediaTypes,
                            defaultMediaId: param.defaultValue,
                            onChange: (function (value) {
                                var value$1 = Utils.def(Belt_Option.map(value, (function (prim) {
                                            return prim.toString();
                                          })), "");
                                Jquery("#" + inputId).val(value$1);
                                
                              })
                          });
              }));
}

var MediaUpload$1 = {
  props_decode: props_decode,
  main: main
};

function props_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var inputId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "inputId"), null));
  if (inputId.TAG === /* Ok */0) {
    var defaultValue = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "defaultValue"), null));
    if (defaultValue.TAG === /* Ok */0) {
      var placeholders = Decco.listFromJson(MarkdownInput.placeholder_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "placeholders"), null));
      if (placeholders.TAG === /* Ok */0) {
        var rows = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "rows"), null));
        if (rows.TAG === /* Ok */0) {
          var withToolbar = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "withToolbar"), null));
          if (withToolbar.TAG === /* Ok */0) {
            var withPreview = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "withPreview"), null));
            if (withPreview.TAG === /* Ok */0) {
              return {
                      TAG: 0,
                      _0: {
                        inputId: inputId._0,
                        defaultValue: defaultValue._0,
                        placeholders: placeholders._0,
                        rows: rows._0,
                        withToolbar: withToolbar._0,
                        withPreview: withPreview._0
                      },
                      [Symbol.for("name")]: "Ok"
                    };
            }
            var e = withPreview._0;
            return {
                    TAG: 1,
                    _0: {
                      path: ".withPreview" + e.path,
                      message: e.message,
                      value: e.value
                    },
                    [Symbol.for("name")]: "Error"
                  };
          }
          var e$1 = withToolbar._0;
          return {
                  TAG: 1,
                  _0: {
                    path: ".withToolbar" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  },
                  [Symbol.for("name")]: "Error"
                };
        }
        var e$2 = rows._0;
        return {
                TAG: 1,
                _0: {
                  path: ".rows" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                },
                [Symbol.for("name")]: "Error"
              };
      }
      var e$3 = placeholders._0;
      return {
              TAG: 1,
              _0: {
                path: ".placeholders" + e$3.path,
                message: e$3.message,
                value: e$3.value
              },
              [Symbol.for("name")]: "Error"
            };
    }
    var e$4 = defaultValue._0;
    return {
            TAG: 1,
            _0: {
              path: ".defaultValue" + e$4.path,
              message: e$4.message,
              value: e$4.value
            },
            [Symbol.for("name")]: "Error"
          };
  }
  var e$5 = inputId._0;
  return {
          TAG: 1,
          _0: {
            path: ".inputId" + e$5.path,
            message: e$5.message,
            value: e$5.value
          },
          [Symbol.for("name")]: "Error"
        };
}

function main$1(element) {
  return dataReact(element, "MarkdownInput", props_decode$1, (function (_element, param) {
                var inputId = param.inputId;
                var tmp = {
                  defaultValue: Utils.def(param.defaultValue, ""),
                  onChange: (function (value) {
                      Jquery("#" + inputId).val(value);
                      
                    }),
                  placeholders: param.placeholders
                };
                if (param.withToolbar !== undefined) {
                  tmp.withToolbar = Caml_option.valFromOption(param.withToolbar);
                }
                if (param.rows !== undefined) {
                  tmp.rows = Caml_option.valFromOption(param.rows);
                }
                if (param.withPreview !== undefined) {
                  tmp.withPreview = Caml_option.valFromOption(param.withPreview);
                }
                return React.createElement(MarkdownInput.Uncontrolled.make, tmp);
              }));
}

var MarkdownInput$1 = {
  props_decode: props_decode$1,
  main: main$1
};

function props_decode$2(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var proId = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "proId"), null));
  if (proId.TAG === /* Ok */0) {
    return {
            TAG: 0,
            _0: {
              proId: proId._0
            },
            [Symbol.for("name")]: "Ok"
          };
  }
  var e = proId._0;
  return {
          TAG: 1,
          _0: {
            path: ".proId" + e.path,
            message: e.message,
            value: e.value
          },
          [Symbol.for("name")]: "Error"
        };
}

function main$2(element) {
  return dataReact(element, "ContractInvoiceTable", props_decode$2, (function (_element, param) {
                return React.createElement(ContractInvoiceTable.make, {
                            proId: param.proId
                          });
              }));
}

var ContractInvoiceTable$1 = {
  props_decode: props_decode$2,
  main: main$2
};

function props_decode$3(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var initialValue = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "initialValue"), null));
  if (initialValue.TAG === /* Ok */0) {
    var inputId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "inputId"), null));
    if (inputId.TAG === /* Ok */0) {
      return {
              TAG: 0,
              _0: {
                initialValue: initialValue._0,
                inputId: inputId._0
              },
              [Symbol.for("name")]: "Ok"
            };
    }
    var e = inputId._0;
    return {
            TAG: 1,
            _0: {
              path: ".inputId" + e.path,
              message: e.message,
              value: e.value
            },
            [Symbol.for("name")]: "Error"
          };
  }
  var e$1 = initialValue._0;
  return {
          TAG: 1,
          _0: {
            path: ".initialValue" + e$1.path,
            message: e$1.message,
            value: e$1.value
          },
          [Symbol.for("name")]: "Error"
        };
}

function main$3(element) {
  return dataReact(element, "InvoiceStyle", props_decode$3, (function (_element, param) {
                return React.createElement(InvoiceStyle_Widget.make, {
                            initialValue: param.initialValue,
                            inputId: param.inputId
                          });
              }));
}

var InvoiceStyle = {
  props_decode: props_decode$3,
  main: main$3
};

function props_decode$4(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var className = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "className"), null));
  if (className.TAG === /* Ok */0) {
    var clientId = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "clientId"), null));
    if (clientId.TAG === /* Ok */0) {
      var quoteId = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "quoteId"), null));
      if (quoteId.TAG === /* Ok */0) {
        var invoiceId = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "invoiceId"), null));
        if (invoiceId.TAG === /* Ok */0) {
          var contractId = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "contractId"), null));
          if (contractId.TAG === /* Ok */0) {
            var icon = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "icon"), null));
            if (icon.TAG === /* Ok */0) {
              var label = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "label"), null));
              if (label.TAG === /* Ok */0) {
                var title = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "title"), null));
                if (title.TAG === /* Ok */0) {
                  return {
                          TAG: 0,
                          _0: {
                            className: className._0,
                            clientId: clientId._0,
                            quoteId: quoteId._0,
                            invoiceId: invoiceId._0,
                            contractId: contractId._0,
                            icon: icon._0,
                            label: label._0,
                            title: title._0
                          },
                          [Symbol.for("name")]: "Ok"
                        };
                }
                var e = title._0;
                return {
                        TAG: 1,
                        _0: {
                          path: ".title" + e.path,
                          message: e.message,
                          value: e.value
                        },
                        [Symbol.for("name")]: "Error"
                      };
              }
              var e$1 = label._0;
              return {
                      TAG: 1,
                      _0: {
                        path: ".label" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      },
                      [Symbol.for("name")]: "Error"
                    };
            }
            var e$2 = icon._0;
            return {
                    TAG: 1,
                    _0: {
                      path: ".icon" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    },
                    [Symbol.for("name")]: "Error"
                  };
          }
          var e$3 = contractId._0;
          return {
                  TAG: 1,
                  _0: {
                    path: ".contractId" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  },
                  [Symbol.for("name")]: "Error"
                };
        }
        var e$4 = invoiceId._0;
        return {
                TAG: 1,
                _0: {
                  path: ".invoiceId" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                },
                [Symbol.for("name")]: "Error"
              };
      }
      var e$5 = quoteId._0;
      return {
              TAG: 1,
              _0: {
                path: ".quoteId" + e$5.path,
                message: e$5.message,
                value: e$5.value
              },
              [Symbol.for("name")]: "Error"
            };
    }
    var e$6 = clientId._0;
    return {
            TAG: 1,
            _0: {
              path: ".clientId" + e$6.path,
              message: e$6.message,
              value: e$6.value
            },
            [Symbol.for("name")]: "Error"
          };
  }
  var e$7 = className._0;
  return {
          TAG: 1,
          _0: {
            path: ".className" + e$7.path,
            message: e$7.message,
            value: e$7.value
          },
          [Symbol.for("name")]: "Error"
        };
}

function main$4(element) {
  return dataReact(element, "ModalSendEmailButton", props_decode$4, (function (_element, param) {
                var tmp = {
                  className: param.className,
                  label: param.label,
                  title: param.title
                };
                if (param.clientId !== undefined) {
                  tmp.clientId = Caml_option.valFromOption(param.clientId);
                }
                if (param.quoteId !== undefined) {
                  tmp.quoteId = Caml_option.valFromOption(param.quoteId);
                }
                if (param.invoiceId !== undefined) {
                  tmp.invoiceId = Caml_option.valFromOption(param.invoiceId);
                }
                if (param.contractId !== undefined) {
                  tmp.contractId = Caml_option.valFromOption(param.contractId);
                }
                if (param.icon !== undefined) {
                  tmp.icon = Caml_option.valFromOption(param.icon);
                }
                return React.createElement(ModalSendEmailButton.make, tmp);
              }));
}

var ModalSendEmailButton$1 = {
  props_decode: props_decode$4,
  main: main$4
};

function main$5(dom) {
  main(dom);
  main$1(dom);
  main$2(dom);
  main$3(dom);
  return main$4(dom);
}

var Dom;

var $$Element;

var PromiseLet;

export {
  Dom ,
  $$Element ,
  PromiseLet ,
  MediaUpload$1 as MediaUpload,
  MarkdownInput$1 as MarkdownInput,
  ContractInvoiceTable$1 as ContractInvoiceTable,
  InvoiceStyle ,
  ModalSendEmailButton$1 as ModalSendEmailButton,
  main$5 as main,
  
}
/* react Not a pure module */
