// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import Modal from "react-bootstrap/lib/Modal";

function backdrop_to_js(backdrop) {
  if (backdrop === "without") {
    return false;
  } else if (backdrop === "static") {
    return "static";
  } else {
    return true;
  }
}

var ModalJs = {};

function React_bootstrap$Modal(Props) {
  var animationOpt = Props.animation;
  var bsClassOpt = Props.bsClass;
  var bsSizeOpt = Props.bsSize;
  var classNameOpt = Props.className;
  var autoFocusOpt = Props.autoFocus;
  var backdropOpt = Props.backdrop;
  var backdropClassName = Props.backdropClassName;
  var backdropStyleOpt = Props.backdropStyle;
  var dialogClassName = Props.dialogClassName;
  var keyboardOpt = Props.keyboard;
  var enforceFocusOpt = Props.enforceFocus;
  var onBackdropClick = Props.onBackdropClick;
  var onEnter = Props.onEnter;
  var onEntered = Props.onEntered;
  var onEntering = Props.onEntering;
  var onEscapeKeyDown = Props.onEscapeKeyDown;
  var onExit = Props.onExit;
  var onExited = Props.onExited;
  var onExiting = Props.onExiting;
  var onHide = Props.onHide;
  var onShow = Props.onShow;
  var restoreFocus = Props.restoreFocus;
  var showOpt = Props.show;
  var childrenOpt = Props.children;
  var animation = animationOpt !== undefined ? animationOpt : true;
  var bsClass = bsClassOpt !== undefined ? bsClassOpt : "modal";
  var bsSize = bsSizeOpt !== undefined ? bsSizeOpt : "lg";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : true;
  var backdrop = backdropOpt !== undefined ? backdropOpt : "with_";
  var backdropStyle = backdropStyleOpt !== undefined ? Caml_option.valFromOption(backdropStyleOpt) : ({});
  var keyboard = keyboardOpt !== undefined ? keyboardOpt : true;
  var enforceFocus = enforceFocusOpt !== undefined ? enforceFocusOpt : true;
  var show = showOpt !== undefined ? showOpt : true;
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var tmp = {
    animation: animation,
    bsClass: bsClass,
    bsSize: bsSize,
    className: className,
    autoFocus: autoFocus,
    backdrop: backdrop_to_js(backdrop),
    backdropStyle: backdropStyle,
    keyboard: keyboard,
    enforceFocus: enforceFocus,
    show: show,
    children: children
  };
  if (backdropClassName !== undefined) {
    tmp.backdropClassName = Caml_option.valFromOption(backdropClassName);
  }
  if (dialogClassName !== undefined) {
    tmp.dialogClassName = Caml_option.valFromOption(dialogClassName);
  }
  if (onBackdropClick !== undefined) {
    tmp.onBackdropClick = Caml_option.valFromOption(onBackdropClick);
  }
  if (onEnter !== undefined) {
    tmp.onEnter = Caml_option.valFromOption(onEnter);
  }
  if (onEntered !== undefined) {
    tmp.onEntered = Caml_option.valFromOption(onEntered);
  }
  if (onEntering !== undefined) {
    tmp.onEntering = Caml_option.valFromOption(onEntering);
  }
  if (onEscapeKeyDown !== undefined) {
    tmp.onEscapeKeyDown = Caml_option.valFromOption(onEscapeKeyDown);
  }
  if (onExit !== undefined) {
    tmp.onExit = Caml_option.valFromOption(onExit);
  }
  if (onExited !== undefined) {
    tmp.onExited = Caml_option.valFromOption(onExited);
  }
  if (onExiting !== undefined) {
    tmp.onExiting = Caml_option.valFromOption(onExiting);
  }
  if (onHide !== undefined) {
    tmp.onHide = Caml_option.valFromOption(onHide);
  }
  if (onShow !== undefined) {
    tmp.onShow = Caml_option.valFromOption(onShow);
  }
  if (restoreFocus !== undefined) {
    tmp.restoreFocus = Caml_option.valFromOption(restoreFocus);
  }
  return React.createElement(Modal, tmp);
}

var Body = {};

var Header = {};

var Title = {};

var Footer = {};

var Modal$1 = {
  backdrop_to_js: backdrop_to_js,
  ModalJs: ModalJs,
  make: React_bootstrap$Modal,
  Body: Body,
  Header: Header,
  Title: Title,
  Footer: Footer
};

var Popover = {};

var OverlayTrigger = {};

var FormGroup = {};

var HelpBlock = {};

var Checkbox = {};

export {
  Modal$1 as Modal,
  Popover ,
  OverlayTrigger ,
  FormGroup ,
  HelpBlock ,
  Checkbox ,
  
}
/* react Not a pure module */
