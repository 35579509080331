import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { graphQlUrl } from "../../../build/temp/js/options";
import isArray from "lodash/isArray";
import isPlainObject from "lodash/isPlainObject";
import join from "lodash/join";
import sortBy from "lodash/sortBy";
import entries from "lodash/entries";
import toString from "lodash/toString";

function simpleRedirect(typename, getIdFromArgs) {
    return {
        read(existing = undefined, { canRead, args, toReference }) {
            try {
                const ref = toReference({ __typename: typename, id: getIdFromArgs(args) });
                return canRead(ref) ? ref : existing;
            } catch (e) {
                return undefined;
            }
        },
    };
}

function encodeArgs(args) {
    if (isArray(args)) {
        return (
            "[" +
            join(
                args.map((value) => encodeArgs(value)),
                ",",
            ) +
            "]"
        );
    } else if (isPlainObject(args)) {
        return (
            "{" +
            join(
                sortBy(entries(args), ([key, _]) => key).map(([key, value]) => `${key}:${encodeArgs(value)}`),
                ",",
            ) +
            "}"
        );
    } else {
        return toString(args);
    }
}
const client = new ApolloClient({
    link: new HttpLink({
        uri: graphQlUrl,
        credentials: "include",
    }),
    cache: new InMemoryCache({
        //possibleTypes,
        addTypename: true,
        resultCaching: true,
        typePolicies: {
            Query: {
                fields: {
                    invoice: simpleRedirect("Invoice", (args) => args.id),
                    strictInvoice: simpleRedirect("Invoice", (args) => args.id),
                    client: simpleRedirect("Client", (args) => args.id),
                    strictClient: simpleRedirect("Client", (args) => args.id),
                    contract: simpleRedirect("Contract", (args) => args.id),
                    strictContract: simpleRedirect("Contract", (args) => args.id),
                    quote: simpleRedirect("Quote", (args) => args.id),
                    strictQuote: simpleRedirect("Quote", (args) => args.id),
                    contractInvoice: simpleRedirect("ContractInvoice", (args) => args.id),
                    strictContractInvoice: simpleRedirect("ContractInvoice", (args) => args.id),
                },
            },
        },
    }),
});

export default client;
