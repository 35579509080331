// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "../vendor/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as DeratType from "../DeratType.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment ImageFragment on Image   {
    __typename
    id
    name
    width
    height
    referenceUrl
    filteredUrl(filter: $filter)
  }
`);

function parse(value) {
  return {
          __typename: value.__typename,
          id: value.id,
          name: value.name,
          width: value.width,
          height: value.height,
          referenceUrl: value.referenceUrl,
          filteredUrl: value.filteredUrl
        };
}

function serialize(value) {
  var value$1 = value.filteredUrl;
  var value$2 = value.referenceUrl;
  var value$3 = value.height;
  var value$4 = value.width;
  var value$5 = value.name;
  var value$6 = value.id;
  var value$7 = value.__typename;
  return {
          __typename: value$7,
          id: value$6,
          name: value$5,
          width: value$4,
          height: value$3,
          referenceUrl: value$2,
          filteredUrl: value$1
        };
}

function verifyArgsAndParse(_filter, _ImageFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var ImageFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = ((frag_0) => require("@apollo/client").gql`
  query Image($id: Int!, $filter: ImageFilterEnum!)  {
    image: strictImage(id: $id)  {
      ...ImageFragment
    }
  }
  ${frag_0}
`)(query);

function parse$1(value) {
  return {
          image: parse(value.image)
        };
}

function serialize$1(value) {
  var value$1 = value.image;
  var image = serialize(value$1);
  return {
          image: image
        };
}

function serializeVariables(inp) {
  var a = inp.filter;
  return {
          id: inp.id,
          filter: a === "inset100" ? "inset100" : (
              a === "inset500" ? "inset500" : (
                  a === "outbound1000" ? "outbound1000" : (
                      a === "reference" ? "reference" : (
                          a === "heighten100" ? "heighten100" : (
                              a === "heighten500" ? "heighten500" : (
                                  a === "widen100" ? "widen100" : (
                                      a === "widen500" ? "widen500" : (
                                          a === "inset1000" ? "inset1000" : (
                                              a === "widen1000" ? "widen1000" : (
                                                  a === "outbound100" ? "outbound100" : (
                                                      a === "outbound500" ? "outbound500" : (
                                                          a === "square100" ? "square100" : (
                                                              a === "square500" ? "square500" : (
                                                                  a === "heighten1000" ? "heighten1000" : "square1000"
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        };
}

function makeVariables(id, filter, param) {
  return {
          id: id,
          filter: filter
        };
}

var ImageQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables
    });

var use = include.use;

var ImageQuery_refetchQueryDescription = include.refetchQueryDescription;

var ImageQuery_useLazy = include.useLazy;

var ImageQuery_useLazyWithVariables = include.useLazyWithVariables;

var ImageQuery = {
  ImageQuery_inner: ImageQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: ImageQuery_refetchQueryDescription,
  use: use,
  useLazy: ImageQuery_useLazy,
  useLazyWithVariables: ImageQuery_useLazyWithVariables
};

var filterFromString = DeratType.imageFilterFromJson;

function imageFromId(id, filter) {
  return {
          TAG: 1,
          _0: id,
          _1: filter,
          [Symbol.for("name")]: "Id"
        };
}

function Image$WithUrl(Props) {
  var id = Props.id;
  var style = Props.style;
  var url = Props.url;
  var className = Props.className;
  var onClick = Props.onClick;
  var tmp = {
    src: url
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("img", tmp);
}

var WithUrl = {
  make: Image$WithUrl
};

function Image$WithId(Props) {
  var id = Props.id;
  var style = Props.style;
  var imageId = Props.imageId;
  var filter = Props.filter;
  var className = Props.className;
  var getClassName = Props.getClassName;
  var onClick = Props.onClick;
  var renderLoadingOpt = Props.renderLoading;
  var renderLoading = renderLoadingOpt !== undefined ? renderLoadingOpt : (function (param) {
        var tmp = {};
        if (className !== undefined) {
          tmp.className = Caml_option.valFromOption(className);
        }
        if (style !== undefined) {
          tmp.style = Caml_option.valFromOption(style);
        }
        return React.createElement("div", tmp);
      });
  var apolloData = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          id: imageId,
          filter: filter
        }
      ]);
  var match = apolloData.data;
  if (apolloData.loading) {
    return Curry._1(renderLoading, undefined);
  }
  if (apolloData.error !== undefined) {
    return Curry._1(renderLoading, undefined);
  }
  if (match === undefined) {
    return Curry._1(renderLoading, undefined);
  }
  var match$1 = match.image;
  var height = match$1.height;
  var width = match$1.width;
  var tmp = {
    className: Cn.append(Cn.take(className), Cn.take(Belt_Option.mapU(getClassName, (function (getClassName) {
                    return Curry._1(getClassName, {
                                width: width,
                                height: height,
                                orientation: width > height ? "horizontal" : "vertical",
                                ratio: width / height
                              });
                  })))),
    height: height.toString(),
    src: match$1.filteredUrl,
    width: width.toString()
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("img", tmp);
}

var WithId = {
  make: Image$WithId
};

function $$Image(Props) {
  var id = Props.id;
  var style = Props.style;
  var image = Props.image;
  var className = Props.className;
  var getClassName = Props.getClassName;
  var onClick = Props.onClick;
  var renderLoading = Props.renderLoading;
  if (image.TAG === /* Url */0) {
    var tmp = {
      url: image._0
    };
    if (id !== undefined) {
      tmp.id = Caml_option.valFromOption(id);
    }
    if (style !== undefined) {
      tmp.style = Caml_option.valFromOption(style);
    }
    if (className !== undefined) {
      tmp.className = Caml_option.valFromOption(className);
    }
    if (onClick !== undefined) {
      tmp.onClick = Caml_option.valFromOption(onClick);
    }
    return React.createElement(Image$WithUrl, tmp);
  }
  var tmp$1 = {
    imageId: image._0,
    filter: image._1
  };
  if (id !== undefined) {
    tmp$1.id = Caml_option.valFromOption(id);
  }
  if (style !== undefined) {
    tmp$1.style = Caml_option.valFromOption(style);
  }
  if (className !== undefined) {
    tmp$1.className = Caml_option.valFromOption(className);
  }
  if (getClassName !== undefined) {
    tmp$1.getClassName = Caml_option.valFromOption(getClassName);
  }
  if (onClick !== undefined) {
    tmp$1.onClick = Caml_option.valFromOption(onClick);
  }
  if (renderLoading !== undefined) {
    tmp$1.renderLoading = Caml_option.valFromOption(renderLoading);
  }
  return React.createElement(Image$WithId, tmp$1);
}

var make = $$Image;

var $$default = $$Image;

export {
  ImageFragment ,
  ImageQuery ,
  filterFromString ,
  imageFromId ,
  WithUrl ,
  WithId ,
  make ,
  $$default ,
  $$default as default,
  
}
/* query Not a pure module */
