// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "@glennsl/bs-json/src/Json.bs.js";
import * as Utils from "../../utils/Utils.bs.js";
import * as React from "react";
import Jquery from "jquery";
import * as Utils_react from "../../utils/Utils_react.bs.js";
import * as Hook$ReasonForm from "@maarekj/reason-form/src/Hook.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/src/Wrap.bs.js";
import * as Helper$ReasonForm from "@maarekj/reason-form/src/Helper.bs.js";
import * as InvoiceStyle_Fields from "./InvoiceStyle_Fields.bs.js";
import * as InvoiceStyle_WinroFields from "./Winro/InvoiceStyle_WinroFields.bs.js";
import * as InvoiceStyle_WinroWidget from "./Winro/InvoiceStyle_WinroWidget.bs.js";
import * as InvoiceStyle_ColdraFields from "./Coldra/InvoiceStyle_ColdraFields.bs.js";
import * as InvoiceStyle_ColdraWidget from "./Coldra/InvoiceStyle_ColdraWidget.bs.js";

function InvoiceStyle_Widget(Props) {
  var initialValue = Props.initialValue;
  var inputId = Props.inputId;
  var initialValue$1;
  try {
    var json = Json.parseOrRaise(Utils.def(initialValue, ""));
    initialValue$1 = InvoiceStyle_Fields.Value.fromJson(json);
  }
  catch (exn){
    initialValue$1 = undefined;
  }
  var match = InvoiceStyle_Fields.useForm(Utils.def(initialValue$1, InvoiceStyle_Fields.Value.empty));
  var field = match[1];
  var wrap = match[0];
  var formValues = Hook$ReasonForm.useFormValues(wrap, Utils.id);
  React.useEffect((function () {
          var json = Json.stringify(InvoiceStyle_Fields.Value.toJson(formValues));
          Jquery(inputId).val(json);
          
        }), [
        formValues,
        inputId
      ]);
  var value = Hook$ReasonForm.useValue(wrap, field);
  var tmp;
  tmp = value.TAG === /* Coldra */0 ? React.createElement(InvoiceStyle_ColdraWidget.make, {
          wrap: wrap,
          field: field,
          transformers: [
            (function (value) {
                if (value.TAG === /* Coldra */0) {
                  return value._0;
                } else {
                  return InvoiceStyle_ColdraFields.Value.empty;
                }
              }),
            (function (v) {
                return {
                        TAG: 0,
                        _0: v,
                        [Symbol.for("name")]: "Coldra"
                      };
              })
          ]
        }) : React.createElement(InvoiceStyle_WinroWidget.make, {
          wrap: wrap,
          field: field,
          transformers: [
            (function (value) {
                if (value.TAG === /* Coldra */0) {
                  return InvoiceStyle_WinroFields.Value.empty;
                } else {
                  return value._0;
                }
              }),
            (function (v) {
                return {
                        TAG: 1,
                        _0: v,
                        [Symbol.for("name")]: "Winro"
                      };
              })
          ]
        });
  return React.createElement("div", undefined, React.createElement("div", {
                  className: ""
                }, React.createElement("div", {
                      className: "form-group"
                    }, React.createElement("label", undefined, Utils_react.ste("Style de la facture")), React.createElement("select", {
                          className: "not-select2 form-control",
                          value: InvoiceStyle_Fields.Value.getName(formValues),
                          onChange: (function ($$event) {
                              var value = InvoiceStyle_Fields.Value.emptyForStyle(Utils_react.getValue($$event));
                              return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                                            return Helper$ReasonForm.changeFieldValue(field, value, param);
                                          }));
                            })
                        }, React.createElement("option", {
                              value: "coldra"
                            }, Utils_react.ste("coldra")), React.createElement("option", {
                              value: "winro"
                            }, Utils_react.ste("winro")))), tmp));
}

var Fields;

var make = InvoiceStyle_Widget;

export {
  Fields ,
  make ,
  
}
/* react Not a pure module */
