// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Apollo from "../../Apollo.bs.js";
import * as Utils_date from "../../utils/Utils_date.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Utils_react from "../../utils/Utils_react.bs.js";
import * as Form_Renderer from "../../Form/Form_Renderer.bs.js";
import * as MarkdownInput from "../MarkdownInput/MarkdownInput.bs.js";
import * as Utils_unicode from "../../utils/Utils_unicode.bs.js";
import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";
import * as React_bootstrap from "../../vendor/React_bootstrap.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/src/Wrap.bs.js";
import * as Field$ReasonForm from "@maarekj/reason-form/src/Field.bs.js";
import * as GraphQLExtending from "../../Graphql/GraphQLExtending.bs.js";
import * as ContractInvoiceTable_Fragment from "./ContractInvoiceTable_Fragment.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = ((frag_0) => require("@apollo/client").gql`
  mutation ChangeEmailMutation($data: ChangeEmailContractInvoiceInput!)  {
    changeEmailContractInvoice(data: $data)  {
      ...ContractInvoiceFragment
    }
  }
  ${frag_0}
`)(ContractInvoiceTable_Fragment.ContractInvoiceFragment.query);

function parse(value) {
  var value$1 = value.changeEmailContractInvoice;
  return {
          changeEmailContractInvoice: !(value$1 == null) ? ContractInvoiceTable_Fragment.ContractInvoiceFragment.verifyArgsAndParse("ContractInvoiceFragment", value$1) : undefined
        };
}

function serialize(value) {
  var value$1 = value.changeEmailContractInvoice;
  var changeEmailContractInvoice = value$1 !== undefined ? ContractInvoiceTable_Fragment.ContractInvoiceFragment.serialize(value$1) : null;
  return {
          changeEmailContractInvoice: changeEmailContractInvoice
        };
}

function serializeInputObjectChangeEmailContractInvoiceInput(inp) {
  var a = inp.emailSubjectPrototype;
  var a$1 = inp.emailPrototype;
  return {
          contractInvoice: inp.contractInvoice,
          emailSubjectPrototype: a !== undefined ? a : undefined,
          emailPrototype: a$1 !== undefined ? a$1 : undefined
        };
}

function serializeVariables(inp) {
  return {
          data: serializeInputObjectChangeEmailContractInvoiceInput(inp.data)
        };
}

function makeVariables(data, param) {
  return {
          data: data
        };
}

function makeInputObjectChangeEmailContractInvoiceInput(contractInvoice, emailSubjectPrototype, emailPrototype, param) {
  return {
          contractInvoice: contractInvoice,
          emailSubjectPrototype: emailSubjectPrototype,
          emailPrototype: emailPrototype
        };
}

var ChangeEmailMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectChangeEmailContractInvoiceInput: serializeInputObjectChangeEmailContractInvoiceInput,
  makeVariables: makeVariables,
  makeInputObjectChangeEmailContractInvoiceInput: makeInputObjectChangeEmailContractInvoiceInput
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var ChangeEmailMutation_useWithVariables = include.useWithVariables;

var ChangeEmailMutation = {
  ChangeEmailMutation_inner: ChangeEmailMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectChangeEmailContractInvoiceInput: serializeInputObjectChangeEmailContractInvoiceInput,
  makeVariables: makeVariables,
  makeInputObjectChangeEmailContractInvoiceInput: makeInputObjectChangeEmailContractInvoiceInput,
  use: use,
  useWithVariables: ChangeEmailMutation_useWithVariables
};

function make(subject, body, param) {
  return {
          subject: subject,
          body: body
        };
}

var Value = {
  make: make
};

var subject = Field$ReasonForm.createField("subject", (function (v) {
        return v.subject;
      }), (function (v, values) {
        return {
                subject: v,
                body: values.body
              };
      }));

var body = Field$ReasonForm.createField("body", (function (v) {
        return v.body;
      }), (function (v, values) {
        return {
                subject: values.subject,
                body: v
              };
      }));

function onValidate(form) {
  Form$ReasonForm.getValues(form);
  return form;
}

function useForm(initialValues) {
  return React.useState(function () {
                var form = Form$ReasonForm.initializeForm(initialValues, undefined, undefined, undefined, undefined, undefined, onValidate, undefined);
                return Wrap$ReasonForm.make(undefined, form);
              })[0];
}

var Fields = {
  Value: Value,
  subject: subject,
  body: body,
  onValidate: onValidate,
  useForm: useForm
};

function ContractInvoiceTable_ModalEmail(Props) {
  var show = Props.show;
  var onClose = Props.onClose;
  var contractInvoice = Props.contractInvoice;
  var wrap = useForm({
        subject: contractInvoice.emailSubjectPrototype,
        body: contractInvoice.emailPrototype
      });
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  var placeholders_0 = {
    label: "Numéro de contrat",
    placeholder: "{{ contract.number }}",
    preview: contractInvoice.contract.number.toString()
  };
  var placeholders_1 = {
    hd: {
      label: "Numéro de facture",
      placeholder: "{{ invoice.number }}",
      preview: Belt_Option.map(contractInvoice.invoice, (function (i) {
              return i.number.toString();
            }))
    },
    tl: {
      hd: {
        label: "Mois",
        placeholder: "{{ month }}",
        preview: Utils_date.format(contractInvoice.effectiveDate, "fr", "LLLL")
      },
      tl: {
        hd: {
          label: "Année",
          placeholder: "{{ year }}",
          preview: Utils_date.format(contractInvoice.effectiveDate, "fr", "Y")
        },
        tl: {
          hd: {
            label: "Url de la facture",
            placeholder: "{{ invoiceUrl }}",
            preview: Belt_Option.map(contractInvoice.invoice, (function (i) {
                    return i.publicUrl;
                  }))
          },
          tl: /* [] */0
        }
      }
    }
  };
  var placeholders = {
    hd: placeholders_0,
    tl: placeholders_1
  };
  return React.createElement(React_bootstrap.Modal.make, {
              onHide: onClose,
              show: show,
              children: React.createElement(Form_Renderer.Form.make, {
                    wrap: wrap,
                    className: "modal-content",
                    onSubmit: (function (form) {
                        var match = Form$ReasonForm.getValues(form);
                        return Apollo.promiseWrapError(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                        data: {
                                          contractInvoice: GraphQLExtending.ContractInvoiceId.serialize(contractInvoice.id),
                                          emailSubjectPrototype: match.subject,
                                          emailPrototype: match.body
                                        }
                                      }));
                      }),
                    onSuccess: (function (_result, _form) {
                        return Curry._1(onClose, undefined);
                      }),
                    children: null
                  }, React.createElement("div", {
                        className: "modal-header"
                      }, React.createElement("button", {
                            className: "close",
                            onClick: (function (param) {
                                return Utils_react.preventCallback(onClose, param);
                              })
                          }, Utils_unicode.timesElement), React.createElement("h4", {
                            className: "modal-title"
                          }, Utils_react.ste("Modifier l\'email"))), React.createElement("div", {
                        className: "modal-body"
                      }, React.createElement(Form_Renderer.Row.make, {
                            label: "Sujet de l\'email",
                            input: React.createElement(MarkdownInput.make, MarkdownInput.makeProps(wrap, undefined, subject, Form_Renderer.optionToText, Form_Renderer.optionFromText, placeholders, false, 1, false, undefined, undefined)),
                            wrap: wrap,
                            field: subject
                          }), React.createElement(Form_Renderer.Row.make, {
                            label: "Contenu de l\'email",
                            input: React.createElement(MarkdownInput.make, MarkdownInput.makeProps(wrap, undefined, body, Form_Renderer.optionToText, Form_Renderer.optionFromText, placeholders, undefined, undefined, undefined, undefined, undefined)),
                            wrap: wrap,
                            field: body
                          }), React.createElement(Form_Renderer.FormErrors.make, {
                            wrap: wrap
                          })), React.createElement("div", {
                        className: "modal-footer"
                      }, React.createElement("button", {
                            className: "btn btn-default",
                            onClick: (function (param) {
                                return Utils_react.preventCallback(onClose, param);
                              })
                          }, Utils_react.ste("Fermer")), React.createElement(Form_Renderer.SubmitButton.make, {
                            wrap: wrap,
                            text: "Modifier",
                            submittingText: "Modification..."
                          })))
            });
}

var ContractInvoiceFragment;

var make$1 = ContractInvoiceTable_ModalEmail;

export {
  ContractInvoiceFragment ,
  ChangeEmailMutation ,
  Fields ,
  make$1 as make,
  
}
/* query Not a pure module */
