// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";

function make(versionOpt, breakpageOpt, descriptionOpt, unitPriceBeforeTaxOpt, quantityOpt, vatOpt, param) {
  var version = versionOpt !== undefined ? versionOpt : 2;
  var breakpage = breakpageOpt !== undefined ? breakpageOpt : false;
  var description = descriptionOpt !== undefined ? descriptionOpt : "";
  var unitPriceBeforeTax = unitPriceBeforeTaxOpt !== undefined ? unitPriceBeforeTaxOpt : "";
  var quantity = quantityOpt !== undefined ? quantityOpt : "1";
  var vat = vatOpt !== undefined ? vatOpt : "10";
  return {
          version: version,
          breakpage: breakpage,
          description: description,
          unitPriceBeforeTax: unitPriceBeforeTax,
          quantity: quantity,
          vat: vat
        };
}

function fromJson(json) {
  return {
          version: Json_decode.field("version", Json_decode.$$int, json),
          breakpage: Json_decode.field("breakpage", Json_decode.bool, json),
          description: Json_decode.field("description", Json_decode.string, json),
          unitPriceBeforeTax: Json_decode.field("unitPriceBeforeTax", Json_decode.string, json),
          quantity: Json_decode.field("quantity", Json_decode.string, json),
          vat: Json_decode.field("vat", Json_decode.string, json)
        };
}

function toJson(v) {
  return Json_encode.object_({
              hd: [
                "version",
                v.version
              ],
              tl: {
                hd: [
                  "breakpage",
                  v.breakpage
                ],
                tl: {
                  hd: [
                    "description",
                    v.description
                  ],
                  tl: {
                    hd: [
                      "unitPriceBeforeTax",
                      v.unitPriceBeforeTax
                    ],
                    tl: {
                      hd: [
                        "quantity",
                        v.quantity
                      ],
                      tl: {
                        hd: [
                          "vat",
                          v.vat
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var Value = {
  make: make,
  fromJson: fromJson,
  toJson: toJson
};

export {
  Value ,
  
}
/* No side effect */
