// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Numeral from "../../vendor/Numeral.bs.js";
import * as Utils_date from "../../utils/Utils_date.bs.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Utils_react from "../../utils/Utils_react.bs.js";
import * as Utils_unicode from "../../utils/Utils_unicode.bs.js";
import * as GraphQLExtending from "../../Graphql/GraphQLExtending.bs.js";
import * as ModalShowInvoice from "../ModalShowInvoice/ModalShowInvoice.bs.js";
import Popover from "react-bootstrap/lib/Popover";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";

var Raw = {};

var query = ((frag_0) => require("@apollo/client").gql`
  fragment InvoiceFrag on Invoice   {
    __typename
    id
    number
    totalBeforeTax
    totalAfterTax
    totalVat
    date
    credit
    ...ModalShowInvoiceFragment
  }
  ${frag_0}
`)(ModalShowInvoice.ModalShowInvoiceFragment.query);

function parse(value) {
  return {
          __typename: value["__typename"],
          id: value["id"],
          number: value["number"],
          totalBeforeTax: value["totalBeforeTax"],
          totalAfterTax: value["totalAfterTax"],
          totalVat: value["totalVat"],
          date: GraphQLExtending.$$Date.parse(value["date"]),
          credit: value["credit"],
          modalShowInvoiceFragment: ModalShowInvoice.ModalShowInvoiceFragment.verifyArgsAndParse("ModalShowInvoiceFragment", value)
        };
}

function serialize(value) {
  var value$1 = value.credit;
  var value$2 = value.date;
  var value$3 = GraphQLExtending.$$Date.serialize(value$2);
  var value$4 = value.totalVat;
  var value$5 = value.totalAfterTax;
  var value$6 = value.totalBeforeTax;
  var value$7 = value.number;
  var value$8 = value.id;
  var value$9 = value.__typename;
  return [ModalShowInvoice.ModalShowInvoiceFragment.serialize(value.modalShowInvoiceFragment)].reduce(GraphQL_PPX.deepMerge, {
              __typename: value$9,
              id: value$8,
              number: value$7,
              totalBeforeTax: value$6,
              totalAfterTax: value$5,
              totalVat: value$4,
              date: value$3,
              credit: value$1
            });
}

function verifyArgsAndParse(_InvoiceFrag, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var InvoiceFrag = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = ((frag_0) => require("@apollo/client").gql`
  fragment QuoteFrag on Quote   {
    __typename
    id
    number
    totalBeforeTax
    totalAfterTax
    totalVat
    date
    ...ModalShowQuoteFragment
  }
  ${frag_0}
`)(ModalShowInvoice.ModalShowQuoteFragment.query);

function parse$1(value) {
  return {
          __typename: value["__typename"],
          id: value["id"],
          number: value["number"],
          totalBeforeTax: value["totalBeforeTax"],
          totalAfterTax: value["totalAfterTax"],
          totalVat: value["totalVat"],
          date: GraphQLExtending.$$Date.parse(value["date"]),
          modalShowQuoteFragment: ModalShowInvoice.ModalShowQuoteFragment.verifyArgsAndParse("ModalShowQuoteFragment", value)
        };
}

function serialize$1(value) {
  var value$1 = value.date;
  var value$2 = GraphQLExtending.$$Date.serialize(value$1);
  var value$3 = value.totalVat;
  var value$4 = value.totalAfterTax;
  var value$5 = value.totalBeforeTax;
  var value$6 = value.number;
  var value$7 = value.id;
  var value$8 = value.__typename;
  return [ModalShowInvoice.ModalShowQuoteFragment.serialize(value.modalShowQuoteFragment)].reduce(GraphQL_PPX.deepMerge, {
              __typename: value$8,
              id: value$7,
              number: value$6,
              totalBeforeTax: value$5,
              totalAfterTax: value$4,
              totalVat: value$3,
              date: value$2
            });
}

function verifyArgsAndParse$1(_QuoteFrag, value) {
  return parse$1(value);
}

function verifyName$1(param) {
  
}

var QuoteFrag = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  verifyArgsAndParse: verifyArgsAndParse$1,
  verifyName: verifyName$1
};

function ModalClient_InvoiceTr$PopoverTotal(Props) {
  var totalBeforeTax = Props.totalBeforeTax;
  var totalAfterTax = Props.totalAfterTax;
  var totalVat = Props.totalVat;
  var popover = React.createElement(Popover, {
        id: "",
        children: React.createElement("p", undefined, React.createElement("strong", undefined, Utils_react.ste("Total HT:" + Utils_unicode.nbsp)), React.createElement("span", undefined, Utils_react.ste(Numeral.formatAutoFloat(totalBeforeTax) + (Utils_unicode.nbsp + "€"))), React.createElement("br", undefined), React.createElement("strong", undefined, Utils_react.ste("Total TTC:" + Utils_unicode.nbsp)), React.createElement("span", undefined, Utils_react.ste(Numeral.formatAutoFloat(totalAfterTax) + (Utils_unicode.nbsp + "€"))), React.createElement("br", undefined), React.createElement("br", undefined), React.createElement("strong", undefined, Utils_react.ste("TVA:" + Utils_unicode.nbsp)), React.createElement("span", undefined, Utils_react.ste(Numeral.formatAutoFloat(totalVat) + (Utils_unicode.nbsp + "€"))))
      });
  return React.createElement(OverlayTrigger, {
              trigger: [
                "hover",
                "focus"
              ],
              placement: "top",
              overlay: popover,
              children: React.createElement("a", undefined, Utils_react.ste(Numeral.formatAutoFloat(totalAfterTax) + (Utils_unicode.nbsp + "€")))
            });
}

var PopoverTotal = {
  make: ModalClient_InvoiceTr$PopoverTotal
};

function ModalClient_InvoiceTr$Invoice(Props) {
  var invoice = Props.invoice;
  var modal = Utils_react.useToggleShow(false);
  return React.createElement("tr", undefined, React.createElement("td", undefined, Utils_react.ste(Numeral.formatInt(invoice.number))), React.createElement("td", undefined, Utils_react.ste(invoice.credit ? "A" : "F")), React.createElement("td", undefined, Utils_react.ste(Utils_date.formatLong(invoice.date, "fr", "LL"))), React.createElement("td", undefined, React.createElement(ModalClient_InvoiceTr$PopoverTotal, {
                      totalBeforeTax: invoice.totalBeforeTax,
                      totalAfterTax: invoice.totalAfterTax,
                      totalVat: invoice.totalVat
                    })), React.createElement("td", undefined, React.createElement("button", {
                      className: "btn btn-default btn-xs",
                      onClick: modal.showWithPrevent
                    }, React.createElement("i", {
                          className: "fa fa-info-circle"
                        })), React.createElement(ModalShowInvoice.Invoice.make, {
                      show: modal.value,
                      onClose: modal.hide,
                      id: invoice.id
                    })));
}

var Invoice = {
  make: ModalClient_InvoiceTr$Invoice
};

function ModalClient_InvoiceTr$Quote(Props) {
  var quote = Props.quote;
  var modal = Utils_react.useToggleShow(false);
  return React.createElement("tr", undefined, React.createElement("td", undefined, Utils_react.ste(Numeral.formatInt(quote.number))), React.createElement("td", undefined, Utils_react.ste(Utils_date.formatLong(quote.date, "fr", "LL"))), React.createElement("td", undefined, React.createElement(ModalClient_InvoiceTr$PopoverTotal, {
                      totalBeforeTax: quote.totalBeforeTax,
                      totalAfterTax: quote.totalAfterTax,
                      totalVat: quote.totalVat
                    })), React.createElement("td", undefined, React.createElement("button", {
                      className: "btn btn-default btn-xs",
                      onClick: modal.showWithPrevent
                    }, React.createElement("i", {
                          className: "fa fa-info-circle"
                        })), React.createElement(ModalShowInvoice.Quote.make, {
                      show: modal.value,
                      onClose: modal.hide,
                      id: quote.id
                    })));
}

var Quote = {
  make: ModalClient_InvoiceTr$Quote
};

var ModalShowInvoiceFragment;

var ModalShowQuoteFragment;

export {
  ModalShowInvoiceFragment ,
  ModalShowQuoteFragment ,
  InvoiceFrag ,
  QuoteFrag ,
  PopoverTotal ,
  Invoice ,
  Quote ,
  
}
/* query Not a pure module */
