// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ButtonModal from "../Modal/ButtonModal.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ModalSendEmail from "./ModalSendEmail.bs.js";

function ModalSendEmailButton(Props) {
  var className = Props.className;
  var clientId = Props.clientId;
  var quoteId = Props.quoteId;
  var invoiceId = Props.invoiceId;
  var contractId = Props.contractId;
  var icon = Props.icon;
  var label = Props.label;
  var title = Props.title;
  var tmp = {
    className: className,
    label: label,
    renderModal: (function (modal) {
        var tmp = {
          show: modal.value,
          onClose: modal.hide,
          title: title
        };
        if (clientId !== undefined) {
          tmp.clientId = Caml_option.valFromOption(clientId);
        }
        if (invoiceId !== undefined) {
          tmp.invoiceId = Caml_option.valFromOption(invoiceId);
        }
        if (contractId !== undefined) {
          tmp.contractId = Caml_option.valFromOption(contractId);
        }
        if (quoteId !== undefined) {
          tmp.quoteId = Caml_option.valFromOption(quoteId);
        }
        return React.createElement(ModalSendEmail.make, tmp);
      })
  };
  if (icon !== undefined) {
    tmp.icon = Caml_option.valFromOption(icon);
  }
  return React.createElement(ButtonModal.make, tmp);
}

var make = ModalSendEmailButton;

export {
  make ,
  
}
/* react Not a pure module */
