import React from "react";
import ReactDOM from "react-dom";
import jq from "jquery";
import {ApolloProvider} from "@apollo/client";

import apollo from "./apollo";

export function initializeFromConfig(config) {
    const {component, containers, getProps = ($elt) => $elt.data("props")} = config;

    containers.each((index, elt) => {
        const $elt = jq(elt);

        initialize(component, $elt.get(0), getProps($elt));
    });
}

export function renderElement(dom, element) {
    ReactDOM.render(<ApolloProvider client={apollo}>{element}</ApolloProvider>, dom);
}

export function initialize(Comp, dom, props = {}) {
    renderElement(dom, <Comp {...props} />);
}
