// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

var none = "";

function append(x1, x2) {
  if (x1 === "") {
    return x2;
  } else if (x2 === "") {
    return x1;
  } else {
    return x1 + (" " + x2);
  }
}

function fromList(param) {
  var _acc = "";
  var _param = param;
  while(true) {
    var param$1 = _param;
    var acc = _acc;
    if (!param$1) {
      return acc;
    }
    var cn = param$1.hd;
    if (cn === "") {
      _param = param$1.tl;
      continue ;
    }
    _param = param$1.tl;
    _acc = acc + (" " + cn);
    continue ;
  };
}

function on(cn, x) {
  if (x) {
    return cn;
  } else {
    return none;
  }
}

function onSome(cn, x) {
  if (x !== undefined) {
    return cn;
  } else {
    return none;
  }
}

function mapSome(x, fn) {
  if (x !== undefined) {
    return Curry._1(fn, Caml_option.valFromOption(x));
  } else {
    return none;
  }
}

function mapSomeU(x, fn) {
  if (x !== undefined) {
    return fn(Caml_option.valFromOption(x));
  } else {
    return none;
  }
}

function take(x) {
  if (x !== undefined) {
    return x;
  } else {
    return none;
  }
}

function onOk(cn, x) {
  if (x.TAG === /* Ok */0) {
    return cn;
  } else {
    return none;
  }
}

function mapOk(x, fn) {
  if (x.TAG === /* Ok */0) {
    return Curry._1(fn, x._0);
  } else {
    return none;
  }
}

function mapOkU(x, fn) {
  if (x.TAG === /* Ok */0) {
    return fn(x._0);
  } else {
    return none;
  }
}

function onErr(cn, x) {
  if (x.TAG === /* Ok */0) {
    return none;
  } else {
    return cn;
  }
}

function mapErr(x, fn) {
  if (x.TAG === /* Ok */0) {
    return none;
  } else {
    return Curry._1(fn, x._0);
  }
}

function mapErrU(x, fn) {
  if (x.TAG === /* Ok */0) {
    return none;
  } else {
    return fn(x._0);
  }
}

var $plus = append;

var $less$colon$great = append;

export {
  none ,
  append ,
  $plus ,
  $less$colon$great ,
  fromList ,
  on ,
  onSome ,
  mapSome ,
  mapSomeU ,
  take ,
  onOk ,
  mapOk ,
  mapOkU ,
  onErr ,
  mapErr ,
  mapErrU ,
  
}
/* No side effect */
