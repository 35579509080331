// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Js_option from "bs-platform/lib/es6/js_option.js";
import * as Utils_option from "./Utils_option.bs.js";
import Format from "date-fns/format";
import ParseISO from "date-fns/parseISO";
import Fr from "date-fns/locale/fr";
import EnUS from "date-fns/locale/en-US";

function getLocale(locale) {
  switch (locale) {
    case "FR" :
    case "fr" :
        return Fr;
    default:
      return EnUS;
  }
}

var Private = {
  getLocale: getLocale
};

function now(param) {
  return new Date();
}

function format(date, locale, pattern) {
  return Format(date, pattern, {
              locale: getLocale(locale)
            });
}

function formatLong(date, locale, style) {
  if (locale === "fr") {
    if (style === "LL") {
      return format(date, locale, "d MMMM yyyy");
    } else if (style === "LT") {
      return format(date, locale, "HH:mm");
    } else if (style === "LLL") {
      return format(date, locale, "d MMMM yyyy HH:mm");
    } else if (style === "LTS") {
      return format(date, locale, "HH:mm:ss");
    } else if (style === "LLLL") {
      return format(date, locale, "dddd, d MMMM yyyy HH:mm");
    } else {
      return format(date, locale, "dd/MM/yyyy");
    }
  } else if (style === "LL") {
    return format(date, locale, "MMMM d yyyy");
  } else if (style === "LT") {
    return format(date, locale, "h:mm aa");
  } else if (style === "LLL") {
    return format(date, locale, "MMMM d yyyy h:mm aa");
  } else if (style === "LTS") {
    return format(date, locale, "h:mm:ss aa");
  } else if (style === "LLLL") {
    return format(date, locale, "dddd, MMMM d yyyy h:mm aa");
  } else {
    return format(date, locale, "MM/dd/yyyy");
  }
}

function fromGraphql(json) {
  return ParseISO(Js_option.getExn(Js_json.decodeString(json)));
}

function optionalFromGraphql(json) {
  return Utils_option.map(fromGraphql, json);
}

function toGraphql(date) {
  return format(date, "en", "yyyy-MM-dd'T'HH:mm:ssxx");
}

export {
  Private ,
  now ,
  format ,
  formatLong ,
  fromGraphql ,
  optionalFromGraphql ,
  toGraphql ,
  
}
/* date-fns/format Not a pure module */
