// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Js_option from "bs-platform/lib/es6/js_option.js";
import * as Utils_date from "../utils/Utils_date.bs.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import ParseISO from "date-fns/parseISO";

var parse = Json_decode.$$int;

function serialize(id) {
  return id;
}

var ProId = {
  parse: parse,
  serialize: serialize
};

var parse$1 = Json_decode.$$int;

function serialize$1(id) {
  return id;
}

var ContractInvoiceId = {
  parse: parse$1,
  serialize: serialize$1
};

var parse$2 = Json_decode.$$int;

function serialize$2(id) {
  return id;
}

var ClientId = {
  parse: parse$2,
  serialize: serialize$2
};

var parse$3 = Json_decode.$$int;

function serialize$3(id) {
  return id;
}

var InvoiceId = {
  parse: parse$3,
  serialize: serialize$3
};

var parse$4 = Json_decode.$$int;

function serialize$4(id) {
  return id;
}

var ContractId = {
  parse: parse$4,
  serialize: serialize$4
};

var parse$5 = Json_decode.$$int;

function serialize$5(id) {
  return id;
}

var QuoteId = {
  parse: parse$5,
  serialize: serialize$5
};

function parse$6(json) {
  return ParseISO(Js_option.getExn(Js_json.decodeString(json)));
}

function serialize$6(date) {
  return Utils_date.format(date, "en", "yyyy-MM-dd'T'HH:mm:ssxx");
}

var $$Date = {
  parse: parse$6,
  serialize: serialize$6
};

function fromOptionBool(bool) {
  if (bool !== undefined) {
    if (bool) {
      return "YES";
    } else {
      return "NO";
    }
  } else {
    return "DEFAULT";
  }
}

var YesNoEnum = {
  fromOptionBool: fromOptionBool
};

export {
  ProId ,
  ContractInvoiceId ,
  ClientId ,
  InvoiceId ,
  ContractId ,
  QuoteId ,
  $$Date ,
  YesNoEnum ,
  
}
/* Utils_date Not a pure module */
