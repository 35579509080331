// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/src/Wrap.bs.js";
import * as Field$ReasonForm from "@maarekj/reason-form/src/Field.bs.js";

function make(alreadySended, monthsOpt, year, param) {
  var months = monthsOpt !== undefined ? monthsOpt : /* [] */0;
  return {
          alreadySended: alreadySended,
          months: months,
          year: year
        };
}

var Value = {
  make: make
};

function createFields(self, baseField) {
  return {
          self: self,
          alreadySended: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("alreadySended", (function (param) {
                      return param.alreadySended;
                    }), (function (alreadySended, v) {
                      return {
                              alreadySended: alreadySended,
                              months: v.months,
                              year: v.year
                            };
                    }))),
          months: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("months", (function (param) {
                      return param.months;
                    }), (function (months, v) {
                      return {
                              alreadySended: v.alreadySended,
                              months: months,
                              year: v.year
                            };
                    }))),
          year: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("year", (function (param) {
                      return param.year;
                    }), (function (year, v) {
                      return {
                              alreadySended: v.alreadySended,
                              months: v.months,
                              year: year
                            };
                    })))
        };
}

function validate(_fields, form) {
  return form;
}

function useForm(initialValues, onChange) {
  var change = React.useRef(onChange);
  change.current = onChange;
  var match = React.useState(function () {
        var rootField = Field$ReasonForm.idField("root");
        var fields = createFields(rootField, rootField);
        var form = Form$ReasonForm.initializeForm(initialValues, undefined, undefined, undefined, undefined, (function (param, oldForm, newForm) {
                if (Caml_obj.caml_notequal(oldForm, newForm)) {
                  Curry._1(change.current, undefined);
                }
                return newForm;
              }), (function (param) {
                return param;
              }), undefined);
        var wrap = Wrap$ReasonForm.make(undefined, form);
        return [
                wrap,
                fields
              ];
      });
  var match$1 = match[0];
  return [
          match$1[0],
          match$1[1]
        ];
}

export {
  Value ,
  createFields ,
  validate ,
  useForm ,
  
}
/* react Not a pure module */
