// Generated by ReScript, PLEASE EDIT WITH CARE

import Numeral from "numeral";
import * as Utils_unicode from "../utils/Utils_unicode.bs.js";
import Repeat from "lodash/repeat";

var Private = {};

function formatInt(i) {
  return Numeral(i).format("0,0");
}

function formatFloat(f, precision) {
  return Numeral(f).format("0,0." + Repeat("0", precision));
}

function formatAutoFloat(f) {
  return Numeral(f).format("0,0[.]00");
}

function formatIntEuro(i) {
  return Numeral(i).format("0,0") + (Utils_unicode.nbsp + "€");
}

function formatAutoFloatEuro(f) {
  return Numeral(f).format("0,0[.]00") + (Utils_unicode.nbsp + "€");
}

var formatAutoFloatEuroEl = formatAutoFloatEuro;

export {
  Private ,
  formatInt ,
  formatFloat ,
  formatAutoFloat ,
  formatIntEuro ,
  formatAutoFloatEuro ,
  formatAutoFloatEuroEl ,
  
}
/* numeral Not a pure module */
