// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Form_Renderer from "../../../Form/Form_Renderer.bs.js";

function InvoiceStyle_WinroForm(Props) {
  var wrap = Props.wrap;
  var fields = Props.fields;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex gx-gutter"
                }, React.createElement(Form_Renderer.Row.make, {
                      label: "Couleur principale",
                      className: "form-group flex-1",
                      input: React.createElement(Form_Renderer.Input.make, {
                            wrap: wrap,
                            type_: "color",
                            toText: Form_Renderer.optionToText,
                            fromText: Form_Renderer.optionFromText,
                            field: fields.mainColor
                          }),
                      wrap: wrap,
                      field: fields.mainColor
                    }), React.createElement(Form_Renderer.Row.make, {
                      label: "Couleur secondaire",
                      className: "form-group flex-1",
                      input: React.createElement(Form_Renderer.Input.make, {
                            wrap: wrap,
                            type_: "color",
                            toText: Form_Renderer.optionToText,
                            fromText: Form_Renderer.optionFromText,
                            field: fields.secondaryColor
                          }),
                      wrap: wrap,
                      field: fields.secondaryColor
                    })), React.createElement(Form_Renderer.Row.make, {
                  label: "Texte du haut",
                  className: "form-group flex-1",
                  input: React.createElement(Form_Renderer.Textarea.make, {
                        wrap: wrap,
                        toText: Form_Renderer.optionToText,
                        fromText: Form_Renderer.optionFromText,
                        field: fields.top
                      }),
                  wrap: wrap,
                  field: fields.top
                }), React.createElement(Form_Renderer.Row.make, {
                  label: "Texte du bas",
                  className: "form-group flex-1",
                  input: React.createElement(Form_Renderer.Textarea.make, {
                        wrap: wrap,
                        toText: Form_Renderer.optionToText,
                        fromText: Form_Renderer.optionFromText,
                        field: fields.bottom
                      }),
                  wrap: wrap,
                  field: fields.bottom
                }), React.createElement(Form_Renderer.Row.make, {
                  label: "Titre du footer",
                  className: "form-group flex-1",
                  input: React.createElement(Form_Renderer.Textarea.make, {
                        wrap: wrap,
                        toText: Form_Renderer.optionToText,
                        fromText: Form_Renderer.optionFromText,
                        field: fields.titleFooter
                      }),
                  wrap: wrap,
                  field: fields.titleFooter
                }), React.createElement(Form_Renderer.Row.make, {
                  label: "Texte du footer",
                  className: "form-group flex-1",
                  input: React.createElement(Form_Renderer.Textarea.make, {
                        wrap: wrap,
                        toText: Form_Renderer.optionToText,
                        fromText: Form_Renderer.optionFromText,
                        field: fields.footer
                      }),
                  wrap: wrap,
                  field: fields.footer
                }));
}

var Fields;

var make = InvoiceStyle_WinroForm;

export {
  Fields ,
  make ,
  
}
/* react Not a pure module */
