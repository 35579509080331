// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/src/Wrap.bs.js";
import * as Field$ReasonForm from "@maarekj/reason-form/src/Field.bs.js";

function fromJson(json) {
  return {
          top: Json_decode.field("top", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          bottom: Json_decode.field("bottom", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          titleFooter: Json_decode.field("titleFooter", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          footer: Json_decode.field("footer", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          mainColor: Json_decode.field("mainColor", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          secondaryColor: Json_decode.field("secondaryColor", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json)
        };
}

function toJson(v) {
  return Json_encode.object_({
              hd: [
                "style",
                "winro"
              ],
              tl: {
                hd: [
                  "top",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), v.top)
                ],
                tl: {
                  hd: [
                    "bottom",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), v.bottom)
                  ],
                  tl: {
                    hd: [
                      "titleFooter",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), v.titleFooter)
                    ],
                    tl: {
                      hd: [
                        "footer",
                        Json_encode.nullable((function (prim) {
                                return prim;
                              }), v.footer)
                      ],
                      tl: {
                        hd: [
                          "mainColor",
                          Json_encode.nullable((function (prim) {
                                  return prim;
                                }), v.mainColor)
                        ],
                        tl: {
                          hd: [
                            "secondaryColor",
                            Json_encode.nullable((function (prim) {
                                    return prim;
                                  }), v.secondaryColor)
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Value_empty = {
  top: undefined,
  bottom: undefined,
  titleFooter: undefined,
  footer: undefined,
  mainColor: "#70BABB",
  secondaryColor: "#6367A6"
};

var Value = {
  empty: Value_empty,
  fromJson: fromJson,
  toJson: toJson
};

function createFields(self, baseField) {
  return {
          self: self,
          top: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("top", (function (v) {
                      return v.top;
                    }), (function (top, v) {
                      return {
                              top: top,
                              bottom: v.bottom,
                              titleFooter: v.titleFooter,
                              footer: v.footer,
                              mainColor: v.mainColor,
                              secondaryColor: v.secondaryColor
                            };
                    }))),
          bottom: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("bottom", (function (v) {
                      return v.bottom;
                    }), (function (bottom, v) {
                      return {
                              top: v.top,
                              bottom: bottom,
                              titleFooter: v.titleFooter,
                              footer: v.footer,
                              mainColor: v.mainColor,
                              secondaryColor: v.secondaryColor
                            };
                    }))),
          titleFooter: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("titleFooter", (function (v) {
                      return v.titleFooter;
                    }), (function (titleFooter, v) {
                      return {
                              top: v.top,
                              bottom: v.bottom,
                              titleFooter: titleFooter,
                              footer: v.footer,
                              mainColor: v.mainColor,
                              secondaryColor: v.secondaryColor
                            };
                    }))),
          footer: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("footer", (function (v) {
                      return v.footer;
                    }), (function (footer, v) {
                      return {
                              top: v.top,
                              bottom: v.bottom,
                              titleFooter: v.titleFooter,
                              footer: footer,
                              mainColor: v.mainColor,
                              secondaryColor: v.secondaryColor
                            };
                    }))),
          mainColor: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("mainColor", (function (v) {
                      return v.mainColor;
                    }), (function (mainColor, v) {
                      return {
                              top: v.top,
                              bottom: v.bottom,
                              titleFooter: v.titleFooter,
                              footer: v.footer,
                              mainColor: mainColor,
                              secondaryColor: v.secondaryColor
                            };
                    }))),
          secondaryColor: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("secondaryColor", (function (v) {
                      return v.secondaryColor;
                    }), (function (secondaryColor, v) {
                      return {
                              top: v.top,
                              bottom: v.bottom,
                              titleFooter: v.titleFooter,
                              footer: v.footer,
                              mainColor: v.mainColor,
                              secondaryColor: secondaryColor
                            };
                    })))
        };
}

function useForm(initialValues, onChangeValue, onBlur, onFocus, param) {
  return React.useMemo((function () {
                var form = Form$ReasonForm.initializeForm(initialValues, undefined, undefined, onBlur, onFocus, onChangeValue, undefined, undefined);
                var wrap = Wrap$ReasonForm.make(undefined, form);
                var rootField = Field$ReasonForm.idField("root");
                return [
                        wrap,
                        createFields(rootField, rootField)
                      ];
              }), []);
}

export {
  Value ,
  createFields ,
  useForm ,
  
}
/* react Not a pure module */
