import jq from "jquery";
import JqueryUtils from "../lib/JqueryUtils";
require("jquery-ui/sortable");

const namePlugin = "appCollectionType";
const prefix = "app-collection";

export default class CollectionType {
    constructor($container, options) {
        this.$container = $container;
        ({
            proto: this.proto,
            protoName: this.protoName,
            max: this.max,
            sortable: this.sortable,
            uiSortable: this.uiSortable,
            collectionName: this.collectionName,
        } = options);

        if (this.protoName == null) {
            this.protoName = "__name__";
        }
    }

    static fromDom(opts) {
        const {$container} = opts;

        const widget = new CollectionType($container, {
            proto: $container.data("prototype"),
            protoName: $container.data("prototype-name"),
            max: $container.data("max"),
            sortable: $container.data("sortable"),
            uiSortable: $container.data("ui-sortable"),
            collectionName: $container.data("collection-name"),
        });

        widget.init();

        return widget;
    }

    clsSelector(cls) {
        return `.${prefix}-${cls}[data-for-collection=${this.collectionName}]`;
    }

    init() {
        JqueryUtils.preInit(this.$container, namePlugin, this);

        this.toggleAddButtonIfNecessary();

        this.$container.on(`click.${namePlugin}`, this.clsSelector("add"), (event) => {
            event.preventDefault();

            const $items = this.$container.find(this.clsSelector("items"));
            const number = $items.children().length;

            // Set field id
            const idRegexp = new RegExp(this.$container.attr("id") + "_" + this.protoName, "g");
            let proto = this.proto.replace(idRegexp, this.$container.attr("id") + "_" + number);

            // Set field name
            const parts = this.$container.attr("id").split("_");
            const nameRegexp = new RegExp(parts[parts.length - 1] + "\\]\\[" + this.protoName, "g");
            proto = proto.replace(nameRegexp, parts[parts.length - 1] + "][" + number);

            const $proto = jq(proto).appendTo($items);
            this.updateSortableRank();

            $proto.trigger(`${prefix}-item-added`);

            return this.toggleAddButtonIfNecessary();
        });

        this.$container.on(`click.${namePlugin}`, this.clsSelector("delete"), (event) => {
            event.preventDefault();
            jq(event.currentTarget).trigger(`${prefix}-item-deleted`);
            jq(event.currentTarget).closest(this.clsSelector("row")).remove();

            this.updateSortableRank();
            return this.toggleAddButtonIfNecessary();
        });

        if (this.sortable != null) {
            this.$container.on("click", this.clsSelector("sortable"), (event) => event.preventDefault());
            return this.$container.find(this.clsSelector("items")).sortable(
                {
                    handle: this.clsSelector("sortable"),
                    update: (event, ui) => this.updateSortableRank(),
                },
                this.uiSortable,
            );
        }
    }

    updateSortableRank() {
        return this.$container.find(this.clsSelector("row")).each((i, row) => {
            return jq(jq(row).data("sortable-input-select")).val(i);
        });
    }

    toggleAddButtonIfNecessary() {
        if (this.max == null) {
            return;
        }

        const $items = this.$container.find(this.clsSelector("items"));
        const number = $items.children().length;
        if (number >= this.max) {
            return this.$container.find(this.clsSelector("add")).attr("disabled", "disabled");
        } else {
            return this.$container.find(this.clsSelector("add")).removeAttr("disabled");
        }
    }

    destroy() {
        return JqueryUtils.postDestroy(this.$container, namePlugin, this);
    }
}

JqueryUtils.declarePlugin(namePlugin, {
    allowedMethods: ["destroy"],
    initCallback(element, opts) {
        opts.$container = jq(element);
        return CollectionType.fromDom(opts);
    },
});
