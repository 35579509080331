// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils_option from "../utils/Utils_option.bs.js";
import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";

function defaultMessage(param) {
  return "Ne doit pas être vide.";
}

function validateOption(value, field, message, param, form) {
  if (value !== undefined) {
    return form;
  } else {
    return Form$ReasonForm.addError(field.key, Utils_option.lazyDef(defaultMessage, message), form);
  }
}

function validateString(value, field, message, param, form) {
  if (value === "" || value.trim() === "") {
    return Form$ReasonForm.addError(field.key, Utils_option.lazyDef(defaultMessage, message), form);
  } else {
    return form;
  }
}

function validateOptionString(value, field, message, param, form) {
  if (value !== undefined && value !== "") {
    if (value.trim() === "") {
      return Form$ReasonForm.addError(field.key, Utils_option.lazyDef(defaultMessage, message), form);
    } else {
      return form;
    }
  }
  return Form$ReasonForm.addError(field.key, Utils_option.lazyDef(defaultMessage, message), form);
}

export {
  validateOption ,
  validateString ,
  validateOptionString ,
  
}
/* No side effect */
