// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils from "../../utils/Utils.bs.js";
import * as React from "react";
import * as Form_Renderer from "../../Form/Form_Renderer.bs.js";
import * as MarkdownInput from "../MarkdownInput/MarkdownInput.bs.js";

function ModalSendEmail_Form(Props) {
  var wrap = Props.wrap;
  var placeholdersOpt = Props.placeholders;
  var fields = Props.fields;
  var placeholders = placeholdersOpt !== undefined ? placeholdersOpt : /* [] */0;
  return React.createElement("div", {
              className: ""
            }, React.createElement(Form_Renderer.Row.make, {
                  label: "Email du client",
                  className: "form-group flex-1",
                  input: React.createElement(Form_Renderer.Input.make, {
                        wrap: wrap,
                        type_: "text",
                        toText: Utils.id,
                        fromText: Utils.id,
                        field: fields.email
                      }),
                  wrap: wrap,
                  field: fields.email
                }), React.createElement(Form_Renderer.Row.make, {
                  label: "Sujet",
                  className: "form-group flex-1",
                  input: React.createElement(MarkdownInput.make, MarkdownInput.makeProps(wrap, undefined, fields.subject, Utils.id, Utils.id, placeholders, false, 1, false, undefined, undefined)),
                  wrap: wrap,
                  field: fields.subject
                }), React.createElement(Form_Renderer.Row.make, {
                  label: "Contenu du message",
                  className: "form-group flex-1",
                  input: React.createElement(MarkdownInput.make, MarkdownInput.makeProps(wrap, undefined, fields.body, Utils.id, Utils.id, placeholders, undefined, undefined, undefined, undefined, undefined)),
                  wrap: wrap,
                  field: fields.body
                }));
}

var Fields;

var make = ModalSendEmail_Form;

export {
  Fields ,
  make ,
  
}
/* react Not a pure module */
