// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function then_(prim, prim$1) {
  return prim$1.then(Curry.__1(prim));
}

function reject(prim) {
  return Promise.reject(prim);
}

function resolve(prim) {
  return Promise.resolve(prim);
}

function resolveOk(v) {
  return Promise.resolve({
              TAG: 0,
              _0: v,
              [Symbol.for("name")]: "Ok"
            });
}

function flatMap(promise, callback) {
  return promise.then(Curry.__1(callback));
}

function map(promise, callback) {
  return promise.then(function (x) {
              return Promise.resolve(Curry._1(callback, x));
            });
}

function tap(promise, callback) {
  return map(promise, (function (x) {
                Curry._1(callback, x);
                return x;
              }));
}

function flatMapOk(promise, callback) {
  return promise.then(function (result) {
              if (result.TAG === /* Ok */0) {
                return Curry._1(callback, result._0);
              } else {
                return Promise.resolve({
                            TAG: 1,
                            _0: result._0,
                            [Symbol.for("name")]: "Error"
                          });
              }
            });
}

function mapOk(promise, callback) {
  return flatMapOk(promise, (function (result) {
                return Promise.resolve(Curry._1(callback, result));
              }));
}

function flatMapError(promise, callback) {
  return promise.then(function (result) {
              if (result.TAG === /* Ok */0) {
                return Promise.resolve({
                            TAG: 0,
                            _0: result._0,
                            [Symbol.for("name")]: "Ok"
                          });
              } else {
                return Curry._1(callback, result._0);
              }
            });
}

function mapError(promise, callback) {
  return flatMapError(promise, (function (error) {
                return Promise.resolve(Curry._1(callback, error));
              }));
}

function errorIfNone(promise, error) {
  return mapOk(promise, (function (v) {
                if (v !== undefined) {
                  return {
                          TAG: 0,
                          _0: Caml_option.valFromOption(v),
                          [Symbol.for("name")]: "Ok"
                        };
                } else {
                  return {
                          TAG: 1,
                          _0: error,
                          [Symbol.for("name")]: "Error"
                        };
                }
              }));
}

function rejectIfNone(promise, exn) {
  return promise.then(function (value) {
              if (value !== undefined) {
                return Promise.resolve(Caml_option.valFromOption(value));
              } else {
                return Promise.reject(exn);
              }
            });
}

function flatCatch(promise, callback) {
  return promise.catch(Curry.__1(callback));
}

function $$catch(promise, callback) {
  return promise.catch(function (a) {
              return Promise.resolve(Curry._1(callback, a));
            });
}

function $$finally(promise, callback) {
  return promise.finally(function () {
              return Curry._1(callback, undefined);
            });
}

function convertResult(promise) {
  return new Promise((function (resolve, param) {
                map(promise, (function (v) {
                        return resolve({
                                    TAG: 0,
                                    _0: v,
                                    [Symbol.for("name")]: "Ok"
                                  });
                      }));
                promise.catch(function (e) {
                      return resolve({
                                  TAG: 1,
                                  _0: {
                                    NAME: "exn",
                                    VAL: e
                                  },
                                  [Symbol.for("name")]: "Error"
                                });
                    });
                
              }));
}

function fromResult(promise, mapError) {
  return map(promise, (function (v) {
                if (v.TAG === /* Ok */0) {
                  return v._0;
                }
                throw Curry._1(mapError, v._0);
              }));
}

var Op = {
  $great$pipe$eq: map,
  $great$great$eq: flatMap,
  $great$great$great: tap,
  $great$pipe$neg: $$catch,
  $great$great$pipe$neg: flatCatch
};

function delay(ms) {
  return new Promise((function (resolve, param) {
                setTimeout((function (param) {
                        return resolve(undefined);
                      }), ms);
                
              }));
}

var unit;

export {
  then_ ,
  reject ,
  resolve ,
  resolveOk ,
  flatMap ,
  map ,
  tap ,
  flatMapOk ,
  mapOk ,
  flatMapError ,
  mapError ,
  errorIfNone ,
  rejectIfNone ,
  flatCatch ,
  $$catch ,
  $$finally ,
  convertResult ,
  fromResult ,
  Op ,
  unit ,
  delay ,
  
}
/* No side effect */
