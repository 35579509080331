// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Apollo from "../Apollo.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_format from "bs-platform/lib/es6/caml_format.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils_error from "../utils/Utils_error.bs.js";
import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";
import * as Hook$ReasonForm from "@maarekj/reason-form/src/Hook.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/src/Wrap.bs.js";
import * as Helper$ReasonForm from "@maarekj/reason-form/src/Helper.bs.js";

function optionToText(opt) {
  return Belt_Option.getWithDefault(opt, "");
}

function optionFromText(text) {
  if (text === "") {
    return ;
  } else {
    return text;
  }
}

function optionIntToText(opt) {
  return Belt_Option.mapWithDefault(opt, "", (function (prim) {
                return String(prim);
              }));
}

function optionIntFromText(text) {
  if (text === "") {
    return ;
  }
  try {
    return Caml_format.caml_int_of_string(text);
  }
  catch (exn){
    return ;
  }
}

function normalizeId(id) {
  var reg = /[^-_a-zA-Z0-9]/g;
  return id.replace(reg, "-");
}

function Form_Renderer$FieldErrors(Props) {
  var wrap = Props.wrap;
  var field = Props.field;
  var match = Hook$ReasonForm.useFormMeta(wrap);
  var nbSubmits = match.nbSubmits;
  var match$1 = Hook$ReasonForm.useMeta(wrap, field);
  var errors = match$1.errors;
  var isDirty = match$1.isDirty;
  return React.useMemo((function () {
                if ((nbSubmits > 0 || isDirty) && Belt_List.length(errors) !== 0) {
                  return React.createElement("div", {
                              className: "help-block",
                              style: {
                                display: "block"
                              }
                            }, Belt_List.toArray(Belt_List.map(errors, (function (prim) {
                                        return prim;
                                      }))));
                } else {
                  return null;
                }
              }), [
              nbSubmits,
              isDirty,
              errors
            ]);
}

var FieldErrors = {
  make: Form_Renderer$FieldErrors
};

function Form_Renderer$FormErrors(Props) {
  var wrap = Props.wrap;
  var match = Hook$ReasonForm.useFormMeta(wrap);
  var nbSubmits = match.nbSubmits;
  var submitErrors = match.submitErrors;
  var hasSubmitErrors = match.hasSubmitErrors;
  var rootErrors = match.rootErrors;
  var hasRootErrors = match.hasRootErrors;
  return React.useMemo((function () {
                if (nbSubmits > 0 && (hasRootErrors || hasSubmitErrors)) {
                  return React.createElement("div", {
                              className: "alert alert-danger"
                            }, Belt_List.toArray(Belt_List.map(rootErrors, (function (prim) {
                                        return prim;
                                      }))), Belt_List.toArray(Belt_List.map(submitErrors, (function (prim) {
                                        return prim;
                                      }))));
                } else {
                  return null;
                }
              }), [
              nbSubmits,
              hasRootErrors,
              hasSubmitErrors,
              rootErrors,
              submitErrors
            ]);
}

var FormErrors = {
  make: Form_Renderer$FormErrors
};

function Form_Renderer$Input(Props) {
  var wrap = Props.wrap;
  var type_Opt = Props.type_;
  var id = Props.id;
  var classNameOpt = Props.className;
  var toText = Props.toText;
  var fromText = Props.fromText;
  var field = Props.field;
  var type_ = type_Opt !== undefined ? type_Opt : "text";
  var className = classNameOpt !== undefined ? classNameOpt : "form-control";
  var value = Hook$ReasonForm.useValue(wrap, field);
  var match = Hook$ReasonForm.useFormMeta(wrap);
  var nbSubmits = match.nbSubmits;
  var match$1 = Hook$ReasonForm.useMeta(wrap, field);
  var hasError = match$1.hasError;
  var isDirty = match$1.isDirty;
  var key = match$1.key;
  return React.useMemo((function () {
                console.log("render input");
                var focus = function (param) {
                  return Form$ReasonForm.focus(key, param);
                };
                var blur = function (param) {
                  return Form$ReasonForm.blur(key, param);
                };
                var id$1;
                if (id !== undefined) {
                  id$1 = id;
                } else {
                  var prefixId = Wrap$ReasonForm.id(wrap);
                  id$1 = normalizeId(prefixId + ("-" + key));
                }
                return React.createElement("input", {
                            className: className + (" " + (
                                (nbSubmits > 0 || isDirty) && hasError ? "is-invalid" : ""
                              )),
                            id: id$1,
                            type: type_,
                            value: Curry._1(toText, value),
                            onFocus: (function (_event) {
                                return Wrap$ReasonForm.dispatch(wrap, focus);
                              }),
                            onBlur: (function (_event) {
                                return Wrap$ReasonForm.dispatch(wrap, blur);
                              }),
                            onChange: (function ($$event) {
                                var value = Curry._1(fromText, Helper$ReasonForm.getValue($$event));
                                return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                                              return Helper$ReasonForm.changeFieldValue(field, value, param);
                                            }));
                              })
                          });
              }), [
              value,
              nbSubmits,
              isDirty,
              hasError,
              key,
              className,
              toText,
              fromText,
              id
            ]);
}

var Input = {
  make: Form_Renderer$Input
};

function Form_Renderer$Textarea(Props) {
  var wrap = Props.wrap;
  var id = Props.id;
  var classNameOpt = Props.className;
  var toText = Props.toText;
  var fromText = Props.fromText;
  var field = Props.field;
  var rowsOpt = Props.rows;
  var className = classNameOpt !== undefined ? classNameOpt : "form-control";
  var rows = rowsOpt !== undefined ? rowsOpt : 5;
  var value = Hook$ReasonForm.useValue(wrap, field);
  var match = Hook$ReasonForm.useFormMeta(wrap);
  var nbSubmits = match.nbSubmits;
  var match$1 = Hook$ReasonForm.useMeta(wrap, field);
  var hasError = match$1.hasError;
  var isDirty = match$1.isDirty;
  var key = match$1.key;
  return React.useMemo((function () {
                console.log("render textarea");
                var focus = function (param) {
                  return Form$ReasonForm.focus(key, param);
                };
                var blur = function (param) {
                  return Form$ReasonForm.blur(key, param);
                };
                var id$1;
                if (id !== undefined) {
                  id$1 = id;
                } else {
                  var prefixId = Wrap$ReasonForm.id(wrap);
                  id$1 = normalizeId(prefixId + ("-" + key));
                }
                return React.createElement("textarea", {
                            className: className + (" " + (
                                (nbSubmits > 0 || isDirty) && hasError ? "is-invalid" : ""
                              )),
                            id: id$1,
                            rows: rows,
                            value: Curry._1(toText, value),
                            onFocus: (function (_event) {
                                return Wrap$ReasonForm.dispatch(wrap, focus);
                              }),
                            onBlur: (function (_event) {
                                return Wrap$ReasonForm.dispatch(wrap, blur);
                              }),
                            onChange: (function ($$event) {
                                var value = Curry._1(fromText, Helper$ReasonForm.getValue($$event));
                                return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                                              return Helper$ReasonForm.changeFieldValue(field, value, param);
                                            }));
                              })
                          });
              }), [
              value,
              nbSubmits,
              isDirty,
              hasError,
              key,
              className,
              toText,
              fromText,
              id,
              rows
            ]);
}

var Textarea = {
  make: Form_Renderer$Textarea
};

function Form_Renderer$Row(Props) {
  var label = Props.label;
  var labelFor = Props.labelFor;
  var classNameOpt = Props.className;
  var input = Props.input;
  var wrap = Props.wrap;
  var field = Props.field;
  var className = classNameOpt !== undefined ? classNameOpt : "form-group";
  var match = Hook$ReasonForm.useFormMeta(wrap);
  var match$1 = Hook$ReasonForm.useMeta(wrap, field);
  var labelFor$1;
  if (labelFor !== undefined) {
    labelFor$1 = labelFor;
  } else {
    var prefixId = Wrap$ReasonForm.id(wrap);
    labelFor$1 = normalizeId(prefixId + ("-" + match$1.key));
  }
  return React.createElement("div", {
              className: className + (" " + (
                  (match.nbSubmits > 0 || match$1.isDirty) && match$1.hasError ? "has-error" : ""
                ))
            }, label !== undefined ? React.createElement("label", {
                    htmlFor: labelFor$1
                  }, label) : null, input, React.createElement(Form_Renderer$FieldErrors, {
                  wrap: wrap,
                  field: field
                }));
}

var Row = {
  make: Form_Renderer$Row
};

function Form_Renderer$SubmitButton(Props) {
  var wrap = Props.wrap;
  var text = Props.text;
  var submittingText = Props.submittingText;
  var formMeta = Hook$ReasonForm.useFormMeta(wrap);
  var isSubmitting = formMeta.isSubmitting;
  return React.createElement("button", {
              className: "btn btn-primary " + (
                formMeta.hasErrors || isSubmitting ? "disabled" : ""
              ),
              type: "submit"
            }, isSubmitting ? submittingText : text);
}

var SubmitButton = {
  make: Form_Renderer$SubmitButton
};

function Form_Renderer$SubmitActionButton(Props) {
  var wrap = Props.wrap;
  var text = Props.text;
  var submittingText = Props.submittingText;
  var onClick = Props.onClick;
  var formMeta = Hook$ReasonForm.useFormMeta(wrap);
  var isSubmitting = formMeta.isSubmitting;
  var tmp = {
    className: "btn btn-primary " + (
      formMeta.hasErrors || isSubmitting ? "disabled" : ""
    )
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("button", tmp, isSubmitting ? submittingText : text);
}

var SubmitActionButton = {
  make: Form_Renderer$SubmitActionButton
};

function Form_Renderer$ResetButton(Props) {
  var wrap = Props.wrap;
  var initialForm = Props.initialForm;
  var text = Props.text;
  var formMeta = Hook$ReasonForm.useFormMeta(wrap);
  return React.createElement("button", {
              className: "btn btn-light " + (
                formMeta.isSubmitting ? "disabled" : ""
              ),
              onClick: (function ($$event) {
                  $$event.preventDefault();
                  return Wrap$ReasonForm.dispatch(wrap, (function (_form) {
                                return initialForm;
                              }));
                })
            }, text);
}

var ResetButton = {
  make: Form_Renderer$ResetButton
};

function defaultOnSuccess(param, param$1) {
  
}

function useOnSubmit(wrap, onSubmit, onSuccessOpt, param) {
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : defaultOnSuccess;
  return React.useCallback((function (param) {
                Wrap$ReasonForm.dispatch(wrap, Form$ReasonForm.startSubmit);
                Wrap$ReasonForm.dispatch(wrap, Form$ReasonForm.clearSubmitErrors);
                return Wrap$ReasonForm.dispatch(wrap, (function (form) {
                              if (Form$ReasonForm.formHasErrors(form)) {
                                return Form$ReasonForm.stopSubmit(form);
                              } else {
                                Curry._1(onSubmit, form).then(function (value) {
                                      if (value.TAG === /* Ok */0) {
                                        Wrap$ReasonForm.dispatch(wrap, Form$ReasonForm.submitSuccess);
                                        Wrap$ReasonForm.dispatch(wrap, Form$ReasonForm.stopSubmit);
                                        Curry._2(onSuccess, value._0, Wrap$ReasonForm.content(wrap));
                                        return Promise.resolve(undefined);
                                      }
                                      var error = value._0;
                                      var message;
                                      if (typeof error === "string") {
                                        message = "Unknown error";
                                      } else {
                                        var variant = error.NAME;
                                        message = variant === "exn" ? Utils_error.exnToString(error.VAL) : (
                                            variant === "apollo" ? Apollo.getErrorMessage(error.VAL) : (
                                                variant === "string" ? error.VAL : "Unknown error"
                                              )
                                          );
                                      }
                                      Wrap$ReasonForm.dispatch(wrap, (function (param) {
                                              return Form$ReasonForm.addSubmitError(message, param);
                                            }));
                                      Wrap$ReasonForm.dispatch(wrap, Form$ReasonForm.stopSubmit);
                                      return Promise.resolve(undefined);
                                    });
                                return form;
                              }
                            }));
              }), [
              onSubmit,
              onSuccess
            ]);
}

function useOnSubmitWithPrevent(wrap, onSubmit, onSuccessOpt, param) {
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : defaultOnSuccess;
  var onSubmit$1 = useOnSubmit(wrap, onSubmit, onSuccess, undefined);
  return React.useCallback((function ($$event) {
                $$event.preventDefault();
                return Curry._1(onSubmit$1, undefined);
              }), [onSubmit$1]);
}

function Form_Renderer$Form(Props) {
  var wrap = Props.wrap;
  var classNameOpt = Props.className;
  var onSubmit = Props.onSubmit;
  var onSuccess = Props.onSuccess;
  var childrenOpt = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "w-full";
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var onSubmit$1 = useOnSubmitWithPrevent(wrap, onSubmit, onSuccess, undefined);
  return React.createElement("form", {
              className: className,
              onSubmit: onSubmit$1
            }, children);
}

var Form = {
  defaultOnSuccess: defaultOnSuccess,
  useOnSubmit: useOnSubmit,
  useOnSubmitWithPrevent: useOnSubmitWithPrevent,
  make: Form_Renderer$Form
};

export {
  optionToText ,
  optionFromText ,
  optionIntToText ,
  optionIntFromText ,
  normalizeId ,
  FieldErrors ,
  FormErrors ,
  Input ,
  Textarea ,
  Row ,
  SubmitButton ,
  SubmitActionButton ,
  ResetButton ,
  Form ,
  
}
/* react Not a pure module */
