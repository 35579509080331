// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as $$Image from "../Image.bs.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Utils_react from "../../utils/Utils_react.bs.js";
import * as React_bootstrap from "../../vendor/React_bootstrap.bs.js";
import * as ModalClient_InvoiceTr from "./ModalClient_InvoiceTr.bs.js";
import ModalBody from "react-bootstrap/lib/ModalBody";
import ModalTitle from "react-bootstrap/lib/ModalTitle";
import ModalFooter from "react-bootstrap/lib/ModalFooter";
import ModalHeader from "react-bootstrap/lib/ModalHeader";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import ModalLoadingJs from "netosoftcrm-js/Modal/ModalLoading.js";

var Raw = {};

var query = ((frag_0, frag_1) => require("@apollo/client").gql`
  query clientQuery($id: Int!)  {
    client: strictClient(id: $id)  {
      __typename
      id
      isCompany
      invoiceName
      companyName
      companyCommonName
      companyLogo  {
        __typename
        id
        name
        width
        height
        referenceUrl
        w500: filteredUrl(filter: widen500)
      }
      observations
      status
      from
      fromObservation
      emailCompta
      tel1
      tel2
      fax
      facebook
      instagram
      companyType
      companyTypeTr
      intraCommunityVatNumber
      editUrl
      invoiceListUrl
      quoteListUrl
      contacts  {
        __typename
        id
        gender
        lastname
        firstname
        post
        tel
        mobile
        email
      }
      address  {
        __typename
        street
        preComplement
        complement
        zipcode
        freeZipcode
        freeCity
        country
        countryLabel
        city  {
          __typename
          id
          name
          county  {
            __typename
            id
            name
            region  {
              __typename
              id
              name
              country  {
                __typename
                id
                name
                isoCode
              }
            }
          }
        }
      }
      invoices  {
        __typename
        id
        ...InvoiceFrag
      }
      quotes  {
        __typename
        id
        ...QuoteFrag
      }
    }
  }
  ${frag_0}
  ${frag_1}
`)(ModalClient_InvoiceTr.InvoiceFrag.query, ModalClient_InvoiceTr.QuoteFrag.query);

function parse(value) {
  var value$1 = value.client;
  var value$2 = value$1.invoiceName;
  var value$3 = value$1.companyName;
  var value$4 = value$1.companyCommonName;
  var value$5 = value$1.companyLogo;
  var value$6 = value$1.observations;
  var value$7 = value$1.fromObservation;
  var value$8 = value$1.emailCompta;
  var value$9 = value$1.tel1;
  var value$10 = value$1.tel2;
  var value$11 = value$1.fax;
  var value$12 = value$1.facebook;
  var value$13 = value$1.instagram;
  var value$14 = value$1.companyType;
  var value$15 = value$1.companyTypeTr;
  var value$16 = value$1.intraCommunityVatNumber;
  var value$17 = value$1.contacts;
  var value$18 = value$1.address;
  var value$19 = value$18.street;
  var value$20 = value$18.preComplement;
  var value$21 = value$18.complement;
  var value$22 = value$18.zipcode;
  var value$23 = value$18.freeZipcode;
  var value$24 = value$18.freeCity;
  var value$25 = value$18.country;
  var value$26 = value$18.countryLabel;
  var value$27 = value$18.city;
  var tmp;
  if (value$27 == null) {
    tmp = undefined;
  } else {
    var value$28 = value$27.county;
    var value$29 = value$28.region;
    var value$30 = value$29.country;
    tmp = {
      __typename: value$27.__typename,
      id: value$27.id,
      name: value$27.name,
      county: {
        __typename: value$28.__typename,
        id: value$28.id,
        name: value$28.name,
        region: {
          __typename: value$29.__typename,
          id: value$29.id,
          name: value$29.name,
          country: {
            __typename: value$30.__typename,
            id: value$30.id,
            name: value$30.name,
            isoCode: value$30.isoCode
          }
        }
      }
    };
  }
  var value$31 = value$1.invoices;
  var value$32 = value$1.quotes;
  return {
          client: {
            __typename: value$1.__typename,
            id: value$1.id,
            isCompany: value$1.isCompany,
            invoiceName: !(value$2 == null) ? value$2 : undefined,
            companyName: !(value$3 == null) ? value$3 : undefined,
            companyCommonName: !(value$4 == null) ? value$4 : undefined,
            companyLogo: !(value$5 == null) ? ({
                  __typename: value$5.__typename,
                  id: value$5.id,
                  name: value$5.name,
                  width: value$5.width,
                  height: value$5.height,
                  referenceUrl: value$5.referenceUrl,
                  w500: value$5.w500
                }) : undefined,
            observations: !(value$6 == null) ? value$6 : undefined,
            status: value$1.status,
            from: value$1.from,
            fromObservation: !(value$7 == null) ? value$7 : undefined,
            emailCompta: !(value$8 == null) ? value$8 : undefined,
            tel1: !(value$9 == null) ? value$9 : undefined,
            tel2: !(value$10 == null) ? value$10 : undefined,
            fax: !(value$11 == null) ? value$11 : undefined,
            facebook: !(value$12 == null) ? value$12 : undefined,
            instagram: !(value$13 == null) ? value$13 : undefined,
            companyType: !(value$14 == null) ? value$14 : undefined,
            companyTypeTr: !(value$15 == null) ? value$15 : undefined,
            intraCommunityVatNumber: !(value$16 == null) ? value$16 : undefined,
            editUrl: value$1.editUrl,
            invoiceListUrl: value$1.invoiceListUrl,
            quoteListUrl: value$1.quoteListUrl,
            contacts: value$17.map(function (value) {
                  var value$1 = value.gender;
                  var value$2 = value.lastname;
                  var value$3 = value.firstname;
                  var value$4 = value.post;
                  var value$5 = value.tel;
                  var value$6 = value.mobile;
                  var value$7 = value.email;
                  return {
                          __typename: value.__typename,
                          id: value.id,
                          gender: !(value$1 == null) ? value$1 : undefined,
                          lastname: !(value$2 == null) ? value$2 : undefined,
                          firstname: !(value$3 == null) ? value$3 : undefined,
                          post: !(value$4 == null) ? value$4 : undefined,
                          tel: !(value$5 == null) ? value$5 : undefined,
                          mobile: !(value$6 == null) ? value$6 : undefined,
                          email: !(value$7 == null) ? value$7 : undefined
                        };
                }),
            address: {
              __typename: value$18.__typename,
              street: !(value$19 == null) ? value$19 : undefined,
              preComplement: !(value$20 == null) ? value$20 : undefined,
              complement: !(value$21 == null) ? value$21 : undefined,
              zipcode: !(value$22 == null) ? value$22 : undefined,
              freeZipcode: !(value$23 == null) ? value$23 : undefined,
              freeCity: !(value$24 == null) ? value$24 : undefined,
              country: !(value$25 == null) ? value$25 : undefined,
              countryLabel: !(value$26 == null) ? value$26 : undefined,
              city: tmp
            },
            invoices: value$31.map(function (value) {
                  return {
                          __typename: value["__typename"],
                          id: value["id"],
                          invoiceFrag: ModalClient_InvoiceTr.InvoiceFrag.verifyArgsAndParse("InvoiceFrag", value)
                        };
                }),
            quotes: value$32.map(function (value) {
                  return {
                          __typename: value["__typename"],
                          id: value["id"],
                          quoteFrag: ModalClient_InvoiceTr.QuoteFrag.verifyArgsAndParse("QuoteFrag", value)
                        };
                })
          }
        };
}

function serialize(value) {
  var value$1 = value.client;
  var value$2 = value$1.quotes;
  var quotes = value$2.map(function (value) {
        var value$1 = value.id;
        var value$2 = value.__typename;
        return [ModalClient_InvoiceTr.QuoteFrag.serialize(value.quoteFrag)].reduce(GraphQL_PPX.deepMerge, {
                    __typename: value$2,
                    id: value$1
                  });
      });
  var value$3 = value$1.invoices;
  var invoices = value$3.map(function (value) {
        var value$1 = value.id;
        var value$2 = value.__typename;
        return [ModalClient_InvoiceTr.InvoiceFrag.serialize(value.invoiceFrag)].reduce(GraphQL_PPX.deepMerge, {
                    __typename: value$2,
                    id: value$1
                  });
      });
  var value$4 = value$1.address;
  var value$5 = value$4.city;
  var city;
  if (value$5 !== undefined) {
    var value$6 = value$5.county;
    var value$7 = value$6.region;
    var value$8 = value$7.country;
    var value$9 = value$8.isoCode;
    var value$10 = value$8.name;
    var value$11 = value$8.id;
    var value$12 = value$8.__typename;
    var country = {
      __typename: value$12,
      id: value$11,
      name: value$10,
      isoCode: value$9
    };
    var value$13 = value$7.name;
    var value$14 = value$7.id;
    var value$15 = value$7.__typename;
    var region = {
      __typename: value$15,
      id: value$14,
      name: value$13,
      country: country
    };
    var value$16 = value$6.name;
    var value$17 = value$6.id;
    var value$18 = value$6.__typename;
    var county = {
      __typename: value$18,
      id: value$17,
      name: value$16,
      region: region
    };
    var value$19 = value$5.name;
    var value$20 = value$5.id;
    var value$21 = value$5.__typename;
    city = {
      __typename: value$21,
      id: value$20,
      name: value$19,
      county: county
    };
  } else {
    city = null;
  }
  var value$22 = value$4.countryLabel;
  var countryLabel = value$22 !== undefined ? value$22 : null;
  var value$23 = value$4.country;
  var country$1 = value$23 !== undefined ? value$23 : null;
  var value$24 = value$4.freeCity;
  var freeCity = value$24 !== undefined ? value$24 : null;
  var value$25 = value$4.freeZipcode;
  var freeZipcode = value$25 !== undefined ? value$25 : null;
  var value$26 = value$4.zipcode;
  var zipcode = value$26 !== undefined ? value$26 : null;
  var value$27 = value$4.complement;
  var complement = value$27 !== undefined ? value$27 : null;
  var value$28 = value$4.preComplement;
  var preComplement = value$28 !== undefined ? value$28 : null;
  var value$29 = value$4.street;
  var street = value$29 !== undefined ? value$29 : null;
  var value$30 = value$4.__typename;
  var address = {
    __typename: value$30,
    street: street,
    preComplement: preComplement,
    complement: complement,
    zipcode: zipcode,
    freeZipcode: freeZipcode,
    freeCity: freeCity,
    country: country$1,
    countryLabel: countryLabel,
    city: city
  };
  var value$31 = value$1.contacts;
  var contacts = value$31.map(function (value) {
        var value$1 = value.email;
        var email = value$1 !== undefined ? value$1 : null;
        var value$2 = value.mobile;
        var mobile = value$2 !== undefined ? value$2 : null;
        var value$3 = value.tel;
        var tel = value$3 !== undefined ? value$3 : null;
        var value$4 = value.post;
        var post = value$4 !== undefined ? value$4 : null;
        var value$5 = value.firstname;
        var firstname = value$5 !== undefined ? value$5 : null;
        var value$6 = value.lastname;
        var lastname = value$6 !== undefined ? value$6 : null;
        var value$7 = value.gender;
        var gender = value$7 !== undefined ? value$7 : null;
        var value$8 = value.id;
        var value$9 = value.__typename;
        return {
                __typename: value$9,
                id: value$8,
                gender: gender,
                lastname: lastname,
                firstname: firstname,
                post: post,
                tel: tel,
                mobile: mobile,
                email: email
              };
      });
  var value$32 = value$1.quoteListUrl;
  var value$33 = value$1.invoiceListUrl;
  var value$34 = value$1.editUrl;
  var value$35 = value$1.intraCommunityVatNumber;
  var intraCommunityVatNumber = value$35 !== undefined ? value$35 : null;
  var value$36 = value$1.companyTypeTr;
  var companyTypeTr = value$36 !== undefined ? value$36 : null;
  var value$37 = value$1.companyType;
  var companyType = value$37 !== undefined ? value$37 : null;
  var value$38 = value$1.instagram;
  var instagram = value$38 !== undefined ? value$38 : null;
  var value$39 = value$1.facebook;
  var facebook = value$39 !== undefined ? value$39 : null;
  var value$40 = value$1.fax;
  var fax = value$40 !== undefined ? value$40 : null;
  var value$41 = value$1.tel2;
  var tel2 = value$41 !== undefined ? value$41 : null;
  var value$42 = value$1.tel1;
  var tel1 = value$42 !== undefined ? value$42 : null;
  var value$43 = value$1.emailCompta;
  var emailCompta = value$43 !== undefined ? value$43 : null;
  var value$44 = value$1.fromObservation;
  var fromObservation = value$44 !== undefined ? value$44 : null;
  var value$45 = value$1.from;
  var value$46 = value$1.status;
  var value$47 = value$1.observations;
  var observations = value$47 !== undefined ? value$47 : null;
  var value$48 = value$1.companyLogo;
  var companyLogo;
  if (value$48 !== undefined) {
    var value$49 = value$48.w500;
    var value$50 = value$48.referenceUrl;
    var value$51 = value$48.height;
    var value$52 = value$48.width;
    var value$53 = value$48.name;
    var value$54 = value$48.id;
    var value$55 = value$48.__typename;
    companyLogo = {
      __typename: value$55,
      id: value$54,
      name: value$53,
      width: value$52,
      height: value$51,
      referenceUrl: value$50,
      w500: value$49
    };
  } else {
    companyLogo = null;
  }
  var value$56 = value$1.companyCommonName;
  var companyCommonName = value$56 !== undefined ? value$56 : null;
  var value$57 = value$1.companyName;
  var companyName = value$57 !== undefined ? value$57 : null;
  var value$58 = value$1.invoiceName;
  var invoiceName = value$58 !== undefined ? value$58 : null;
  var value$59 = value$1.isCompany;
  var value$60 = value$1.id;
  var value$61 = value$1.__typename;
  var client = {
    __typename: value$61,
    id: value$60,
    isCompany: value$59,
    invoiceName: invoiceName,
    companyName: companyName,
    companyCommonName: companyCommonName,
    companyLogo: companyLogo,
    observations: observations,
    status: value$46,
    from: value$45,
    fromObservation: fromObservation,
    emailCompta: emailCompta,
    tel1: tel1,
    tel2: tel2,
    fax: fax,
    facebook: facebook,
    instagram: instagram,
    companyType: companyType,
    companyTypeTr: companyTypeTr,
    intraCommunityVatNumber: intraCommunityVatNumber,
    editUrl: value$34,
    invoiceListUrl: value$33,
    quoteListUrl: value$32,
    contacts: contacts,
    address: address,
    invoices: invoices,
    quotes: quotes
  };
  return {
          client: client
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

function makeVariables(id, param) {
  return {
          id: id
        };
}

var ClientQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var ClientQuery_refetchQueryDescription = include.refetchQueryDescription;

var ClientQuery_useLazy = include.useLazy;

var ClientQuery_useLazyWithVariables = include.useLazyWithVariables;

var ClientQuery = {
  ClientQuery_inner: ClientQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: ClientQuery_refetchQueryDescription,
  use: use,
  useLazy: ClientQuery_useLazy,
  useLazyWithVariables: ClientQuery_useLazyWithVariables
};

var separator = "h-px bg-gray-200 mt-3 mb-3";

function ModalClient$Contact(Props) {
  var contact = Props.contact;
  var match = contact.gender;
  var tmp;
  if (match !== undefined) {
    switch (match) {
      case "man" :
          tmp = "M ";
          break;
      case "woman" :
          tmp = "Mme ";
          break;
      default:
        tmp = "";
    }
  } else {
    tmp = "";
  }
  return React.createElement("div", undefined, React.createElement("dl", {
                  className: "dl-horizontal"
                }, React.createElement("dt", undefined, Utils_react.ste("Contact")), React.createElement("dd", undefined, Utils_react.ste(tmp), Utils_react.mapOrNull(contact.firstname, (function (v) {
                            return Utils_react.ste(v + " ");
                          })), Utils_react.mapOrNull(contact.lastname, Utils_react.ste)), Utils_react.mapOrNull(contact.post, (function (v) {
                        return React.createElement(React.Fragment, undefined, React.createElement("dt", undefined, Utils_react.ste("Poste")), React.createElement("dd", undefined, Utils_react.ste(v)));
                      })), Utils_react.mapOrNull(contact.email, (function (v) {
                        return React.createElement(React.Fragment, undefined, React.createElement("dt", undefined, Utils_react.ste("Email")), React.createElement("dd", undefined, Utils_react.ste(v)));
                      })), Utils_react.mapOrNull(contact.tel, (function (v) {
                        return React.createElement(React.Fragment, undefined, React.createElement("dt", undefined, Utils_react.ste("Téléphone")), React.createElement("dd", undefined, Utils_react.ste(v)));
                      })), Utils_react.mapOrNull(contact.mobile, (function (v) {
                        return React.createElement(React.Fragment, undefined, React.createElement("dt", undefined, Utils_react.ste("Mobile")), React.createElement("dd", undefined, Utils_react.ste(v)));
                      }))));
}

var Contact = {
  make: ModalClient$Contact
};

function ModalClient(Props) {
  var show = Props.show;
  var clientId = Props.clientId;
  var onClose = Props.onClose;
  var apolloData = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          id: clientId
        }
      ]);
  var match = apolloData.data;
  var error = apolloData.error;
  if (apolloData.loading) {
    return React.createElement(ModalLoadingJs, {
                show: show,
                onClose: onClose
              });
  }
  if (error !== undefined) {
    return React.createElement(ModalLoadingJs, {
                show: show,
                onClose: onClose,
                data: error
              });
  }
  if (match === undefined) {
    return React.createElement(ModalLoadingJs, {
                show: show,
                onClose: onClose,
                data: "Erreur inconnue"
              });
  }
  var client = match.client;
  var match$1 = client.status;
  var tmp = match$1 === "prospect" ? React.createElement("span", {
          className: "label label-primary"
        }, Utils_react.ste("Prospect")) : React.createElement("span", {
          className: "label label-success"
        }, Utils_react.ste("Client"));
  var match$2 = client.address;
  var city = match$2.city;
  var match$3 = client.tel1;
  var match$4 = client.tel2;
  var tmp$1;
  var exit = 0;
  if (match$3 !== undefined || match$4 !== undefined) {
    exit = 1;
  } else {
    tmp$1 = null;
  }
  if (exit === 1) {
    tmp$1 = React.createElement("div", {
          className: "flex-1 form-group"
        }, React.createElement("label", undefined, Utils_react.ste("Téléphone")), Utils_react.mapOrNull(client.tel1, (function (v) {
                return React.createElement("div", undefined, Utils_react.ste(v));
              })), Utils_react.mapOrNull(client.tel2, (function (v) {
                return React.createElement("div", undefined, Utils_react.ste(v));
              })));
  }
  return React.createElement(React_bootstrap.Modal.make, {
              onHide: onClose,
              show: show,
              children: null
            }, React.createElement(ModalHeader, {
                  children: React.createElement(ModalTitle, {
                        children: Utils_react.defste(client.invoiceName, "")
                      })
                }), React.createElement(ModalBody, {
                  children: null
                }, React.createElement("div", {
                      className: "flex flex-row"
                    }, React.createElement("div", {
                          className: "flex-1 form-group"
                        }, React.createElement("label", undefined, Utils_react.ste("ID")), React.createElement("div", undefined, Utils_react.ste(client.id.toString()))), React.createElement("div", {
                          className: "flex-1 form-group"
                        }, React.createElement("label", undefined, Utils_react.ste("Nom")), React.createElement("div", undefined, Utils_react.defste(client.invoiceName, ""))), React.createElement("div", {
                          className: "flex-1 form-group"
                        }, React.createElement("label", undefined, Utils_react.ste("Statut")), React.createElement("div", undefined, client.isCompany ? React.createElement("span", {
                                    className: "label label-primary"
                                  }, Utils_react.ste("Société")) : React.createElement("span", {
                                    className: "label label-success"
                                  }, Utils_react.ste("Particulier")))), React.createElement("div", {
                          className: "flex-1 form-group"
                        }, React.createElement("label", undefined, Utils_react.ste("Client ?")), React.createElement("div", undefined, tmp)), React.createElement("div", {
                          className: "flex-1 form-group"
                        }, React.createElement("label", undefined, Utils_react.ste("Nous connais de ?")), React.createElement("div", undefined, Utils_react.ste(client.from)), React.createElement("div", undefined, Utils_react.defste(client.fromObservation, "")))), client.isCompany ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                            className: separator
                          }), React.createElement("div", {
                            className: "flex flex-row"
                          }, React.createElement("div", {
                                className: "flex-1 form-group"
                              }, React.createElement("label", undefined, Utils_react.ste("Raison sociale (Nom de la société)")), React.createElement("div", undefined, Utils_react.defste(client.companyName, ""))), Utils_react.mapOrNull(client.companyCommonName, (function (companyCommonName) {
                                  return React.createElement("div", {
                                              className: "flex-1 form-group"
                                            }, React.createElement("label", undefined, Utils_react.ste("Nom d\'utilisation")), React.createElement("div", undefined, Utils_react.ste(companyCommonName)));
                                })))) : null, Utils_react.mapOrNull(client.companyLogo, (function (image) {
                        return React.createElement(React.Fragment, undefined, React.createElement("div", {
                                        className: separator
                                      }), React.createElement("div", {
                                        className: "flex flex-row"
                                      }, React.createElement("div", {
                                            className: "flex-1 form-group"
                                          }, React.createElement("label", undefined, Utils_react.ste("Logo de la société")), React.createElement("div", undefined, React.createElement($$Image.make, {
                                                    image: {
                                                      TAG: 1,
                                                      _0: image.id,
                                                      _1: "widen500",
                                                      [Symbol.for("name")]: "Id"
                                                    },
                                                    className: "w-32 h-auto"
                                                  })))));
                      })), Utils_react.mapOrNull(client.intraCommunityVatNumber, (function (intraCommunityVatNumber) {
                        return React.createElement(React.Fragment, undefined, React.createElement("div", {
                                        className: separator
                                      }), React.createElement("div", {
                                        className: "flex flex-row"
                                      }, React.createElement("div", {
                                            className: "flex-1 form-group"
                                          }, React.createElement("label", undefined, Utils_react.ste("N° de TVA Intracommunautaire")), React.createElement("div", undefined, Utils_react.ste(intraCommunityVatNumber)))));
                      })), React.createElement(React.Fragment, undefined, React.createElement("div", {
                          className: separator
                        }), React.createElement("div", {
                          className: "flex flex-row"
                        }, React.createElement("div", {
                              className: "flex-1 form-group"
                            }, React.createElement("label", undefined, Utils_react.ste("Adresse")), React.createElement("address", {
                                  className: "m-0"
                                }, Utils_react.mapOrNull(match$2.preComplement, (function (v) {
                                        return React.createElement(React.Fragment, undefined, Utils_react.ste(v), React.createElement("br", undefined));
                                      })), Utils_react.mapOrNull(match$2.street, (function (v) {
                                        return React.createElement(React.Fragment, undefined, Utils_react.ste(v), React.createElement("br", undefined));
                                      })), Utils_react.mapOrNull(match$2.complement, (function (v) {
                                        return React.createElement(React.Fragment, undefined, Utils_react.ste(v), React.createElement("br", undefined));
                                      })), React.createElement("strong", undefined, Utils_react.mapOrNull(match$2.zipcode, (function (v) {
                                            return React.createElement(React.Fragment, undefined, Utils_react.ste(v));
                                          })), Utils_react.mapOrNull(match$2.freeZipcode, (function (v) {
                                            return React.createElement(React.Fragment, undefined, Utils_react.ste(", (" + (v + ")")));
                                          })), Utils_react.mapOrNull(city, (function (city) {
                                            return Utils_react.ste(", " + city.name);
                                          })), Utils_react.mapOrNull(match$2.freeCity, (function (freeCity) {
                                            return Utils_react.ste(", " + freeCity);
                                          }))), Utils_react.mapOrNull(city, (function (city) {
                                        return React.createElement(React.Fragment, undefined, React.createElement("br", undefined), Utils_react.ste(city.county.region.name + (", " + city.county.name)));
                                      })), Utils_react.mapOrNull(match$2.countryLabel, (function (countryLabel) {
                                        return React.createElement(React.Fragment, undefined, React.createElement("br", undefined), Utils_react.ste(countryLabel));
                                      })))))), React.createElement("div", {
                      className: separator
                    }), React.createElement("div", {
                      className: "flex flex-row"
                    }, Utils_react.mapOrNull(client.emailCompta, (function (v) {
                            return React.createElement("div", {
                                        className: "flex-1 form-group"
                                      }, React.createElement("label", undefined, Utils_react.ste("Email")), React.createElement("div", undefined, Utils_react.ste(v)));
                          })), tmp$1, Utils_react.mapOrNull(client.fax, (function (v) {
                            return React.createElement("div", {
                                        className: "flex-1 form-group"
                                      }, React.createElement("label", undefined, Utils_react.ste("Fax")), React.createElement("div", undefined, Utils_react.ste(v)));
                          })), Utils_react.mapOrNull(client.facebook, (function (v) {
                            return React.createElement("div", {
                                        className: "flex-1 form-group"
                                      }, React.createElement("label", undefined, Utils_react.ste("Facebook")), React.createElement("div", undefined, Utils_react.ste(v)));
                          })), Utils_react.mapOrNull(client.instagram, (function (v) {
                            return React.createElement("div", {
                                        className: "flex-1 form-group"
                                      }, React.createElement("label", undefined, Utils_react.ste("Instagram")), React.createElement("div", undefined, Utils_react.ste(v)));
                          }))), Utils_react.mapOrNull(client.observations, (function (v) {
                        return React.createElement(React.Fragment, undefined, React.createElement("div", {
                                        className: separator
                                      }), React.createElement("div", {
                                        className: "flex flex-row"
                                      }, React.createElement("div", {
                                            className: "flex-1 form-group"
                                          }, React.createElement("label", undefined, Utils_react.ste("Observations")), React.createElement("div", undefined, Utils_react.ste(v)))));
                      })), client.contacts.length !== 0 ? React.createElement("div", {
                        className: "box box-default"
                      }, React.createElement("div", {
                            className: "box-header"
                          }, React.createElement("h5", {
                                className: "box-title"
                              }, Utils_react.ste("Contacts"))), React.createElement("div", {
                            className: "box-body"
                          }, Utils_react.ate(Belt_Array.map(client.contacts, (function (contact) {
                                      return React.createElement(ModalClient$Contact, {
                                                  contact: contact,
                                                  key: "contact-" + contact.id.toString()
                                                });
                                    }))))) : null, client.quotes.length !== 0 ? React.createElement("div", {
                        className: "box box-default"
                      }, React.createElement("div", {
                            className: "flex flex-row box-header"
                          }, React.createElement("div", {
                                className: "flex-1"
                              }, React.createElement("h5", {
                                    className: "box-title"
                                  }, Utils_react.ste("Devis"))), React.createElement("a", {
                                className: "btn btn-primary btn-xs",
                                href: client.quoteListUrl
                              }, React.createElement("i", {
                                    className: "fa fa-list mr-2"
                                  }), Utils_react.ste("liste des devis"))), React.createElement("div", {
                            className: "box-body no-padding"
                          }, React.createElement("table", {
                                className: "table"
                              }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, Utils_react.ste("Devis N°")), React.createElement("th", undefined, Utils_react.ste("Date")), React.createElement("th", undefined, Utils_react.ste("Total TTC")), React.createElement("th", undefined, Utils_react.ste("Actions")))), React.createElement("tbody", undefined, Utils_react.ate(Belt_Array.map(client.quotes, (function (quote) {
                                              return React.createElement(ModalClient_InvoiceTr.Quote.make, {
                                                          quote: quote.quoteFrag,
                                                          key: "quote-" + quote.id.toString()
                                                        });
                                            }))))))) : null, client.invoices.length !== 0 ? React.createElement("div", {
                        className: "box box-default"
                      }, React.createElement("div", {
                            className: "flex flex-row box-header"
                          }, React.createElement("div", {
                                className: "flex-1"
                              }, React.createElement("h5", {
                                    className: "box-title"
                                  }, Utils_react.ste("Factures"))), React.createElement("a", {
                                className: "btn btn-primary btn-xs",
                                href: client.quoteListUrl
                              }, React.createElement("i", {
                                    className: "fa fa-list mr-2"
                                  }), Utils_react.ste("liste des factures"))), React.createElement("div", {
                            className: "box-body no-padding"
                          }, React.createElement("table", {
                                className: "table"
                              }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, Utils_react.ste("Fact. N°")), React.createElement("th", undefined, Utils_react.ste("Type")), React.createElement("th", undefined, Utils_react.ste("Date")), React.createElement("th", undefined, Utils_react.ste("Total TTC")), React.createElement("th", undefined, Utils_react.ste("Actions")))), React.createElement("tbody", undefined, Utils_react.ate(Belt_Array.map(client.invoices, (function (invoice) {
                                              return React.createElement(ModalClient_InvoiceTr.Invoice.make, {
                                                          invoice: invoice.invoiceFrag,
                                                          key: "invoice-" + invoice.id.toString()
                                                        });
                                            }))))))) : null), React.createElement(ModalFooter, {
                  children: null
                }, React.createElement("a", {
                      className: "btn btn-default",
                      href: client.editUrl
                    }, Utils_react.ste("Modifier le client")), React.createElement("button", {
                      className: "btn btn-danger",
                      onClick: (function (param) {
                          return Utils_react.preventCallback(onClose, param);
                        })
                    }, Utils_react.ste("Fermer"))));
}

var QuoteFrag;

var InvoiceFrag;

var Modal;

var make = ModalClient;

export {
  QuoteFrag ,
  InvoiceFrag ,
  ClientQuery ,
  Modal ,
  separator ,
  Contact ,
  make ,
  
}
/* query Not a pure module */
