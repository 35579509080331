import jq from "jquery";
import lodash from "lodash";

export default class JqueryUtils {
    static declarePlugin(name, opts) {
        let {allowedMethods, valueMethods, defaultOptions, initCallback, reinitCallback} = opts;
        allowedMethods = allowedMethods != null ? allowedMethods : [];
        valueMethods = valueMethods != null ? valueMethods : [];
        defaultOptions = defaultOptions != null ? defaultOptions : {};
        reinitCallback = reinitCallback != null ? reinitCallback : null;

        if (initCallback == null) {
            throw `L'option initCallback est obligatoire pour initialiser le plugin ${name}`;
        }

        return (jq.fn[name] = function (...args) {
            let value = undefined;
            this.each(function () {
                let plugin;
                if (args.length === 0 || typeof args[0] === "object") {
                    opts = args.length === 0 ? {} : jq.extend({}, defaultOptions, args[0]);
                    if (reinitCallback != null) {
                        plugin = jq(this).data(name);
                        if (plugin != null) {
                            reinitCallback(this, plugin, opts);
                        } else {
                            initCallback(this, opts);
                        }
                    } else {
                        initCallback(this, opts);
                    }
                    return null;
                } else if (typeof args[0] === "string") {
                    const method = args[0];
                    plugin = jq(this).data(name);

                    if (false === lodash.includes(allowedMethods, method)) {
                        throw `Unknown method: ${method}`;
                    }
                    if (plugin == null) {
                        return;
                    }

                    if (true === lodash.includes(valueMethods, method)) {
                        value = plugin[method](...Array.from(args.slice(1) || []));
                        return false;
                    } else {
                        return plugin[method](...Array.from(args.slice(1) || []));
                    }
                } else {
                    throw `Invalid arguments to ${name} plugin: ${args}`;
                }
            });

            if (value === undefined) {
                return this;
            } else {
                return value;
            }
        });
    }

    static preInit($dom, namePlugin, plugin) {
        // destroy if called on an existing component
        if ($dom.data(namePlugin) != null) {
            $dom.data(namePlugin).destroy();
        }
        return $dom.data(namePlugin, plugin);
    }

    static postDestroy($dom, namePlugin, plugin) {
        $dom.off(`.${namePlugin}`);
        return $dom.data(namePlugin, null);
    }
}
