// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Numeral from "../../vendor/Numeral.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Utils_date from "../../utils/Utils_date.bs.js";
import * as Utils_react from "../../utils/Utils_react.bs.js";
import * as Utils_unicode from "../../utils/Utils_unicode.bs.js";
import * as React_bootstrap from "../../vendor/React_bootstrap.bs.js";
import * as GraphQLExtending from "../../Graphql/GraphQLExtending.bs.js";
import ModalBody from "react-bootstrap/lib/ModalBody";
import ModalTitle from "react-bootstrap/lib/ModalTitle";
import ModalFooter from "react-bootstrap/lib/ModalFooter";
import ModalHeader from "react-bootstrap/lib/ModalHeader";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import ModalLoadingJs from "netosoftcrm-js/Modal/ModalLoading.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  query PaymentsQuery($invoiceId: Int!)  {
    invoice: strictInvoice(id: $invoiceId)  {
      __typename
      id
      number
      totalPaid
      soldOut
      addPaymentUrl
      payments  {
        __typename
        id
        amount
        notes
        bordereau
        type
        typeTr
        date
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.invoice;
  var value$2 = value$1.payments;
  return {
          invoice: {
            __typename: value$1.__typename,
            id: value$1.id,
            number: value$1.number,
            totalPaid: value$1.totalPaid,
            soldOut: value$1.soldOut,
            addPaymentUrl: value$1.addPaymentUrl,
            payments: value$2.map(function (value) {
                  var value$1 = value.notes;
                  var value$2 = value.bordereau;
                  return {
                          __typename: value.__typename,
                          id: value.id,
                          amount: value.amount,
                          notes: !(value$1 == null) ? value$1 : undefined,
                          bordereau: !(value$2 == null) ? value$2 : undefined,
                          type: value.type,
                          typeTr: value.typeTr,
                          date: GraphQLExtending.$$Date.parse(value.date)
                        };
                })
          }
        };
}

function serialize(value) {
  var value$1 = value.invoice;
  var value$2 = value$1.payments;
  var payments = value$2.map(function (value) {
        var value$1 = value.date;
        var value$2 = GraphQLExtending.$$Date.serialize(value$1);
        var value$3 = value.typeTr;
        var value$4 = value.type;
        var value$5 = value.bordereau;
        var bordereau = value$5 !== undefined ? value$5 : null;
        var value$6 = value.notes;
        var notes = value$6 !== undefined ? value$6 : null;
        var value$7 = value.amount;
        var value$8 = value.id;
        var value$9 = value.__typename;
        return {
                __typename: value$9,
                id: value$8,
                amount: value$7,
                notes: notes,
                bordereau: bordereau,
                type: value$4,
                typeTr: value$3,
                date: value$2
              };
      });
  var value$3 = value$1.addPaymentUrl;
  var value$4 = value$1.soldOut;
  var value$5 = value$1.totalPaid;
  var value$6 = value$1.number;
  var value$7 = value$1.id;
  var value$8 = value$1.__typename;
  var invoice = {
    __typename: value$8,
    id: value$7,
    number: value$6,
    totalPaid: value$5,
    soldOut: value$4,
    addPaymentUrl: value$3,
    payments: payments
  };
  return {
          invoice: invoice
        };
}

function serializeVariables(inp) {
  return {
          invoiceId: inp.invoiceId
        };
}

function makeVariables(invoiceId, param) {
  return {
          invoiceId: invoiceId
        };
}

var PaymentsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var PaymentsQuery_refetchQueryDescription = include.refetchQueryDescription;

var PaymentsQuery_useLazy = include.useLazy;

var PaymentsQuery_useLazyWithVariables = include.useLazyWithVariables;

var PaymentsQuery = {
  PaymentsQuery_inner: PaymentsQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: PaymentsQuery_refetchQueryDescription,
  use: use,
  useLazy: PaymentsQuery_useLazy,
  useLazyWithVariables: PaymentsQuery_useLazyWithVariables
};

function ModalPayments(Props) {
  var show = Props.show;
  var onClose = Props.onClose;
  var invoiceId = Props.invoiceId;
  var canAddPayment = Props.canAddPayment;
  var apolloData = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          invoiceId: invoiceId
        }
      ]);
  var match = apolloData.data;
  var error = apolloData.error;
  if (apolloData.loading) {
    return React.createElement(ModalLoadingJs, {
                show: show,
                onClose: onClose
              });
  }
  if (error !== undefined) {
    return React.createElement(ModalLoadingJs, {
                show: show,
                onClose: onClose,
                data: error
              });
  }
  if (match === undefined) {
    return React.createElement(ModalLoadingJs, {
                show: show,
                onClose: onClose,
                data: "Facture introuvable"
              });
  }
  var invoice = match.invoice;
  return React.createElement(React_bootstrap.Modal.make, {
              onHide: onClose,
              show: show,
              children: null
            }, React.createElement(ModalHeader, {
                  children: React.createElement(ModalTitle, {
                        children: null
                      }, Utils_react.ste("Paiements sur la facture n°"), Utils_react.ite(invoice.number))
                }), React.createElement(ModalBody, {
                  children: null
                }, React.createElement("table", {
                      className: "table"
                    }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, Utils_react.ste("Montant")), React.createElement("th", undefined, Utils_react.ste("Modalité")), React.createElement("th", undefined, Utils_react.ste("Date")), React.createElement("th", undefined, Utils_react.ste("Bordereau")), React.createElement("th", undefined, Utils_react.ste("Notes")))), React.createElement("tbody", undefined, Belt_Array.map(invoice.payments, (function (payment) {
                                return React.createElement("tr", {
                                            key: "payment-" + payment.id
                                          }, React.createElement("td", undefined, Utils_react.ste(Numeral.formatAutoFloat(payment.amount) + (Utils_unicode.nbsp + "€"))), React.createElement("td", undefined, Utils_react.ste(payment.typeTr)), React.createElement("td", undefined, Utils_react.ste(Utils_date.formatLong(payment.date, "fr", "LL"))), React.createElement("td", undefined, Utils_react.defste(payment.bordereau, "")), React.createElement("td", undefined, Utils_react.defste(payment.notes, "")));
                              })))), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontSize(Css.pct(120)),
                            tl: /* [] */0
                          })
                    }, React.createElement("strong", undefined, Utils_react.ste("Total payé:")), React.createElement("span", undefined, Utils_react.ste(Numeral.formatAutoFloat(invoice.totalPaid) + (Utils_unicode.nbsp + "€"))))), React.createElement(ModalFooter, {
                  children: null
                }, React.createElement("div", {
                      className: "btn btn-default",
                      onClick: (function (param) {
                          return Utils_react.preventCallback(onClose, param);
                        })
                    }, Utils_react.ste("Fermer")), canAddPayment && invoice.soldOut === false ? React.createElement("a", {
                        className: "btn btn-primary",
                        href: invoice.addPaymentUrl
                      }, Utils_react.ste("Ajouter un paiement")) : null));
}

var Modal;

var defste = Utils_react.defste;

var ste = Utils_react.ste;

var ite = Utils_react.ite;

var preventCallback = Utils_react.preventCallback;

var nbsp = Utils_unicode.nbsp;

var make = ModalPayments;

var $$default = ModalPayments;

export {
  PaymentsQuery ,
  Modal ,
  defste ,
  ste ,
  ite ,
  preventCallback ,
  nbsp ,
  make ,
  $$default ,
  $$default as default,
  
}
/* query Not a pure module */
