// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Utils from "../utils/Utils.bs.js";
import * as React from "react";
import * as Js_null from "bs-platform/lib/es6/js_null.js";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import ReactSelect from "react-select";
import * as Form_Renderer from "./Form_Renderer.bs.js";
import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";
import * as Hook$ReasonForm from "@maarekj/reason-form/src/Hook.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/src/Wrap.bs.js";
import * as Helper$ReasonForm from "@maarekj/reason-form/src/Helper.bs.js";

function useMemo8(prim, prim$1) {
  return React.useMemo((function () {
                return Curry._1(prim, undefined);
              }), prim$1);
}

function useMemo9(prim, prim$1) {
  return React.useMemo((function () {
                return Curry._1(prim, undefined);
              }), prim$1);
}

function Form_ReactSelect$Optional(Props) {
  var wrap = Props.wrap;
  var classNameOpt = Props.className;
  var id = Props.id;
  var isClearableOpt = Props.isClearable;
  var placeholder = Props.placeholder;
  var choices = Props.choices;
  var field = Props.field;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var isClearable = isClearableOpt !== undefined ? isClearableOpt : true;
  var value = Hook$ReasonForm.useValue(wrap, field);
  var match = Hook$ReasonForm.useFormMeta(wrap);
  var match$1 = Hook$ReasonForm.useMeta(wrap, field);
  var key = match$1.key;
  var onFocus = React.useCallback((function (_event) {
          return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                        return Form$ReasonForm.focus(key, param);
                      }));
        }), [key]);
  var onBlur = React.useCallback((function (_event) {
          return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                        return Form$ReasonForm.blur(key, param);
                      }));
        }), [key]);
  var onChange = React.useMemo((function () {
          return function (param, param$1) {
            var setValue = function (param, param$1) {
              return Helper$ReasonForm.changeFieldValue(field, param, param$1);
            };
            var match = param$1.action;
            switch (match) {
              case "clear" :
                  return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                                return setValue(undefined, param);
                              }));
              case "select-option" :
                  var partial_arg = Caml_option.some(param.value);
                  return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                                return setValue(partial_arg, param);
                              }));
              default:
                return ;
            }
          };
        }), [
        wrap,
        field
      ]);
  var id$1;
  if (id !== undefined) {
    id$1 = id;
  } else {
    var prefixId = Wrap$ReasonForm.id(wrap);
    id$1 = Form_Renderer.normalizeId(prefixId + ("-" + key));
  }
  var className$1 = className + (" " + (
      (match.nbSubmits > 0 || match$1.isDirty) && match$1.hasError ? "is-invalid" : ""
    ));
  var options = React.useMemo((function () {
          return Belt_List.toArray(choices);
        }), [choices]);
  var valueChoice = Js_null.fromOption(React.useMemo((function () {
              return Belt_List.getByU(choices, (function (choice) {
                            if (value !== undefined) {
                              return Caml_obj.caml_equal(choice.value, Caml_option.valFromOption(value));
                            } else {
                              return false;
                            }
                          }));
            }), [
            choices,
            value
          ]));
  return React.useMemo((function () {
                console.log("render ReactSelect");
                var tmp = {
                  value: valueChoice,
                  options: options,
                  className: className$1,
                  inputId: id$1,
                  isClearable: isClearable,
                  onFocus: onFocus,
                  onBlur: onBlur,
                  onChange: onChange
                };
                if (placeholder !== undefined) {
                  tmp.placeholder = Caml_option.valFromOption(placeholder);
                }
                return React.createElement(ReactSelect, tmp);
              }), [
              id$1,
              isClearable,
              placeholder,
              className$1,
              valueChoice,
              onFocus,
              onBlur,
              onChange,
              options
            ]);
}

var Optional = {
  make: Form_ReactSelect$Optional
};

function Form_ReactSelect$Single(Props) {
  var wrap = Props.wrap;
  var classNameOpt = Props.className;
  var id = Props.id;
  var placeholder = Props.placeholder;
  var choices = Props.choices;
  var field = Props.field;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var value = Hook$ReasonForm.useValue(wrap, field);
  var match = Hook$ReasonForm.useFormMeta(wrap);
  var match$1 = Hook$ReasonForm.useMeta(wrap, field);
  var key = match$1.key;
  var onFocus = React.useCallback((function (_event) {
          return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                        return Form$ReasonForm.focus(key, param);
                      }));
        }), [key]);
  var onBlur = React.useCallback((function (_event) {
          return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                        return Form$ReasonForm.blur(key, param);
                      }));
        }), [key]);
  var onChange = React.useMemo((function () {
          return function (param, param$1) {
            var match = param$1.action;
            if (match !== "select-option") {
              return ;
            }
            var partial_arg = param.value;
            return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                          return Helper$ReasonForm.changeFieldValue(field, partial_arg, param);
                        }));
          };
        }), [
        wrap,
        field.key
      ]);
  var id$1;
  if (id !== undefined) {
    id$1 = id;
  } else {
    var prefixId = Wrap$ReasonForm.id(wrap);
    id$1 = Form_Renderer.normalizeId(prefixId + ("-" + key));
  }
  var className$1 = className + (" " + (
      (match.nbSubmits > 0 || match$1.isDirty) && match$1.hasError ? "is-invalid" : ""
    ));
  var options = React.useMemo((function () {
          return Belt_List.toArray(choices);
        }), [choices]);
  var valueChoice = Js_null.fromOption(React.useMemo((function () {
              return Belt_List.getByU(choices, (function (choice) {
                            return Caml_obj.caml_equal(choice.value, value);
                          }));
            }), [
            choices,
            value
          ]));
  return React.useMemo((function () {
                console.log("render ReactSelect");
                var tmp = {
                  value: valueChoice,
                  options: options,
                  className: className$1,
                  inputId: id$1,
                  onFocus: onFocus,
                  onBlur: onBlur,
                  onChange: onChange
                };
                if (placeholder !== undefined) {
                  tmp.placeholder = Caml_option.valFromOption(placeholder);
                }
                return React.createElement(ReactSelect, tmp);
              }), [
              id$1,
              placeholder,
              className$1,
              valueChoice,
              onFocus,
              onBlur,
              onChange,
              options
            ]);
}

var Single = {
  make: Form_ReactSelect$Single
};

function Form_ReactSelect$Multi(Props) {
  var wrap = Props.wrap;
  var classNameOpt = Props.className;
  var id = Props.id;
  var placeholder = Props.placeholder;
  var choices = Props.choices;
  var field = Props.field;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var values = Hook$ReasonForm.useValue(wrap, field);
  var match = Hook$ReasonForm.useFormMeta(wrap);
  var match$1 = Hook$ReasonForm.useMeta(wrap, field);
  var key = match$1.key;
  var onFocus = React.useCallback((function (_event) {
          return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                        return Form$ReasonForm.focus(key, param);
                      }));
        }), [key]);
  var onBlur = React.useCallback((function (_event) {
          return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                        return Form$ReasonForm.blur(key, param);
                      }));
        }), [key]);
  var onChange = React.useMemo((function () {
          return function (param, param$1) {
            console.log(param$1, param);
            var setValue = function (param, param$1) {
              return Helper$ReasonForm.changeFieldValue(field, param, param$1);
            };
            if (param == null) {
              return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                            return setValue(/* [] */0, param);
                          }));
            }
            var partial_arg = Belt_List.fromArray(Belt_Array.map(param, (function (c) {
                        return c.value;
                      })));
            return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                          return setValue(partial_arg, param);
                        }));
          };
        }), [
        wrap,
        field.key
      ]);
  var id$1;
  if (id !== undefined) {
    id$1 = id;
  } else {
    var prefixId = Wrap$ReasonForm.id(wrap);
    id$1 = Form_Renderer.normalizeId(prefixId + ("-" + key));
  }
  var className$1 = className + (" " + (
      (match.nbSubmits > 0 || match$1.isDirty) && match$1.hasError ? "is-invalid" : ""
    ));
  var options = React.useMemo((function () {
          return Belt_List.toArray(choices);
        }), [choices]);
  var valueChoice = React.useMemo((function () {
          return Belt_List.toArray(Belt_List.keepMap(Belt_List.map(values, (function (v) {
                                return Belt_List.getByU(choices, (function (c) {
                                              return Caml_obj.caml_equal(c.value, v);
                                            }));
                              })), Utils.id));
        }), [
        choices,
        values
      ]);
  return React.useMemo((function () {
                console.log("render ReactSelect");
                var tmp = {
                  value: valueChoice,
                  options: options,
                  className: className$1,
                  inputId: id$1,
                  isMulti: true,
                  onFocus: onFocus,
                  onBlur: onBlur,
                  onChange: onChange
                };
                if (placeholder !== undefined) {
                  tmp.placeholder = Caml_option.valFromOption(placeholder);
                }
                return React.createElement(ReactSelect, tmp);
              }), [
              id$1,
              placeholder,
              className$1,
              valueChoice,
              onFocus,
              onBlur,
              onChange,
              options
            ]);
}

var Multi = {
  make: Form_ReactSelect$Multi
};

var normalizeId = Form_Renderer.normalizeId;

export {
  useMemo8 ,
  useMemo9 ,
  normalizeId ,
  Optional ,
  Single ,
  Multi ,
  
}
/* react Not a pure module */
