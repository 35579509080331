// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils_option from "./Utils_option.bs.js";

function parse(str) {
  try {
    return Caml_option.some(JSON.parse(str));
  }
  catch (exn){
    return ;
  }
}

function get(key, dict) {
  return Js_dict.get(dict, key);
}

function getDef(key, def, dict) {
  return Utils_option.def(Js_dict.get(dict, key), def);
}

function getCastString(key, dict) {
  var value = Js_dict.get(dict, key);
  var valueNumber = Utils_option.flatMap(Js_json.decodeNumber, value);
  if (valueNumber !== undefined) {
    return String(valueNumber | 0);
  } else {
    return Utils_option.flatMap(Js_json.decodeString, value);
  }
}

function getBool(key, dict) {
  return Utils_option.flatMap(Js_json.decodeBoolean, Js_dict.get(dict, key));
}

function getString(key, dict) {
  return Utils_option.flatMap(Js_json.decodeString, Js_dict.get(dict, key));
}

function getNumber(key, dict) {
  return Utils_option.flatMap(Js_json.decodeNumber, Js_dict.get(dict, key));
}

function getInt(key, dict) {
  return Utils_option.map((function (prim) {
                return prim | 0;
              }), getNumber(key, dict));
}

function getArray(key, dict) {
  return Utils_option.flatMap(Js_json.decodeArray, Js_dict.get(dict, key));
}

function getNumberArray(key, dict) {
  return Utils_option.map((function (array) {
                return array.map(Js_json.decodeNumber);
              }), Utils_option.flatMap(Js_json.decodeArray, Js_dict.get(dict, key)));
}

function getStringArray(key, dict) {
  return Utils_option.map((function (array) {
                return array.map(Js_json.decodeString);
              }), Utils_option.flatMap(Js_json.decodeArray, Js_dict.get(dict, key)));
}

function getDict(key, dict) {
  return Utils_option.flatMap(Js_json.decodeObject, Js_dict.get(dict, key));
}

function mergeDict(a, b) {
  var empty = {};
  return Object.assign(Object.assign(empty, a), b);
}

export {
  parse ,
  get ,
  getDef ,
  getCastString ,
  getBool ,
  getString ,
  getNumber ,
  getInt ,
  getArray ,
  getNumberArray ,
  getStringArray ,
  getDict ,
  mergeDict ,
  
}
/* No side effect */
