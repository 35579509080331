import jq from "jquery";
import _ from "lodash";
import chart from "chart.js";
import onReady from "./src/onReady";
import CollectionType from "./src/collectionType";
import SonataAdmin, {main as sonataAdminMain} from "./SonataAdmin";

import numeral from "numeral";
import xModalForm from "netosoftdomain/js/xModalForm";
import renderFieldUrl from "netosoftdomain/js/renderFieldUrl";
import SelectorHelper from "./lib/SelectorHelper";
import {initializeFromConfig} from "./lib/reactUtils";
import ModalClientButton from "netosoftcrm-reason/component/ModalClient/ModalClientButton.bs.js";
import BbCode from "./BbCode/index";
import * as AdminRe from "netosoftcrm-reason/Admin.bs.js";
import SoldOutWidget from "netosoftcrm-reason/component/SoldOutWidget.bs.js";
import ModalPaymentsButton from "netosoftcrm-reason/component/ModalPayments/ModalPaymentsButton.bs.js";
import InvoiceLines from "netosoftcrm-reason/component/InvoiceLines/InvoiceLines.bs.js";
import SignaturePad from "netosoftcrm-reason/component/SignaturePad.bs.js";

import dashboard from "./dashboard";

sonataAdminMain();

numeral.register("locale", "fr", {
    delimiters: {
        thousands: " ",
        decimal: ",",
    },
    abbreviations: {
        thousand: "k",
        million: "m",
        billion: "b",
        trillion: "t",
    },
    ordinal: function (number) {
        return number === 1 ? "er" : "ème";
    },
    currency: {
        symbol: "€",
    },
});
numeral.locale("fr");

//
// Bootstrap Toggle
//
onReady.createAnonym(["jquery", "content-loaded", "x-modal-form"], ($parent) => {
    $parent.find("[data-toggle=tooltip]").tooltip();
    $parent.find("[data-toggle=popover]").popover();
});

onReady.createAnonym(["x-modal-form", "content-loaded", "company-extends"], ($dom) => {
    SonataAdmin.setup_select2($dom);
    SonataAdmin.setup_icheck($dom);
    SonataAdmin.setup_xeditable($dom);
    SonataAdmin.setup_collection_counter($dom);
});

onReady.createAnonym(["jquery", "x-modal-form"], ($dom) => {
    $dom.find("[data-toggle=x-modal-form]").each((i, element) => {
        const $target = jq(element);
        xModalForm($target, {
            onModalLoaded: ($modal) => {
                onReady.ready("content-loaded", $modal);
            },
            onSuccess: ({$target}) => {
                const $element = SelectorHelper.get().select($target.data("reload-selector"), {default: $target});
                const url = $target.data("reload-url");
                renderFieldUrl({
                    $element,
                    url,
                    onReady: ($element) => {
                        onReady.ready("x-modal-form", $element);
                    },
                });
            },
        });
    });
});

onReady.createAnonym(["jquery", "collection-item-added", "x-modal-form", "content-loaded"], ($dom) => {
    $dom.find('[data-toggle="app-collection-type"]').appCollectionType();
});

onReady.createAnonym(["jquery"], ($dom) => {
    $dom.find("[data-toggle=select-location]").each((i, e) => {
        const $e = jq(e);
        const ajaxUrl = $e.data("ajax-url");
        const placeholder = $e.data("placeholder");
        const locationType = $e.data("location-type");
        const initSelectionData = $e.data("init-selection-data");

        let config = {
            ajax: {
                url: ajaxUrl,
                delay: 250,
                data: function (term) {
                    return {
                        q: term,
                        type: locationType,
                    };
                },
                results: function (data) {
                    return {
                        results: data.items,
                    };
                },
            },
            width: "100%",
            allowClear: true,
            minimumInputLength: 1,
            placeholder,
        };

        if (initSelectionData != null) {
            config = {
                ...config,
                initSelection: function (element, callback) {
                    callback(initSelectionData);
                },
            };
        }

        $e.select2(config);
    });
});

onReady.createAnonym(["jquery"], ($dom) => {
    $dom.find("[data-toggle=toggle-filters]").on("click", (event) => {
        event.preventDefault();
        $dom.find(".sonata-filters").toggle();
    });

    if (window.innerWidth <= 1024) {
        $dom.find(".sonata-filters").hide();
    }
});

onReady.createAnonym(["jquery"], ($dom) => {
    initializeFromConfig({
        component: InvoiceLines,
        containers: $dom.find("[data-react=InvoiceLines]"),
    });

    initializeFromConfig({
        component: ModalClientButton,
        containers: $dom.find("[data-react=ModalClientButton]"),
    });

    initializeFromConfig({
        component: ModalPaymentsButton,
        containers: $dom.find("[data-react=ModalPaymentsButton"),
    });

    initializeFromConfig({
        component: SoldOutWidget,
        containers: $dom.find("[data-react=SoldOutWidget]"),
    });

    initializeFromConfig({
        component: SignaturePad,
        containers: $dom.find("[data-react=SignaturePad]"),
    });
});

onReady.createAnonym(["jquery"], ($dom) => {
    $dom.find("[data-toggle=client-command-widget]").each((i, element) => {
        const $elt = jq(element);
        const prototypes = $elt.data("prototypes");
        const isCompanySelector = $elt.data("is-company-selector");

        $elt.on("change", isCompanySelector, (event) => {
            const checked = _.parseInt(jq(event.target).val());
            if (checked === 0) {
                $elt.find("[data-js-select=company-extends]").html("");
            } else {
                $elt.find("[data-js-select=company-extends]").html(prototypes["company-extends"]);
                onReady.ready("company-extends", $elt.find("[data-js-select=company-extends]"));
            }
        });
    });
});

onReady.createAnonym(["jquery"], ($dom) => {
    $dom.on("click", "[data-form-target=_blank]", (event) => {
        const $button = jq(event.currentTarget);
        const $form = $button.closest("form");
        $form.attr("target", "_blank");

        setTimeout(() => {
            $form.attr("target", "");
        }, 0);
    });
});

onReady.createAnonym(["jquery"], ($dom) => {
    BbCode.BbCodeEdit.addPlugin(BbCode.plugins.ImagePlugin());

    const plugins = ["table", "text", "title", "list", "email", "url", "nbsp"];
    const fullPlugins = [...plugins, "image", "video", "post"];

    BbCode.BbCodeEdit.authorizePluginsForType(fullPlugins, "sheet_content");

    BbCode.BbCodeEdit.getPlugin("post")
        .addFramedStyle("light-gray")
        .addFramedStyle("dark-gray")
        .addFramedStyle("yellow")
        .addFramedStyle("blue")
        .addFramedStyle("green")
        .addFramedStyle("red")
        .addFramedStyle("orange");

    BbCode.BbCodeEdit.getPlugin("list")
        .addUlStyle("default")
        .addUlStyle("disk")
        .addUlStyle("circle")
        .addUlStyle("square");
});

onReady.createAnonym(["jquery", "collection-item-added"], ($dom) => {
    $dom.find('[data-toggle="bbCodeEdit"]').bbCodeEdit();
});

onReady.createAnonym(["jquery", "company-extends"], ($dom) => {
    AdminRe.main($dom.get(0));
});
