// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as SubformSyncWidget from "../../SubformSyncWidget.bs.js";
import * as InvoiceStyle_WinroForm from "./InvoiceStyle_WinroForm.bs.js";
import * as InvoiceStyle_WinroFields from "./InvoiceStyle_WinroFields.bs.js";

function InvoiceStyle_WinroWidget$Inner(Props) {
  var initialValues = Props.initialValues;
  var onChangeValue = Props.onChangeValue;
  var onBlur = Props.onBlur;
  var onFocus = Props.onFocus;
  var match = InvoiceStyle_WinroFields.useForm(initialValues, onChangeValue, onBlur, onFocus, undefined);
  return React.createElement(InvoiceStyle_WinroForm.make, {
              wrap: match[0],
              fields: match[1]
            });
}

var Inner = {
  make: InvoiceStyle_WinroWidget$Inner
};

function renderForm(initialValues, onChangeValue, onBlur, onFocus, param) {
  return React.createElement(InvoiceStyle_WinroWidget$Inner, {
              initialValues: initialValues,
              onChangeValue: onChangeValue,
              onBlur: onBlur,
              onFocus: onFocus
            });
}

function InvoiceStyle_WinroWidget(Props) {
  var wrap = Props.wrap;
  var field = Props.field;
  var transformers = Props.transformers;
  return React.createElement(SubformSyncWidget.make, {
              wrap: wrap,
              field: field,
              transformers: transformers,
              renderForm: renderForm,
              key: field.key
            });
}

var Fields;

var make = InvoiceStyle_WinroWidget;

export {
  Fields ,
  Inner ,
  renderForm ,
  make ,
  
}
/* react Not a pure module */
