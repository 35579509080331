const jq = require("jquery");
const _ = require("lodash");

const onReady = require("./src/onReady").default;

const colors = ["#ffd778", "#5cbcab", "#58a0c9", "#131b2a", "#ea6a00", "#50a193", "#d1e9e6", "#aeb6cb"];

onReady.createAnonym("jquery-dashboard-page", ($page) => {
    const $container = $page.find("#dashboard-sales-charts");
    const sales = $container.data("sales");
    if ($container.size() === 0 || sales == null) {
        return;
    }

    const years = _.keys(sales);

    let data = {
        labels: ["Jan.", "Fév.", "Mars", "Avr.", "Mai", "Juin", "Juil.", "Août", "Sept.", "Oct.", "Nov.", "Déc."],
        datasets: _.map(years, (year, i) => ({
            label: `${year}`,
            data: sales[year],
            backgroundColor: colors[i % colors.length],
        })),
    };

    const chart = new Chart($container.get(0), {
        type: "bar",
        data,
        options: {},
    });
});
