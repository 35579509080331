// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Fetch from "bs-fetch/src/Fetch.bs.js";
import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Utils_json from "../utils/Utils_json.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils_Decco from "../utils/Utils_Decco.bs.js";
import * as Utils_error from "../utils/Utils_error.bs.js";
import * as Utils_option from "../utils/Utils_option.bs.js";
import * as Utils_promise from "../utils/Utils_promise.bs.js";

function append(prim, prim$1, prim$2) {
  prim.append(prim$1, prim$2);
  
}

function contextToString(param) {
  return "tmp";
}

var _map = {"image":"image","video":"video","pdf":"pdf"};

function mediaTypeToJs(param) {
  return param;
}

function mediaTypeFromJs(param) {
  return _map[param];
}

function mediaType_encode(param) {
  return Utils_Decco.encodeWithToJs(mediaTypeToJs, param);
}

function mediaType_decode(param) {
  return Utils_Decco.decodeWithFromJs(mediaTypeFromJs, param);
}

function downloadFile(url) {
  return Utils_promise.mapOk(Utils_promise.flatMapOk(Utils_promise.convertResult(fetch(url, Fetch.RequestInit.make(/* Get */0, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined))), (function (res) {
                    return Utils_promise.convertResult(res.blob());
                  })), (function (blob) {
                return {
                        TAG: 0,
                        _0: blob,
                        [Symbol.for("name")]: "Ok"
                      };
              }));
}

function upload(type_Opt, context, file, param) {
  var type_ = type_Opt !== undefined ? type_Opt : "image";
  var file$1;
  file$1 = file.TAG === /* File */0 ? Utils_promise.resolveOk(file._0) : downloadFile(file._0);
  return Utils_promise.flatMapOk(file$1, (function (file) {
                var formData = new FormData();
                append(formData, "file", file);
                append(formData, "context", "tmp");
                formData.append("type", type_);
                return Utils_promise.mapError(Utils_promise.mapOk(Utils_promise.flatMapOk(Utils_promise.convertResult(fetch("/media/upload", Fetch.RequestInit.make(/* Post */2, undefined, Caml_option.some(formData), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined))), (function (res) {
                                      return Utils_promise.convertResult(res.json());
                                    })), (function (json) {
                                  var id = Utils_option.flatMap((function (param) {
                                          return Utils_json.getInt("id", param);
                                        }), Utils_option.flatMap((function (param) {
                                              return Utils_json.getDict("data", param);
                                            }), Js_json.decodeObject(json)));
                                  if (id !== undefined) {
                                    return {
                                            TAG: 0,
                                            _0: id,
                                            [Symbol.for("name")]: "Ok"
                                          };
                                  } else {
                                    return {
                                            TAG: 1,
                                            _0: {
                                              NAME: "exn",
                                              VAL: new Error(Utils_error.messageFromJsonStrict(json))
                                            },
                                            [Symbol.for("name")]: "Error"
                                          };
                                  }
                                })), (function (error) {
                              console.log(error);
                              return {
                                      TAG: 1,
                                      _0: error,
                                      [Symbol.for("name")]: "Error"
                                    };
                            }));
              }));
}

var mapOk = Utils_promise.mapOk;

var flatMapOk = Utils_promise.flatMapOk;

var mapError = Utils_promise.mapError;

var convertResult = Utils_promise.convertResult;

export {
  mapOk ,
  flatMapOk ,
  mapError ,
  convertResult ,
  append ,
  contextToString ,
  mediaTypeToJs ,
  mediaTypeFromJs ,
  mediaType_encode ,
  mediaType_decode ,
  downloadFile ,
  upload ,
  
}
/* No side effect */
