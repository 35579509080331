// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Belt_Result from "bs-platform/lib/es6/belt_Result.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function encodeWithToJs(toJs, x) {
  return Decco.stringToJson(Curry._1(toJs, x));
}

function decodeWithFromJs(fromJs, x) {
  return Belt_Result.flatMap(Belt_Result.map(Decco.stringFromJson(x), fromJs), (function (y) {
                if (y !== undefined) {
                  return {
                          TAG: 0,
                          _0: Caml_option.valFromOption(y),
                          [Symbol.for("name")]: "Ok"
                        };
                } else {
                  return Decco.error(undefined, "Unknown value", x);
                }
              }));
}

export {
  encodeWithToJs ,
  decodeWithFromJs ,
  
}
/* No side effect */
