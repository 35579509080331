// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "../../vendor/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as React from "react";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as Spinner from "../../Spinner.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils_react from "../../utils/Utils_react.bs.js";
import * as UseDebounce from "use-debounce";
import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";
import * as Hook$ReasonForm from "@maarekj/reason-form/src/Hook.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/src/Wrap.bs.js";
import ReactAutosizeTextarea from "react-autosize-textarea";
import * as BootstrapRender$ReasonForm from "@maarekj/reason-form/src/BootstrapRender.bs.js";
import Popover from "react-bootstrap/lib/Popover";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";

var Raw = {};

var query = (require("@apollo/client").gql`
  query MarkdownToHtmlQuery($markdown: String!)  {
    html: markdownToHtml(markdown: $markdown)
  }
`);

function parse(value) {
  return {
          html: value.html
        };
}

function serialize(value) {
  var value$1 = value.html;
  return {
          html: value$1
        };
}

function serializeVariables(inp) {
  return {
          markdown: inp.markdown
        };
}

function makeVariables(markdown, param) {
  return {
          markdown: markdown
        };
}

var MarkdownToHtmlQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var MarkdownToHtmlQuery_refetchQueryDescription = include.refetchQueryDescription;

var MarkdownToHtmlQuery_useLazy = include.useLazy;

var MarkdownToHtmlQuery_useLazyWithVariables = include.useLazyWithVariables;

var MarkdownToHtmlQuery = {
  MarkdownToHtmlQuery_inner: MarkdownToHtmlQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: MarkdownToHtmlQuery_refetchQueryDescription,
  use: use,
  useLazy: MarkdownToHtmlQuery_useLazy,
  useLazyWithVariables: MarkdownToHtmlQuery_useLazyWithVariables
};

function placeholder_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var label = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "label"), null));
  if (label.TAG === /* Ok */0) {
    var placeholder = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "placeholder"), null));
    if (placeholder.TAG === /* Ok */0) {
      var preview = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "preview"), null));
      if (preview.TAG === /* Ok */0) {
        return {
                TAG: 0,
                _0: {
                  label: label._0,
                  placeholder: placeholder._0,
                  preview: preview._0
                },
                [Symbol.for("name")]: "Ok"
              };
      }
      var e = preview._0;
      return {
              TAG: 1,
              _0: {
                path: ".preview" + e.path,
                message: e.message,
                value: e.value
              },
              [Symbol.for("name")]: "Error"
            };
    }
    var e$1 = placeholder._0;
    return {
            TAG: 1,
            _0: {
              path: ".placeholder" + e$1.path,
              message: e$1.message,
              value: e$1.value
            },
            [Symbol.for("name")]: "Error"
          };
  }
  var e$2 = label._0;
  return {
          TAG: 1,
          _0: {
            path: ".label" + e$2.path,
            message: e$2.message,
            value: e$2.value
          },
          [Symbol.for("name")]: "Error"
        };
}

function MarkdownInput$ToolbarItem(Props) {
  var label = Props.label;
  var icon = Props.icon;
  var preview = Props.preview;
  var textareaRef = Props.textareaRef;
  var action = Props.action;
  var button = React.createElement("button", {
        className: "btn btn-xs btn-default",
        onClick: Curry._1(Utils_react.preventCallback, (function (param) {
                Belt_Option.map(Caml_option.nullable_to_opt(textareaRef.current), (function (textarea) {
                        if (action.TAG === /* Insert */0) {
                          textarea.setRangeText(action._0, textarea.selectionStart, textarea.selectionStart);
                          return ;
                        }
                        textarea.setRangeText(action._0, textarea.selectionStart, textarea.selectionStart);
                        textarea.setRangeText(action._1, textarea.selectionEnd, textarea.selectionEnd);
                        
                      }));
                
              }))
      }, Utils_react.orNull(icon), Utils_react.orNull(label));
  if (preview !== undefined) {
    return React.createElement(OverlayTrigger, {
                trigger: ["hover"],
                placement: "top",
                overlay: React.createElement(Popover, {
                      id: "",
                      children: React.createElement("p", {
                            className: "overflow-x-auto"
                          }, Utils_react.ste(preview))
                    }),
                children: button
              });
  } else {
    return button;
  }
}

var ToolbarItem = {
  HtmlInputElement: undefined,
  make: MarkdownInput$ToolbarItem
};

function MarkdownInput$Toolbar(Props) {
  var textareaRef = Props.textareaRef;
  return React.createElement("div", {
              className: "flex flex-wrap gx-1 gy-2 mb-1"
            }, React.createElement("div", undefined, React.createElement(MarkdownInput$ToolbarItem, {
                      icon: React.createElement("i", {
                            className: "fa fa-bold"
                          }),
                      textareaRef: textareaRef,
                      action: {
                        TAG: 1,
                        _0: "**",
                        _1: "**",
                        [Symbol.for("name")]: "Wrap"
                      }
                    })), React.createElement("div", undefined, React.createElement(MarkdownInput$ToolbarItem, {
                      icon: React.createElement("i", {
                            className: "fa fa-italic"
                          }),
                      textareaRef: textareaRef,
                      action: {
                        TAG: 1,
                        _0: "*",
                        _1: "*",
                        [Symbol.for("name")]: "Wrap"
                      }
                    })), React.createElement("div", undefined, React.createElement(MarkdownInput$ToolbarItem, {
                      label: Utils_react.ste("h1"),
                      textareaRef: textareaRef,
                      action: {
                        TAG: 1,
                        _0: "# ",
                        _1: "",
                        [Symbol.for("name")]: "Wrap"
                      }
                    })), React.createElement("div", undefined, React.createElement(MarkdownInput$ToolbarItem, {
                      label: Utils_react.ste("h2"),
                      textareaRef: textareaRef,
                      action: {
                        TAG: 1,
                        _0: "## ",
                        _1: "",
                        [Symbol.for("name")]: "Wrap"
                      }
                    })), React.createElement("div", undefined, React.createElement(MarkdownInput$ToolbarItem, {
                      label: Utils_react.ste("h3"),
                      textareaRef: textareaRef,
                      action: {
                        TAG: 1,
                        _0: "### ",
                        _1: "",
                        [Symbol.for("name")]: "Wrap"
                      }
                    })), React.createElement("div", undefined, React.createElement(MarkdownInput$ToolbarItem, {
                      label: Utils_react.ste("h4"),
                      textareaRef: textareaRef,
                      action: {
                        TAG: 1,
                        _0: "#### ",
                        _1: "",
                        [Symbol.for("name")]: "Wrap"
                      }
                    })));
}

var Toolbar = {
  make: MarkdownInput$Toolbar
};

function MarkdownInput$PlaceholdersToolbar(Props) {
  var placeholders = Props.placeholders;
  var textareaRef = Props.textareaRef;
  if (Belt_List.length(placeholders) === 0) {
    return null;
  } else {
    return React.createElement("div", {
                className: "flex flex-wrap gx-1 gy-2 mb-2"
              }, Utils_react.lte(Belt_List.mapWithIndex(placeholders, (function (i, param) {
                          var tmp = {
                            label: Utils_react.ste(param.label),
                            textareaRef: textareaRef,
                            action: {
                              TAG: 0,
                              _0: param.placeholder,
                              [Symbol.for("name")]: "Insert"
                            }
                          };
                          if (param.preview !== undefined) {
                            tmp.preview = Caml_option.valFromOption(param.preview);
                          }
                          return React.createElement("div", {
                                      key: i.toString()
                                    }, React.createElement(MarkdownInput$ToolbarItem, tmp));
                        }))));
  }
}

var PlaceholdersToolbar = {
  make: MarkdownInput$PlaceholdersToolbar
};

function MarkdownInput$Controlled(Props) {
  var id = Props.id;
  var value = Props.value;
  var onChangeOpt = Props.onChange;
  var placeholdersOpt = Props.placeholders;
  var onFocusOpt = Props.onFocus;
  var onBlurOpt = Props.onBlur;
  var withToolbarOpt = Props.withToolbar;
  var withPreviewOpt = Props.withPreview;
  var rowsOpt = Props.rows;
  var onChange = onChangeOpt !== undefined ? onChangeOpt : (function (_value) {
        
      });
  var placeholders = placeholdersOpt !== undefined ? placeholdersOpt : /* [] */0;
  var onFocus = onFocusOpt !== undefined ? onFocusOpt : (function (param) {
        
      });
  var onBlur = onBlurOpt !== undefined ? onBlurOpt : (function (param) {
        
      });
  var withToolbar = withToolbarOpt !== undefined ? withToolbarOpt : true;
  var withPreview = withPreviewOpt !== undefined ? withPreviewOpt : true;
  var rows = rowsOpt !== undefined ? rowsOpt : 20;
  var onFocus$1 = function (_event) {
    return Curry._1(onFocus, undefined);
  };
  var onBlur$1 = function (_event) {
    return Curry._1(onBlur, undefined);
  };
  var onChangeFromEvent = function ($$event) {
    return Curry._1(onChange, Utils_react.getValue($$event));
  };
  var textareaRef = React.useRef(null);
  var match = UseDebounce.useDebounce(value, 250);
  var apollo = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* NoCache */4,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          markdown: match[0]
        }
      ]);
  var tmp = {
    ref: textareaRef,
    className: "w-full px-2",
    onFocus: onFocus$1,
    onBlur: onBlur$1,
    onChange: onChangeFromEvent,
    value: value,
    rows: rows
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  var tmp$1;
  if (withPreview) {
    var match$1 = apollo.data;
    var tmp$2;
    if (match$1 !== undefined) {
      var html = match$1.html;
      tmp$2 = apollo.loading ? React.createElement("div", {
              dangerouslySetInnerHTML: {
                __html: html
              }
            }) : React.createElement("div", {
              dangerouslySetInnerHTML: {
                __html: html
              }
            });
    } else {
      tmp$2 = apollo.loading ? React.createElement(Spinner.make, {
              className: "mx-auto"
            }) : Utils_react.ste("Erreur");
    }
    tmp$1 = React.createElement("div", {
          className: "hidden t:block t:w-1/2 p-4"
        }, tmp$2);
  } else {
    tmp$1 = null;
  }
  return React.createElement("div", {
              className: "flex items-start mt-2"
            }, React.createElement("div", {
                  className: Cn.$plus("w-full", Cn.on("t:w-1/2", withPreview))
                }, withToolbar ? React.createElement(MarkdownInput$Toolbar, {
                        textareaRef: textareaRef
                      }) : null, React.createElement(MarkdownInput$PlaceholdersToolbar, {
                      placeholders: placeholders,
                      textareaRef: textareaRef
                    }), React.createElement(ReactAutosizeTextarea, tmp)), tmp$1);
}

var Controlled = {
  make: MarkdownInput$Controlled
};

function MarkdownInput$Uncontrolled(Props) {
  var id = Props.id;
  var defaultValue = Props.defaultValue;
  var onChange = Props.onChange;
  var placeholders = Props.placeholders;
  var withToolbar = Props.withToolbar;
  var rows = Props.rows;
  var withPreview = Props.withPreview;
  var match = React.useState(function () {
        return defaultValue;
      });
  var setValue = match[1];
  var onChange$1 = function (value) {
    Curry._1(setValue, (function (param) {
            return value;
          }));
    return Curry._1(onChange, value);
  };
  var tmp = {
    value: match[0],
    onChange: onChange$1
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (placeholders !== undefined) {
    tmp.placeholders = Caml_option.valFromOption(placeholders);
  }
  if (withToolbar !== undefined) {
    tmp.withToolbar = Caml_option.valFromOption(withToolbar);
  }
  if (withPreview !== undefined) {
    tmp.withPreview = Caml_option.valFromOption(withPreview);
  }
  if (rows !== undefined) {
    tmp.rows = Caml_option.valFromOption(rows);
  }
  return React.createElement(MarkdownInput$Controlled, tmp);
}

var Uncontrolled = {
  make: MarkdownInput$Uncontrolled
};

function MarkdownInput$FormInput(Props) {
  var wrap = Props.wrap;
  var id = Props.id;
  var field = Props.field;
  var toText = Props.toText;
  var fromText = Props.fromText;
  var placeholders = Props.placeholders;
  var withToolbar = Props.withToolbar;
  var rows = Props.rows;
  var withPreview = Props.withPreview;
  var value = Hook$ReasonForm.useValue(wrap, field);
  var key = field.key;
  var id$1;
  if (id !== undefined) {
    id$1 = id;
  } else {
    var prefixId = Wrap$ReasonForm.id(wrap);
    id$1 = BootstrapRender$ReasonForm.normalizeId(prefixId + ("-" + key));
  }
  var onChange = React.useCallback((function (value) {
          return Wrap$ReasonForm.dispatch(wrap, (function (form) {
                        var value$1 = Curry._2(field.setValue, Curry._1(fromText, value), Form$ReasonForm.getValues(form));
                        return Form$ReasonForm.changeValues({
                                    hd: field.key,
                                    tl: /* [] */0
                                  }, value$1, form);
                      }));
        }), [
        wrap,
        field,
        fromText
      ]);
  var onFocus = React.useCallback((function (_event) {
          return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                        return Form$ReasonForm.focus(key, param);
                      }));
        }), [
        wrap,
        key
      ]);
  var onBlur = React.useCallback((function (_event) {
          return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                        return Form$ReasonForm.blur(key, param);
                      }));
        }), [
        wrap,
        key
      ]);
  return React.useMemo((function () {
                var tmp = {
                  id: id$1,
                  value: Curry._1(toText, value),
                  onChange: onChange,
                  onFocus: onFocus,
                  onBlur: onBlur
                };
                if (placeholders !== undefined) {
                  tmp.placeholders = Caml_option.valFromOption(placeholders);
                }
                if (withToolbar !== undefined) {
                  tmp.withToolbar = Caml_option.valFromOption(withToolbar);
                }
                if (withPreview !== undefined) {
                  tmp.withPreview = Caml_option.valFromOption(withPreview);
                }
                if (rows !== undefined) {
                  tmp.rows = Caml_option.valFromOption(rows);
                }
                return React.createElement(MarkdownInput$Controlled, tmp);
              }), [
              value,
              id$1,
              onChange,
              onFocus,
              onBlur,
              toText,
              placeholders,
              withToolbar,
              rows,
              withPreview
            ]);
}

var FormInput = {
  Form: undefined,
  Hook: undefined,
  Helper: undefined,
  Wrap: undefined,
  make: MarkdownInput$FormInput
};

function makeProps(prim, prim$1, prim$2, prim$3, prim$4, prim$5, prim$6, prim$7, prim$8, prim$9, prim$10) {
  var tmp = {
    wrap: prim,
    field: prim$2,
    toText: prim$3,
    fromText: prim$4
  };
  if (prim$1 !== undefined) {
    tmp.id = Caml_option.valFromOption(prim$1);
  }
  if (prim$5 !== undefined) {
    tmp.placeholders = Caml_option.valFromOption(prim$5);
  }
  if (prim$6 !== undefined) {
    tmp.withToolbar = Caml_option.valFromOption(prim$6);
  }
  if (prim$7 !== undefined) {
    tmp.rows = Caml_option.valFromOption(prim$7);
  }
  if (prim$8 !== undefined) {
    tmp.withPreview = Caml_option.valFromOption(prim$8);
  }
  if (prim$9 !== undefined) {
    tmp.key = Caml_option.valFromOption(prim$9);
  }
  return tmp;
}

var make = MarkdownInput$FormInput;

export {
  MarkdownToHtmlQuery ,
  placeholder_decode ,
  ToolbarItem ,
  Toolbar ,
  PlaceholdersToolbar ,
  Controlled ,
  Uncontrolled ,
  FormInput ,
  make ,
  makeProps ,
  
}
/* query Not a pure module */
