// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "@glennsl/bs-json/src/Json.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Utils from "../../utils/Utils.bs.js";
import * as React from "react";
import Jquery from "jquery";
import * as Numeral from "../../vendor/Numeral.bs.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as Utils_react from "../../utils/Utils_react.bs.js";
import * as InvoiceLineTr from "./InvoiceLineTr.bs.js";
import * as Utils_unicode from "../../utils/Utils_unicode.bs.js";
import * as InvoiceLines_Types from "./InvoiceLines_Types.bs.js";

var euro = Utils_react.ste(Utils_unicode.nbsp + "€");

function swap(lines, index, newIndex) {
  var temp = Belt_List.get(lines, newIndex);
  var current = Belt_List.get(lines, index);
  if (temp === undefined) {
    return lines;
  }
  if (current === undefined) {
    return lines;
  }
  var current$1 = Caml_option.valFromOption(current);
  var temp$1 = Caml_option.valFromOption(temp);
  return Belt_List.mapWithIndex(lines, (function (i, l) {
                if (i === newIndex) {
                  return current$1;
                } else if (i === index) {
                  return temp$1;
                } else {
                  return l;
                }
              }));
}

function InvoiceLines(Props) {
  var acts = Props.acts;
  var inputId = Props.inputId;
  var defaultVat = Props.defaultVat;
  var match = React.useState(function () {
        var input = Jquery(inputId);
        try {
          var json = Json.parseOrRaise(input.val());
          return Json_decode.list(InvoiceLines_Types.Value.fromJson, json);
        }
        catch (exn){
          return /* [] */0;
        }
      });
  var changeValue = match[1];
  var value = match[0];
  React.useEffect((function () {
          var input = Jquery(inputId);
          var v = Json_encode.list(InvoiceLines_Types.Value.toJson, value);
          input.val(Json.stringify(v));
          
        }), [
        value,
        inputId
      ]);
  return React.createElement("div", undefined, React.createElement("table", {
                  className: "table table-striped"
                }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined), React.createElement("th", undefined, Utils_react.ste("Description")), React.createElement("th", undefined, Utils_react.ste("P.U. HT")), React.createElement("th", undefined, Utils_react.ste("Quantité")), React.createElement("th", undefined, Utils_react.ste("TVA")), React.createElement("th", undefined, Utils_react.ste("Coupure de page")))), React.createElement("tbody", undefined, Utils_react.lte(Belt_List.mapWithIndex(value, (function (index, line) {
                                return React.createElement(InvoiceLineTr.make, {
                                            value: line,
                                            onChange: (function (line) {
                                                return Curry._1(changeValue, (function (lines) {
                                                              return Belt_List.mapWithIndex(lines, (function (i, l) {
                                                                            if (i !== index) {
                                                                              return l;
                                                                            } else {
                                                                              return line;
                                                                            }
                                                                          }));
                                                            }));
                                              }),
                                            onRemoveClick: (function (param) {
                                                return Curry._1(changeValue, (function (lines) {
                                                              return Belt_List.keepWithIndex(lines, (function (param, i) {
                                                                            return i !== index;
                                                                          }));
                                                            }));
                                              }),
                                            onUpClick: (function (param) {
                                                return Curry._1(changeValue, (function (lines) {
                                                              return swap(lines, index, index - 1 | 0);
                                                            }));
                                              }),
                                            onDownClick: (function (param) {
                                                return Curry._1(changeValue, (function (lines) {
                                                              return swap(lines, index, index + 1 | 0);
                                                            }));
                                              }),
                                            acts: acts,
                                            key: "line" + index.toString()
                                          });
                              })))), React.createElement("tfoot", undefined, React.createElement("tr", undefined, React.createElement("td", {
                              colSpan: 6
                            }, React.createElement("button", {
                                  className: "btn btn-primary",
                                  onClick: Curry._1(Utils_react.preventCallback, (function (param) {
                                          return Curry._1(changeValue, (function (lines) {
                                                        return Belt_List.concat(lines, {
                                                                    hd: InvoiceLines_Types.Value.make(undefined, undefined, undefined, undefined, undefined, defaultVat, undefined),
                                                                    tl: /* [] */0
                                                                  });
                                                      }));
                                        }))
                                }, React.createElement("i", {
                                      className: "fa fa-plus"
                                    })))), React.createElement("tr", undefined, React.createElement("td", {
                              className: "text-right font-bold",
                              colSpan: 2
                            }, Utils_react.ste("Total HT")), React.createElement("td", {
                              className: "text-right font-bold",
                              colSpan: 3
                            }, Utils_react.ste(Numeral.formatAutoFloat(Belt_List.reduce(Belt_List.map(value, (function (line) {
                                                return Utils.def(Pervasives.float_of_string_opt(line.quantity), 0.0) * Utils.def(Pervasives.float_of_string_opt(line.unitPriceBeforeTax), 0.0);
                                              })), 0.0, (function (prim, prim$1) {
                                            return prim + prim$1;
                                          })))), euro), React.createElement("td", undefined)), React.createElement("tr", undefined, React.createElement("td", {
                              className: "text-right font-bold",
                              colSpan: 2
                            }, Utils_react.ste("TVA")), React.createElement("td", {
                              className: "text-right font-bold",
                              colSpan: 3
                            }, Utils_react.ste(Numeral.formatAutoFloat(Belt_List.reduce(Belt_List.map(value, (function (line) {
                                                return Utils.def(Pervasives.float_of_string_opt(line.quantity), 0.0) * Utils.def(Pervasives.float_of_string_opt(line.unitPriceBeforeTax), 0.0) * (Utils.def(Pervasives.float_of_string_opt(line.vat), 0.0) / 100);
                                              })), 0.0, (function (prim, prim$1) {
                                            return prim + prim$1;
                                          })))), euro), React.createElement("td", undefined)), React.createElement("tr", undefined, React.createElement("td", {
                              className: "text-right font-bold",
                              colSpan: 2
                            }, Utils_react.ste("Total")), React.createElement("td", {
                              className: "text-right font-bold",
                              colSpan: 3
                            }, Utils_react.ste(Numeral.formatAutoFloat(Belt_List.reduce(Belt_List.map(value, (function (line) {
                                                return Utils.def(Pervasives.float_of_string_opt(line.quantity), 0.0) * Utils.def(Pervasives.float_of_string_opt(line.unitPriceBeforeTax), 0.0) * (1 + Utils.def(Pervasives.float_of_string_opt(line.vat), 0.0) / 100);
                                              })), 0.0, (function (prim, prim$1) {
                                            return prim + prim$1;
                                          })))), euro), React.createElement("td", undefined)))));
}

var make = InvoiceLines;

var $$default = InvoiceLines;

export {
  euro ,
  swap ,
  make ,
  $$default ,
  $$default as default,
  
}
/* euro Not a pure module */
