// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ButtonModal from "../Modal/ButtonModal.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ModalPayments from "./ModalPayments.bs.js";

function ModalPaymentsButton(Props) {
  var className = Props.className;
  var icon = Props.icon;
  var label = Props.label;
  var invoiceId = Props.invoiceId;
  var canAddPayment = Props.canAddPayment;
  var tmp = {
    className: className,
    label: label,
    renderModal: (function (modal) {
        return React.createElement(ModalPayments.make, {
                    show: modal.value,
                    onClose: modal.hide,
                    invoiceId: invoiceId,
                    canAddPayment: canAddPayment
                  });
      })
  };
  if (icon !== undefined) {
    tmp.icon = Caml_option.valFromOption(icon);
  }
  return React.createElement(ButtonModal.make, tmp);
}

var make = ModalPaymentsButton;

var $$default = ModalPaymentsButton;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
