// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Form_Renderer from "../../../Form/Form_Renderer.bs.js";

function InvoiceStyle_ColdraForm(Props) {
  var wrap = Props.wrap;
  var fields = Props.fields;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex gx-gutter"
                }, React.createElement(Form_Renderer.Row.make, {
                      label: "Couleur principale",
                      className: "form-group flex-1",
                      input: React.createElement(Form_Renderer.Input.make, {
                            wrap: wrap,
                            type_: "color",
                            toText: Form_Renderer.optionToText,
                            fromText: Form_Renderer.optionFromText,
                            field: fields.mainColor
                          }),
                      wrap: wrap,
                      field: fields.mainColor
                    }), React.createElement(Form_Renderer.Row.make, {
                      label: "Couleur de la bordure (1)",
                      className: "form-group flex-1",
                      input: React.createElement(Form_Renderer.Input.make, {
                            wrap: wrap,
                            type_: "color",
                            toText: Form_Renderer.optionToText,
                            fromText: Form_Renderer.optionFromText,
                            field: fields.border1Color
                          }),
                      wrap: wrap,
                      field: fields.border1Color
                    }), React.createElement(Form_Renderer.Row.make, {
                      label: "Couleur de la bordure (2)",
                      className: "form-group flex-1",
                      input: React.createElement(Form_Renderer.Input.make, {
                            wrap: wrap,
                            type_: "color",
                            toText: Form_Renderer.optionToText,
                            fromText: Form_Renderer.optionFromText,
                            field: fields.border2Color
                          }),
                      wrap: wrap,
                      field: fields.border2Color
                    })), React.createElement("div", {
                  className: "flex gx-gutter"
                }, React.createElement(Form_Renderer.Row.make, {
                      label: "Texte en haut à gauche",
                      className: "form-group flex-1",
                      input: React.createElement(Form_Renderer.Textarea.make, {
                            wrap: wrap,
                            toText: Form_Renderer.optionToText,
                            fromText: Form_Renderer.optionFromText,
                            field: fields.topLeft
                          }),
                      wrap: wrap,
                      field: fields.topLeft
                    }), React.createElement(Form_Renderer.Row.make, {
                      label: "Texte en haut au milieu",
                      className: "form-group flex-1",
                      input: React.createElement(Form_Renderer.Textarea.make, {
                            wrap: wrap,
                            toText: Form_Renderer.optionToText,
                            fromText: Form_Renderer.optionFromText,
                            field: fields.topMiddle
                          }),
                      wrap: wrap,
                      field: fields.topMiddle
                    }), React.createElement(Form_Renderer.Row.make, {
                      label: "Texte en haut à droite",
                      className: "form-group flex-1",
                      input: React.createElement(Form_Renderer.Textarea.make, {
                            wrap: wrap,
                            toText: Form_Renderer.optionToText,
                            fromText: Form_Renderer.optionFromText,
                            field: fields.topRight
                          }),
                      wrap: wrap,
                      field: fields.topRight
                    })), React.createElement(Form_Renderer.Row.make, {
                  label: "Texte en bas (au dessus du footer)",
                  input: React.createElement(Form_Renderer.Textarea.make, {
                        wrap: wrap,
                        toText: Form_Renderer.optionToText,
                        fromText: Form_Renderer.optionFromText,
                        field: fields.bottom
                      }),
                  wrap: wrap,
                  field: fields.bottom
                }), React.createElement(Form_Renderer.Row.make, {
                  label: "Texte du footer",
                  input: React.createElement(Form_Renderer.Textarea.make, {
                        wrap: wrap,
                        toText: Form_Renderer.optionToText,
                        fromText: Form_Renderer.optionFromText,
                        field: fields.footer
                      }),
                  wrap: wrap,
                  field: fields.footer
                }));
}

var Fields;

var make = InvoiceStyle_ColdraForm;

export {
  Fields ,
  make ,
  
}
/* react Not a pure module */
