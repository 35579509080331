// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "./vendor/Cn.bs.js";
import * as React from "react";

function Spinner(Props) {
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : Cn.none;
  return React.createElement("div", {
              className: Cn.$plus("spinner-border", className)
            }, React.createElement("span", {
                  className: "sr-only"
                }, "Chargement..."));
}

var make = Spinner;

export {
  make ,
  
}
/* react Not a pure module */
