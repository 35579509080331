// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function flatDef(def, option) {
  if (option !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(option));
  } else {
    return def;
  }
}

function lazyDef(fdef, option) {
  if (option !== undefined) {
    return Caml_option.valFromOption(option);
  } else {
    return Curry._1(fdef, undefined);
  }
}

function map(f, x) {
  if (x !== undefined) {
    return Caml_option.some(Curry._1(f, Caml_option.valFromOption(x)));
  }
  
}

function flatMap(f, x) {
  var match = map(f, x);
  if (match === undefined) {
    return ;
  }
  var x$1 = Caml_option.valFromOption(match);
  if (x$1 !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(x$1));
  }
  
}

function flatMap2(f, x) {
  if (x === undefined) {
    return ;
  }
  var match = Curry._1(f, Caml_option.valFromOption(x));
  var a = match[0];
  if (a === undefined) {
    return ;
  }
  var b = match[1];
  if (b !== undefined) {
    return [
            Caml_option.valFromOption(a),
            Caml_option.valFromOption(b)
          ];
  }
  
}

function flatMap3(f, x) {
  if (x === undefined) {
    return ;
  }
  var match = Curry._1(f, Caml_option.valFromOption(x));
  var a = match[0];
  if (a === undefined) {
    return ;
  }
  var b = match[1];
  if (b === undefined) {
    return ;
  }
  var c = match[2];
  if (c !== undefined) {
    return [
            Caml_option.valFromOption(a),
            Caml_option.valFromOption(b),
            Caml_option.valFromOption(c)
          ];
  }
  
}

function defMap(f, x) {
  if (x !== undefined) {
    return x;
  } else {
    return Curry._1(f, undefined);
  }
}

function let_(a, b) {
  if (a !== undefined) {
    return Curry._1(b, Caml_option.valFromOption(a));
  }
  
}

var Opt = {
  let_: let_
};

function let_$1(x, f) {
  return map(f, x);
}

var $$Map = {
  let_: let_$1
};

function let_$2(x, f) {
  return flatMap(f, x);
}

var FlatMap = {
  let_: let_$2
};

function fromResult(res) {
  if (res.TAG === /* Ok */0) {
    return Caml_option.some(res._0);
  }
  
}

var def = Belt_Option.getWithDefault;

export {
  def ,
  flatDef ,
  lazyDef ,
  map ,
  flatMap ,
  flatMap2 ,
  flatMap3 ,
  defMap ,
  Opt ,
  $$Map ,
  FlatMap ,
  fromResult ,
  
}
/* No side effect */
