// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Validator from "../../validator/validator.bs.js";
import * as Form$ReasonForm from "@maarekj/reason-form/src/Form.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/src/Wrap.bs.js";
import * as Field$ReasonForm from "@maarekj/reason-form/src/Field.bs.js";

function make(emailOpt, subjectOpt, bodyOpt, param) {
  var email = emailOpt !== undefined ? emailOpt : "";
  var subject = subjectOpt !== undefined ? subjectOpt : "";
  var body = bodyOpt !== undefined ? bodyOpt : "";
  return {
          email: email,
          subject: subject,
          body: body
        };
}

var Value = {
  make: make
};

function createFields(self, baseField) {
  return {
          self: self,
          email: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("email", (function (param) {
                      return param.email;
                    }), (function (email, v) {
                      return {
                              email: email,
                              subject: v.subject,
                              body: v.body
                            };
                    }))),
          subject: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("subject", (function (param) {
                      return param.subject;
                    }), (function (subject, v) {
                      return {
                              email: v.email,
                              subject: subject,
                              body: v.body
                            };
                    }))),
          body: Field$ReasonForm.$plus$pipe$great(baseField, Field$ReasonForm.createField("body", (function (param) {
                      return param.body;
                    }), (function (body, v) {
                      return {
                              email: v.email,
                              subject: v.subject,
                              body: body
                            };
                    })))
        };
}

function validate(fields, form) {
  var value = Form$ReasonForm.getValues(form);
  return Validator.stringIsRequired(value.body, fields.body, undefined, undefined, Validator.stringIsRequired(value.subject, fields.subject, undefined, undefined, Validator.isEmail(value.email, fields.email, undefined, undefined, Validator.stringIsRequired(value.email, fields.email, undefined, undefined, form))));
}

function useForm(initialValues) {
  return React.useMemo((function () {
                var rootField = Field$ReasonForm.idField("root");
                var fields = createFields(rootField, rootField);
                var form = Form$ReasonForm.initializeForm(initialValues, undefined, undefined, undefined, undefined, undefined, (function (param) {
                        return validate(fields, param);
                      }), undefined);
                var wrap = Wrap$ReasonForm.make(undefined, form);
                return [
                        wrap,
                        fields
                      ];
              }), []);
}

export {
  Value ,
  createFields ,
  validate ,
  useForm ,
  
}
/* react Not a pure module */
