// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Form_Choice from "./Form_Choice.bs.js";
import * as Utils_react from "../utils/Utils_react.bs.js";
import * as Form_ReactSelect from "./Form_ReactSelect.bs.js";

function Form_YesNo$Optional(Props) {
  var wrap = Props.wrap;
  var className = Props.className;
  var id = Props.id;
  var placeholderLabelOpt = Props.placeholderLabel;
  var yesLabelOpt = Props.yesLabel;
  var noLabelOpt = Props.noLabel;
  var field = Props.field;
  var placeholderLabel = placeholderLabelOpt !== undefined ? placeholderLabelOpt : "";
  var yesLabel = yesLabelOpt !== undefined ? yesLabelOpt : "Oui";
  var noLabel = noLabelOpt !== undefined ? noLabelOpt : "Non";
  var choices = React.useMemo((function () {
          return {
                  hd: {
                    value: true,
                    label: yesLabel
                  },
                  tl: {
                    hd: {
                      value: false,
                      label: noLabel
                    },
                    tl: /* [] */0
                  }
                };
        }), [
        yesLabel,
        noLabel,
        placeholderLabel
      ]);
  var tmp = {
    wrap: wrap,
    choices: choices,
    field: field
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement(Form_ReactSelect.Optional.make, tmp);
}

var Optional = {
  make: Form_YesNo$Optional
};

function Form_YesNo$Required(Props) {
  var wrap = Props.wrap;
  var className = Props.className;
  var id = Props.id;
  var yesLabelOpt = Props.yesLabel;
  var noLabelOpt = Props.noLabel;
  var field = Props.field;
  var yesLabel = yesLabelOpt !== undefined ? yesLabelOpt : "Oui";
  var noLabel = noLabelOpt !== undefined ? noLabelOpt : "Non";
  var choices = React.useMemo((function () {
          return {
                  hd: {
                    value: true,
                    string: "yes",
                    label: Utils_react.ste(yesLabel)
                  },
                  tl: {
                    hd: {
                      value: false,
                      string: "no",
                      label: Utils_react.ste(noLabel)
                    },
                    tl: /* [] */0
                  }
                };
        }), [
        yesLabel,
        noLabel
      ]);
  var tmp = {
    wrap: wrap,
    field: field,
    choices: choices
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  return React.createElement(Form_Choice.Radio.make, tmp);
}

var Required = {
  make: Form_YesNo$Required
};

export {
  Optional ,
  Required ,
  
}
/* react Not a pure module */
