// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "../../vendor/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Utils_react from "../../utils/Utils_react.bs.js";

function ButtonModal(Props) {
  var className = Props.className;
  var icon = Props.icon;
  var label = Props.label;
  var renderModal = Props.renderModal;
  var modal = Utils_react.useToggleShow(false);
  var match = React.useState(function () {
        return true;
      });
  var setFirst = match[1];
  var show = React.useCallback((function (param) {
          Curry._1(modal.show, undefined);
          return Curry._1(setFirst, (function (param) {
                        return false;
                      }));
        }), [
        modal.show,
        setFirst
      ]);
  var showWithPrevent = Utils_react.usePreventCallback(show);
  var modal$1 = React.useMemo((function () {
          return {
                  value: modal.value,
                  toggle: modal.toggle,
                  toggleWithPrevent: modal.toggleWithPrevent,
                  show: show,
                  showWithPrevent: showWithPrevent,
                  hide: modal.hide,
                  hideWithPrevent: modal.hideWithPrevent
                };
        }), [
        modal,
        show,
        showWithPrevent
      ]);
  return React.createElement(React.Fragment, undefined, React.createElement("button", {
                  className: className,
                  onClick: modal$1.showWithPrevent
                }, Utils_react.mapOrNull(icon, (function (icon) {
                        return React.createElement("i", {
                                    className: Cn.$plus(icon, "mr-1")
                                  });
                      })), label), match[0] === false ? Curry._1(renderModal, modal$1) : null);
}

var mapOrNull = Utils_react.mapOrNull;

var usePreventCallback = Utils_react.usePreventCallback;

var useToggleShow = Utils_react.useToggleShow;

var make = ButtonModal;

export {
  mapOrNull ,
  usePreventCallback ,
  useToggleShow ,
  make ,
  
}
/* react Not a pure module */
