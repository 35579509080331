// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function getFiles(dom) {
  var files = dom.files;
  if (files == null) {
    return [];
  } else {
    return files;
  }
}

function getFirstFile(dom) {
  var files = getFiles(dom);
  if (files.length !== 0) {
    return Caml_option.some(Caml_array.get(files, 0));
  }
  
}

var $$File = {
  getFiles: getFiles,
  getFirstFile: getFirstFile
};

var $$FormData = {};

export {
  $$File ,
  $$FormData ,
  
}
/* No side effect */
