// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import Join from "lodash/join";
import * as Utils_promise from "./utils/Utils_promise.bs.js";

function wrapError(result) {
  if (result.TAG === /* Ok */0) {
    return {
            TAG: 0,
            _0: result._0,
            [Symbol.for("name")]: "Ok"
          };
  } else {
    return {
            TAG: 1,
            _0: {
              NAME: "apollo",
              VAL: result._0
            },
            [Symbol.for("name")]: "Error"
          };
  }
}

function promiseWrapError(p) {
  return Utils_promise.map(p, wrapError);
}

function promiseMapData(p) {
  return Utils_promise.mapOk(p, (function (result) {
                return {
                        TAG: 0,
                        _0: result.data,
                        [Symbol.for("name")]: "Ok"
                      };
              }));
}

function getErrorMessage(param) {
  var networkError = param.networkError;
  var errors = Belt_Array.map(param.graphQLErrors, (function (error) {
          return error.message;
        }));
  var tmp;
  if (networkError !== undefined) {
    switch (networkError.TAG | 0) {
      case /* FetchFailure */0 :
          tmp = [networkError._0.message];
          break;
      case /* BadStatus */1 :
          tmp = [networkError._1.message];
          break;
      case /* BadBody */2 :
          tmp = [networkError._0.message];
          break;
      case /* ParseError */3 :
          tmp = [networkError._0.error.message];
          break;
      
    }
  } else {
    tmp = [];
  }
  var errors$1 = Belt_Array.concat(errors, tmp);
  var errors$2 = Belt_Array.concat(errors$1, [param.message]);
  return Join(Belt_Array.keepMap(errors$2, (function (error) {
                    if (error !== undefined && error !== "") {
                      return error;
                    }
                    
                  })), "\n");
}

function toSimple(result) {
  var match = result.loading;
  var match$1 = result.error;
  var match$2 = result.data;
  return {
          called: result.called,
          data: result.data,
          error: match$1 !== undefined ? ({
                NAME: "apollo",
                VAL: match$1
              }) : (
              match || match$2 !== undefined ? undefined : ({
                    NAME: "string",
                    VAL: "Unknown"
                  })
            ),
          loading: result.loading,
          networkStatus: result.networkStatus
        };
}

function map(result, transform) {
  var data = result.data;
  var data$1 = data !== undefined ? Curry._1(transform, Caml_option.valFromOption(data)) : undefined;
  var tmp;
  tmp = data$1 !== undefined && data$1.TAG === /* Ok */0 ? Caml_option.some(data$1._0) : undefined;
  var match = result.error;
  var tmp$1;
  tmp$1 = match !== undefined ? Caml_option.some(Caml_option.valFromOption(match)) : (
      data$1 !== undefined && data$1.TAG !== /* Ok */0 ? Caml_option.some(data$1._0) : undefined
    );
  return {
          called: result.called,
          data: tmp,
          error: tmp$1,
          loading: result.loading,
          networkStatus: result.networkStatus
        };
}

function merge(resultA, resultB, join) {
  var match = resultA.data;
  var match$1 = resultB.data;
  var joined = match !== undefined && match$1 !== undefined ? Curry._2(join, Caml_option.valFromOption(match), Caml_option.valFromOption(match$1)) : undefined;
  var data = joined !== undefined && joined.TAG === /* Ok */0 ? Caml_option.some(joined._0) : undefined;
  var loading = resultA.loading || resultB.loading;
  var e = resultA.error;
  var error;
  if (e !== undefined) {
    error = e;
  } else {
    var e$1 = resultB.error;
    error = e$1 !== undefined ? e$1 : (
        joined !== undefined ? (
            joined.TAG === /* Ok */0 ? undefined : joined._0
          ) : (
            loading ? undefined : ({
                  NAME: "string",
                  VAL: "Unknown error"
                })
          )
      );
  }
  var match$2 = resultA.networkStatus;
  var match$3 = resultB.networkStatus;
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  var exit$2 = 0;
  if (match$2 !== 5) {
    if (match$2 !== 0) {
      exit$2 = 3;
    } else {
      tmp = /* Loading */0;
    }
  } else {
    switch (match$3) {
      case /* SetVariables */1 :
      case /* FetchMore */2 :
          exit$1 = 2;
          break;
      case /* Refetch */3 :
      case /* Poll */4 :
          exit = 1;
          break;
      case /* Ready */5 :
          tmp = /* Ready */5;
          break;
      case /* Loading */0 :
      case /* Error */6 :
          exit$2 = 3;
          break;
      case /* SkippedOrNotPresent */7 :
          tmp = match$2;
          break;
      
    }
  }
  if (exit$2 === 3) {
    if (match$3 !== 6) {
      if (match$3 !== 0) {
        if (match$2 !== 6) {
          if (match$2 >= 2) {
            exit$1 = 2;
          } else {
            tmp = /* SetVariables */1;
          }
        } else {
          tmp = /* Error */6;
        }
      } else {
        tmp = /* Loading */0;
      }
    } else {
      tmp = /* Error */6;
    }
  }
  if (exit$1 === 2) {
    if (match$3 !== 2) {
      if (match$3 >= 2) {
        if (match$2 !== 3) {
          if (match$2 >= 3) {
            exit = 1;
          } else {
            tmp = /* FetchMore */2;
          }
        } else {
          tmp = /* Refetch */3;
        }
      } else {
        tmp = /* SetVariables */1;
      }
    } else {
      tmp = /* FetchMore */2;
    }
  }
  if (exit === 1) {
    tmp = match$3 !== 4 ? (
        match$3 >= 4 ? (
            match$2 >= 7 ? match$3 : /* Poll */4
          ) : /* Refetch */3
      ) : /* Poll */4;
  }
  return {
          called: resultA.called && resultB.called,
          data: data,
          error: error,
          loading: loading,
          networkStatus: tmp
        };
}

var SimpleResult = {
  toSimple: toSimple,
  map: map,
  merge: merge
};

var QueryResult;

var FetchResult;

var $$Error;

var NetworkStatus;

export {
  QueryResult ,
  FetchResult ,
  $$Error ,
  NetworkStatus ,
  wrapError ,
  promiseWrapError ,
  promiseMapData ,
  getErrorMessage ,
  SimpleResult ,
  
}
/* lodash/join Not a pure module */
