// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "../../vendor/Cn.bs.js";
import * as React from "react";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Form_YesNo from "../../Form/Form_YesNo.bs.js";
import * as Utils_date from "../../utils/Utils_date.bs.js";
import * as Utils_react from "../../utils/Utils_react.bs.js";
import * as Form_Renderer from "../../Form/Form_Renderer.bs.js";
import * as Caml_primitive from "bs-platform/lib/es6/caml_primitive.js";
import * as Form_ReactSelect from "../../Form/Form_ReactSelect.bs.js";
import GetYear from "date-fns/getYear";

var currentYear = GetYear(Utils_date.now(undefined));

function ContractInvoiceTable_Filters_Form(Props) {
  var classNameOpt = Props.className;
  var wrap = Props.wrap;
  var fields = Props.fields;
  var className = classNameOpt !== undefined ? classNameOpt : Cn.none;
  return React.createElement("div", {
              className: Cn.$plus("box box-default", className)
            }, React.createElement("div", {
                  className: "box-header"
                }, React.createElement("h4", {
                      className: "box-title"
                    }, Utils_react.ste("Filtres"))), React.createElement("div", {
                  className: "box-body flex gx-gutter"
                }, React.createElement("div", {
                      className: "flex-1"
                    }, React.createElement(Form_Renderer.Row.make, {
                          label: "Déjà envoyé ?",
                          input: React.createElement(Form_YesNo.Optional.make, {
                                wrap: wrap,
                                yesLabel: "Déjà envoyé",
                                noLabel: "Pas encore envoyé",
                                field: fields.alreadySended
                              }),
                          wrap: wrap,
                          field: fields.alreadySended
                        })), React.createElement("div", {
                      className: "flex-1"
                    }, React.createElement(Form_Renderer.Row.make, {
                          label: "Mois",
                          input: React.createElement(Form_ReactSelect.Multi.make, {
                                wrap: wrap,
                                placeholder: "Mois...",
                                choices: {
                                  hd: {
                                    value: 1,
                                    label: "Janvier"
                                  },
                                  tl: {
                                    hd: {
                                      value: 2,
                                      label: "Février"
                                    },
                                    tl: {
                                      hd: {
                                        value: 3,
                                        label: "Mars"
                                      },
                                      tl: {
                                        hd: {
                                          value: 4,
                                          label: "Avril"
                                        },
                                        tl: {
                                          hd: {
                                            value: 5,
                                            label: "Mai"
                                          },
                                          tl: {
                                            hd: {
                                              value: 6,
                                              label: "Juin"
                                            },
                                            tl: {
                                              hd: {
                                                value: 7,
                                                label: "Juillet"
                                              },
                                              tl: {
                                                hd: {
                                                  value: 8,
                                                  label: "Août"
                                                },
                                                tl: {
                                                  hd: {
                                                    value: 9,
                                                    label: "Septembre"
                                                  },
                                                  tl: {
                                                    hd: {
                                                      value: 10,
                                                      label: "Octobre"
                                                    },
                                                    tl: {
                                                      hd: {
                                                        value: 11,
                                                        label: "Novembre"
                                                      },
                                                      tl: {
                                                        hd: {
                                                          value: 12,
                                                          label: "Décembre"
                                                        },
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                },
                                field: fields.months
                              }),
                          wrap: wrap,
                          field: fields.alreadySended
                        })), React.createElement("div", {
                      className: "flex-1"
                    }, React.createElement(Form_Renderer.Row.make, {
                          label: "Année",
                          input: React.createElement(Form_ReactSelect.Optional.make, {
                                wrap: wrap,
                                placeholder: "Année...",
                                choices: Belt_List.sort(Belt_List.makeBy(100, (function (i) {
                                            return {
                                                    value: currentYear - i | 0,
                                                    label: (currentYear - i | 0).toString()
                                                  };
                                          })), (function (param, param$1) {
                                        return Caml_primitive.caml_int_compare(param$1.value, param.value);
                                      })),
                                field: fields.year
                              }),
                          wrap: wrap,
                          field: fields.year
                        }))));
}

var Fields;

var make = ContractInvoiceTable_Filters_Form;

export {
  Fields ,
  currentYear ,
  make ,
  
}
/* currentYear Not a pure module */
