// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "../../vendor/Cn.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Lodash from "../../vendor/Lodash.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils_react from "../../utils/Utils_react.bs.js";
import Checkbox from "react-bootstrap/lib/Checkbox";
import FormGroup from "react-bootstrap/lib/FormGroup";
import HelpBlock from "react-bootstrap/lib/HelpBlock";

function descriptionErrors(param) {
  if (Lodash.trim(undefined, param.description) === "") {
    return "La description ne doit pas être vide.";
  }
  
}

function unitPriceBeforeTaxErrors(param) {
  var v = Pervasives.float_of_string_opt(param.unitPriceBeforeTax);
  if (v !== undefined) {
    if (v < 0.0) {
      return "Le prix unitaire ht doit être positive ou nulle.";
    } else {
      return ;
    }
  } else {
    return "Doit être un nombre valide";
  }
}

function quantityErrors(param) {
  var v = Pervasives.float_of_string_opt(param.quantity);
  if (v !== undefined) {
    if (v < 0.0) {
      return "La quantité doit être positive ou nulle.";
    } else {
      return ;
    }
  } else {
    return "Doit être un nombre valide";
  }
}

function InvoiceLineTr(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var onRemoveClick = Props.onRemoveClick;
  var onUpClick = Props.onUpClick;
  var onDownClick = Props.onDownClick;
  var acts = Props.acts;
  var descriptionErrors$1 = descriptionErrors(value);
  var unitPriceBeforeTaxErrors$1 = unitPriceBeforeTaxErrors(value);
  var quantityErrors$1 = quantityErrors(value);
  var descriptionRef = React.useRef(null);
  var tmp = {
    children: null
  };
  var tmp$1 = Belt_Option.isNone(descriptionErrors$1) ? undefined : "error";
  if (tmp$1 !== undefined) {
    tmp.validationState = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    children: null
  };
  var tmp$3 = Belt_Option.isNone(unitPriceBeforeTaxErrors$1) ? undefined : "error";
  if (tmp$3 !== undefined) {
    tmp$2.validationState = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = {
    children: null
  };
  var tmp$5 = Belt_Option.isNone(quantityErrors$1) ? undefined : "error";
  if (tmp$5 !== undefined) {
    tmp$4.validationState = Caml_option.valFromOption(tmp$5);
  }
  return React.createElement("tr", {
              className: value.breakpage ? "border-dashed border-0 border-b-2 border-gray-700" : Cn.none
            }, React.createElement("td", undefined, React.createElement("button", {
                      className: "btn btn-warning",
                      onClick: (function (param) {
                          return Utils_react.preventCallback(onRemoveClick, param);
                        })
                    }, React.createElement("i", {
                          className: "fa fa-trash"
                        }))), React.createElement("td", undefined, React.createElement("select", {
                      className: "form-control not-select2",
                      value: "",
                      onChange: (function ($$event) {
                          var v = Utils_react.getValue($$event);
                          if (v === "") {
                            return ;
                          }
                          Curry._1(onChange, {
                                version: value.version,
                                breakpage: value.breakpage,
                                description: v,
                                unitPriceBeforeTax: value.unitPriceBeforeTax,
                                quantity: value.quantity,
                                vat: value.vat
                              });
                          var element = Utils_react.asHtmlElement(descriptionRef.current);
                          if (element !== undefined) {
                            Caml_option.valFromOption(element).focus();
                            return ;
                          }
                          
                        })
                    }, React.createElement("option", {
                          value: ""
                        }), Utils_react.ate(Belt_Array.mapWithIndex(acts, (function (i, act) {
                                return React.createElement("option", {
                                            key: "act-" + i.toString(),
                                            value: act
                                          }, Utils_react.ste(act));
                              })))), React.createElement(FormGroup, tmp, React.createElement("textarea", {
                          ref: descriptionRef,
                          className: "form-control",
                          rows: 5,
                          value: value.description,
                          onChange: (function ($$event) {
                              return Curry._1(onChange, {
                                          version: value.version,
                                          breakpage: value.breakpage,
                                          description: Utils_react.getValue($$event),
                                          unitPriceBeforeTax: value.unitPriceBeforeTax,
                                          quantity: value.quantity,
                                          vat: value.vat
                                        });
                            })
                        }), Utils_react.mapOrNull(descriptionErrors$1, (function (e) {
                            return React.createElement(HelpBlock, {
                                        children: Utils_react.ste(e)
                                      });
                          })))), React.createElement("td", undefined, React.createElement(FormGroup, tmp$2, React.createElement("input", {
                          className: "form-control",
                          type: "number",
                          value: value.unitPriceBeforeTax,
                          onChange: (function ($$event) {
                              return Curry._1(onChange, {
                                          version: value.version,
                                          breakpage: value.breakpage,
                                          description: value.description,
                                          unitPriceBeforeTax: Utils_react.getValue($$event),
                                          quantity: value.quantity,
                                          vat: value.vat
                                        });
                            })
                        }), Utils_react.mapOrNull(unitPriceBeforeTaxErrors$1, (function (e) {
                            return React.createElement(HelpBlock, {
                                        children: Utils_react.ste(e)
                                      });
                          })))), React.createElement("td", undefined, React.createElement(FormGroup, tmp$4, React.createElement("input", {
                          className: "form-control",
                          type: "number",
                          value: value.quantity,
                          onChange: (function ($$event) {
                              return Curry._1(onChange, {
                                          version: value.version,
                                          breakpage: value.breakpage,
                                          description: value.description,
                                          unitPriceBeforeTax: value.unitPriceBeforeTax,
                                          quantity: Utils_react.getValue($$event),
                                          vat: value.vat
                                        });
                            })
                        }), Utils_react.mapOrNull(quantityErrors$1, (function (e) {
                            return React.createElement(HelpBlock, {
                                        children: Utils_react.ste(e)
                                      });
                          })))), React.createElement("td", undefined, React.createElement("select", {
                      className: "form-control not-select2",
                      value: value.vat,
                      onChange: (function ($$event) {
                          return Curry._1(onChange, {
                                      version: value.version,
                                      breakpage: value.breakpage,
                                      description: value.description,
                                      unitPriceBeforeTax: value.unitPriceBeforeTax,
                                      quantity: value.quantity,
                                      vat: Utils_react.getValue($$event)
                                    });
                        })
                    }, React.createElement("option", {
                          value: "0"
                        }, Utils_react.ste("Pas de TVA")), React.createElement("option", {
                          value: "10"
                        }, Utils_react.ste("10%")), React.createElement("option", {
                          value: "20"
                        }, Utils_react.ste("20%")))), React.createElement("td", undefined, React.createElement(Checkbox, {
                      checked: value.breakpage,
                      onChange: (function (_event) {
                          return Curry._1(onChange, {
                                      version: value.version,
                                      breakpage: !value.breakpage,
                                      description: value.description,
                                      unitPriceBeforeTax: value.unitPriceBeforeTax,
                                      quantity: value.quantity,
                                      vat: value.vat
                                    });
                        })
                    })), React.createElement("td", undefined, React.createElement("div", {
                      className: "btn-group",
                      role: "group"
                    }, React.createElement("button", {
                          className: "btn btn-default btn-sm",
                          onClick: (function (param) {
                              return Utils_react.preventCallback(onUpClick, param);
                            })
                        }, React.createElement("i", {
                              className: "fa fa-arrow-up"
                            })), React.createElement("button", {
                          className: "btn btn-default btn-sm",
                          onClick: (function (param) {
                              return Utils_react.preventCallback(onDownClick, param);
                            })
                        }, React.createElement("i", {
                              className: "fa fa-arrow-down"
                            })))));
}

var FormGroup$1;

var HelpBlock$1;

var Checkbox$1;

var make = InvoiceLineTr;

export {
  FormGroup$1 as FormGroup,
  HelpBlock$1 as HelpBlock,
  Checkbox$1 as Checkbox,
  descriptionErrors ,
  unitPriceBeforeTaxErrors ,
  quantityErrors ,
  make ,
  
}
/* react Not a pure module */
